import {
  CHECKIN,
  CHECKIN_PAYMENT,
  CHECKIN_THANKYOU,
  CHECKOUT,
  CHECKOUT_PAYMENT,
  CHECKOUT_THANKYOU,
  DOCUMENT_SCAN,
  DOCUMENT_SCAN_TYPE,
  DOCUMENT_VERIFICATION,
  FACE_VERIFICATION,
  FACE_VERIFICATION_NEW,
  FOLIO,
  HOME,
  KEY,
  KEYPAGE,
  KEY_NEW,
  KEY_SEARCH,
  KEY_THANKYOU,
  QR_SEARCH,
  RESERVATION_DETAIL,
  RESERVATION_LIST_FORM,
  RESERVATION_LIST_FORM_TYPE,
  SEARCH_RESERVATION,
  SEND_FOLIO,
  UPGRADE,
} from "./Paths";
import { Navigate, Route, Routes } from "react-router-dom";
import { getModuleStatus, getStepMaster } from "../helpers/commonHelper";

import CheckOutPage from "../containers/pages/CheckOutPage";
import CheckinPage from "../containers/pages/CheckinPage";
import CheckoutPayment from "../containers/Checkout/CheckoutPayment";
import ConfirmationNumber from "../containers/Checkin/ReservationSearch";
import CreateKeyPassportScan from "../containers/Checkin/CreateKey";
import DocumentVerification from "../components/UserVerification/DocumentVerification";
import FaceVerificationQrScanning from "../containers/Checkin/FaceVerification";
import HomePage from "../containers/pages/HomePage";
import InvoiceInformation from "../containers/Checkout/InvoiceInformation";
import KeyReservationSearch from "../containers/Key/KeyReservationSearch";
import Login from "../containers/pages/Login";
import PassportReservationForm from "../containers/Checkin/ResearvationList";
import PassportScanning from "../containers/Checkin/DocumentScanning";
import PaymentOnPassportScan from "../containers/Checkin/PaymentCheckin";
import PrivateRoute from "../HOC/PrivateRoute";
import QrScanning from "../containers/Checkin/QrScanning";
import ReservationDetailsPassportScan from "../containers/Checkin/ReservationDetails";
import { STEP_MASTER_ENUM } from "../constants/constant";
import SendInvoiceOnMail from "../containers/Checkout/SendInvoiceOnMail";
import SendMessage from "../components/Forms/SendMessage";
import Thankyou from "../components/ThankYou/Thankyou";
import Thankyou2 from "../components/ThankYou/Thanyou2";
import Thankyou3 from "../components/ThankYou/Thanyou3";
import ThankyouPassportScan from "../containers/Checkin/ThankYouCheckin";
import UpgradeOptionPassportScan from "../containers/Checkin/Upgrade";
import { fetchLanguages } from "../store/Checkin/languageSlice";
import { getModuleConfiguration } from "../store/Checkin/moduleConfigurationSlice";
import { useSelector } from "react-redux";

const CustomRouter = () => {
  const stepMaster = useSelector((state) => getModuleConfiguration(state));

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      {/* <Route
            path="/"
            element={
              <PrivateRoute component={Thankyou} type={"checkin"} />
            }
          /> */}

      {stepMaster && (
        <>
          <Route
            path={HOME}
            element={
              <PrivateRoute
                component={HomePage}
                type={"checkin"}
                currentStep={STEP_MASTER_ENUM.HOME_PAGE}
              />
            }
          />
          <Route
            path={CHECKIN}
            element={<PrivateRoute component={CheckinPage} type={"checkin"} />}
          />
          <Route
            path={CHECKOUT}
            element={
              <PrivateRoute component={CheckOutPage} type={"checkout"} />
            }
          />
          <Route
            path={QR_SEARCH}
            element={<PrivateRoute component={QrScanning} type={"checkin"} />}
          />

          <Route
            path={SEARCH_RESERVATION}
            element={
              <PrivateRoute component={ConfirmationNumber} type={"checkin"} />
            }
          />
          <Route
            path={DOCUMENT_SCAN}
            element={
              <PrivateRoute component={PassportScanning} type={"checkin"} />
            }
          />

          <Route
            path={DOCUMENT_SCAN_TYPE}
            element={
              <PrivateRoute component={PassportScanning} type={"checkin"} />
            }
          />

          <Route
            path={RESERVATION_LIST_FORM}
            element={
              <PrivateRoute
                component={PassportReservationForm}
                type={"checkin"}
              />
            }
          />
          <Route
            path={RESERVATION_LIST_FORM_TYPE}
            element={
              <PrivateRoute
                component={PassportReservationForm}
                type={"checkin"}
              />
            }
          />
          <Route
            path={UPGRADE}
            element={
              <PrivateRoute
                component={UpgradeOptionPassportScan}
                type={"checkin"}
              />
            }
          />
          <Route
            path={FACE_VERIFICATION}
            element={
              <PrivateRoute
                component={FaceVerificationQrScanning}
                type={"checkin"}
              />
            }
          />
          <Route
            path={FACE_VERIFICATION_NEW}
            element={
              <PrivateRoute
                component={FaceVerificationQrScanning}
                type={"checkin"}
              />
            }
          />
          <Route
            path={RESERVATION_DETAIL}
            element={
              <PrivateRoute
                component={ReservationDetailsPassportScan}
                type={"checkin"}
              />
            }
          />

          <Route
            path={DOCUMENT_VERIFICATION}
            element={
              <PrivateRoute component={DocumentVerification} type={"checkin"} />
            }
          />

          <Route
            path={CHECKIN_PAYMENT}
            element={
              <PrivateRoute
                component={PaymentOnPassportScan}
                type={"checkin"}
              />
            }
          />

          <Route
            path={KEY}
            element={
              <PrivateRoute
                component={CreateKeyPassportScan}
                type={"checkin"}
              />
            }
          />

          <Route
            path={CHECKIN_THANKYOU}
            element={
              <PrivateRoute component={ThankyouPassportScan} type={"checkin"} />
            }
          />

          <Route
            path={FOLIO}
            element={
              <PrivateRoute component={InvoiceInformation} type={"checkout"} />
            }
          />

          <Route
            path={CHECKOUT_PAYMENT}
            element={
              <PrivateRoute component={CheckoutPayment} type={"checkout"} />
            }
          />

          <Route
            path={SEND_FOLIO}
            element={
              <PrivateRoute component={SendInvoiceOnMail} type={"checkout"} />
            }
          />

          <Route
            path={CHECKOUT_THANKYOU}
            element={<PrivateRoute component={Thankyou2} type={"checkout"} />}
          />

          <Route
            path={KEYPAGE}
            element={
              <PrivateRoute component={KeyReservationSearch} type={"key"} />
            }
          />
          <Route
            path={KEY_NEW}
            element={
              <PrivateRoute component={CreateKeyPassportScan} type={"key"} />
            }
          />

          <Route
            path={KEY_THANKYOU}
            element={<PrivateRoute component={Thankyou3} type={"key"} />}
          />
        </>
      )}

      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default CustomRouter;
