/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import ErrorPopup from "../LoaderAndNotification/ErrorPopup";
import FaceRcgFailedModal from "../Layout/Card/FaceRcgFailedModal";
import ImageCaptureComponent from "../../containers/Checkin/ImageCaptureComponent";
import LoaderPopup from "../LoaderAndNotification/LoaderPopup";
import SubHeader from "../Layout/SubHeader";
import SuccessPopup from "../LoaderAndNotification/SuccessPopup";
import { TitleCaseConverter } from '../Plugins/TitleCaseCoverter';
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import i18n from 'i18next';
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

const FaceVerification = ({
  loading,
  openModal,
  closeErrorModal,
  erroModalData,
  signedInUserName,
  successLoader,
  successMessage,
  facialRecognisationFailed,
  completedFacialRecognisation,
  backToHomePage,
  faceImage,
  scanName,
  guestCount,
  retryFace,
  onImageCapture,
  isReload,
  manualAuthenticationRequired,
  enableSelfie,
  loadingMsg

}) => {
  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
     }, []);

  return (
    <div>
      <main>
        <div className="main-content bg-invoice-white">
          <div >
            <SubHeader
              onClick={backToHomePage}
              setShowModal={openModal}
              isHome={true}
              title={TitleCaseConverter({text:scanName}) || "-"}
              subTitle={t("Please look at the screen while we verify your ID")}
              highlight ={t("Hey")}
            />
          </div>

          <div className="content-center" style={{top:"50%"}}>
            

            <div className="verification-container">
              <div className="test-image-capture-container text-center">
                <ImageCaptureComponent
                  onCapture={onImageCapture}
                  reloadCapture={isReload}
                  onManualAuthorization= {completedFacialRecognisation}
                />
              </div>
              <div>
                <div className="profile-scan text-center">
                  <div>{t("Your ID image")}</div>
                  {faceImage === null ||
                  faceImage === undefined ||
                  faceImage === "" ? (
                    <p class="donut mb-0"></p>
                  ) : (
                    <>
                      <img src={faceImage} alt="Your ID image" />
                    </>
                  )}
                </div>
              </div>
            </div>
           
            <div className="mt-30 text-center d-flex justify-content-center align-items-center">
              <p className="donut mb-0"></p>
             
              <p className="mb-0 verify-text">{t("Verifying your ID")}</p>
            </div>
            <div className="text-center">
              <p className="verify-text-set">
                <span className="mat-icon material-symbols-outlined">
                  <i className="bx bxs-error"></i>
                </span>
                {t("Please remove your Mask, Glass and Cap for face verification")}
              </p>
            </div>
            
          </div>
        </div>
        <LoaderPopup loading={loading} message={loadingMsg}/>
        <ErrorPopup
          closeErrorModal={closeErrorModal}
          erroModalData={erroModalData}
        />
        <SuccessPopup loading={successLoader} successMessage={successMessage} />
        {facialRecognisationFailed === true && enableSelfie  === false && (
          <FaceRcgFailedModal
            ficalRecognisationModal={facialRecognisationFailed}
            completedFacialRecognisation={completedFacialRecognisation}
            backToHomePage={backToHomePage}
            manualAuthenticationRequired = {manualAuthenticationRequired}
            retryFace={retryFace}
          />
        )}
      </main>
    </div>
  );
};

export default FaceVerification;
