import { CHECKIN_THANKYOU, HOME, KEY } from "../../routes/Paths";
import { CONFIG_MASTER_ENUM, SESSION_ENUM, STEP_MASTER_ENUM } from "../../constants/constant";
import {
  checkIn,
  getReservationPaymentInfo,
  postPaymentToPMS,
  queueRoom,
  triggerPayment,
} from "../../network/apiService";
import {
  formatCardExpiry,
  getConfigurationCode,
  getParametersFromLocalStorage,
  homePageHandler,
} from "../../helpers/commonHelper";
import { useEffect, useState } from "react";

import Footer from "../../components/Layout/Footer";
import HomePageCard from "../../components/Layout/Card/HomePageCard";
import Payment from "../../components/Payment/Payment";
import { getModuleConfiguration } from "../../store/Checkin/moduleConfigurationSlice";
import { getMultiConfirmationNos } from "../../store/Checkin/ConfirmationNumber/confirmationNumberSlice";
import { getPassportScanData } from "../../store/Checkin/Scan/scanPassportSlice";
import { getPropertyInfo } from "../../store/Checkin/Scan/propertySlice";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import { getStepMaster } from "../../helpers/commonHelper";
import i18n from "i18next";
import { timerConstantValue } from "../../constants/constant";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const PaymentOnPassportScan = () => {
  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  const [i, setI] = useState(0);
  const timeLimit = timerConstantValue;
  const [showModal, setShowModal] = useState(false);
  const [paymentToggle, setPaymentToggle] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);
  const [successMessage, setSuccessMessage] = useState("Checked in successfully");

  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [erroModalData2, setErrorModalData2] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [erroModalData3, setErrorModalData3] = useState({
    toggleError: false,
    errorMessage: "",
  });

  const [erroModalData4, setErrorModalData4] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(getPassportScanData);
  const token = getParametersFromLocalStorage("token");
  const propertyData = useSelector(getPropertyInfo);
  const confirmationNos = useSelector(getMultiConfirmationNos);
  const deviceConfig = localStorage.getItem("PaymentDevice"); //useSelector(getDeviceDetails);

  const stepMaster = useSelector((state) => getModuleConfiguration(state));
  const paymentPage = getStepMaster(stepMaster, STEP_MASTER_ENUM.PAYMENT_PAGE);

  const isTogglePay = getConfigurationCode(
    paymentPage?.configs,
    CONFIG_MASTER_ENUM.TOGGLE_PAY
  );
  
  
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
    const total = localStorage.getItem("DueAmount");
    if(total !== null && total !== undefined){
      setTotalDue(total);
    }else{
      fetchReservationDetails();
    }    
  }, []);

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const closeErrorModal2 = () => {
    setErrorModalData2((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const closeErrorModal3 = () => {
    setErrorModalData3((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const closeErrorModal4 = () => {
    setErrorModalData4((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
    homePageHandler(dispatch, navigate);
    return;
  };



  const generateToken = async () => {
    if (isTogglePay === true) {
      setPaymentToggle(true);
    } else {
      paymentTypeHandler(null, totalDue);
    }
    return;
  };

  const showToggle = (flag) => {
    if (isTogglePay === true) {
      setPaymentToggle(flag);
    }
  };

  const incrementI = () => {
    setI(i + 1);
  };

  const handlebackPage = () => {
    try {
      homePageHandler(dispatch, navigate);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };

  const [totalDue, setTotalDue] = useState(null);

  const fetchReservationDetails = async (query) => {
    try {
      setLoading(true);

      const confirmNo = localStorage.getItem("confirmationNo");
      const token = getParametersFromLocalStorage("token");
      const propertyCode = localStorage.getItem("propertyCode");
      const reservationNameID = localStorage.getItem("reservationNameID");

      const query = {
        ConfirmationNo: confirmNo,
        PropertyCode: propertyCode,
        ReservationNameID: reservationNameID,
      };

      const response = await getReservationPaymentInfo(query, token);

      if (response?.data?.result === true && response.data.data != null) {
        const amount = response.data?.data?.totalAmountDue;
        setTotalDue(amount);
        if (amount === 0) {
          setTotalDue(0);
          setLoading(false);
          setLoading3(true);
          
          await callCheckin(null);
          // const checkinData = {
          //   confirmationNo: userData?.pmsConfirmationNo,
          //   reservationNameID: userData?.reservationNameID,
          //   confirmationNumbers: JSON.stringify(confirmationNos),
          // };

          // const response2 = await checkIn(checkinData, token);
          // if (response2?.data?.result === true) {
          //   setLoading3(false);
          //   setSuccessMessage("Checked in successfully!!");
          //   setSuccessLoader(true);
          //   setTimeout(() => {
          //     navigate(KEY, { replace: true });
          //   }, 2000);
          //   setLoading(false);
          //   return;
          // } else {
          //   setLoading3(false);
          //   setErrorModalData4((state) => ({
          //     ...state,
          //     toggleError: true,
          //     errorMessage: t("Checkin failed"),
          //   }));
          //   setLoading(false);
          // }
        } else {
          setLoading(false);
          setLoading2(false);
          setLoading3(false);
        }
      } else {
        setLoading(false);
        setLoading2(false);
        setLoading3(false);
        setErrorModalData4((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t(
            "Check-in Not Allowed. Please visit front desk for assistance"
          ),
        }));
        return;
      }
    } catch (error) {
      setLoading(false);
      setErrorModalData4((state) => ({
        ...state,
        toggleError: true,
        errorMessage: t("Checkin failed"),
      }));
    }
  };

  const userCheckIn = async () => {
    try {
      setLoading3(true);
      var data = {
        cardType: "",
        cardNumber: "",
        cardExpiry: null,
        chipAndPin: true,
        cardHolderName: "",
        cardToken: "ds",
        terminalID: deviceConfig, //deviceID.settingsValue
      };
      
      await callCheckin(data);
      // const checkinData = {
      //   confirmationNo: userData?.pmsConfirmationNo,
      //   reservationNameID: userData?.reservationNameID,
      //   payment: data,
      //   confirmationNumbers: JSON.stringify(confirmationNos),
      // };

      // const response2 = await checkIn(checkinData, token);
      // if (response2.status === 200 && response2.data?.statusCode == 200) {
      //   setLoading3(false);
      //   setSuccessMessage("Checked in successfully!!");
      //   setSuccessLoader(true);
      //   setTimeout(() => {
      //     setSuccessLoader(false);
      //     navigate(KEY, { replace: true });
      //   }, 2000);
      //   return;
      // } else {
      //   setLoading2(false);
      //   setLoading3(false);
      //   setErrorModalData4((state) => ({
      //     ...state,
      //     toggleError: true,
      //     errorMessage: t(
      //       "Check-in Not Allowed. Please visit front desk for assistance"
      //     ),
      //   }));
      // }
    } catch (error) {
      setLoading3(false);
      setErrorModalData3((state) => ({
        ...state,
        toggleError: true,
        errorMessage: error.message,
      }));
      return;
    }
    return;
  };

  const paymentTypeHandler = async (paymentMode, totalAmount) => {
    try {
      incrementI();

      // setLoading(true);
      setLoading2(true);
      const paymentType = {
        amount: Number.parseFloat(totalAmount).toFixed(2),
        poiID: deviceConfig, // deviceConfig,//deviceID.settingsValue,//"AMS1-000168230430477",
        currency: propertyData?.data?.currency,
        reservationNameID: userData?.reservationNameID,
        account: userData?.account,
        pmsConfirmationNo: userData?.pmsConfirmationNo,
        guaranteeCode: userData?.guaranteeCode,
      };
      const response = await triggerPayment(paymentType, token);
      if (response.status === 200 && response.data.result) {
        const paymentData = {
          reservationNameID: userData?.reservationNameID,
          postDate: new Date().toISOString(),
          amount: totalAmount,
          info: "",
          terminalID: deviceConfig, //"KIOSK",
          folioWindow: 1,
          creditCardType: response?.data?.data?.cardType?.toUpperCase(), //"CA",
          creditCardNumber: response?.data?.data?.cardNumber, // "4151500000000008",
          creditCardExpiry: formatCardExpiry(response?.data?.data?.cardExpiry),
          preAuthRule: "",
          reference: "",
        };
        const response3 = await postPaymentToPMS(paymentData, token);

        if (response3?.data?.result === true) {
          await callCheckin(paymentData);
        } else {
          setLoading2(false);
          setLoading3(false);
          showToggle(true);
          setErrorModalData4((state) => ({
            ...state,
            toggleError: true,
            errorMessage: t(
              "Check-in Not Allowed. Please visit front desk for assistance"
            ),
          }));
        }
      } else {
        setLoading2(false);
        setLoading3(false);
        showToggle(true);
        setErrorModalData4((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t(
            "Payment unsuccessful. Please review your payment details and try again, or proceed to the front desk for assistance."
          ),
          //"Check-in Not Allowed.Please visit front desk for assistance",
        }));
        setTimeout(() => {
          closeErrorModal4();
        }, 8000);
        return;
      }
    } catch (error) {
      setLoading2(false);
      setLoading3(false);
      showToggle(true);
      setErrorModalData4((state) => ({
        ...state,
        toggleError: true,
        errorMessage: t(
          "Payment unsuccessful. Please review your payment details and try again, or proceed to the front desk for assistance."
        ),
        //"Check-in Not Allowed.Please visit front desk for assistance",
      }));
      setTimeout(() => {
        closeErrorModal4();
      }, 8000);
      return;
    }
    return;
  };

  const moveToQueueRoom = async () =>{
    const confirmationNo = localStorage.getItem("confirmationNo");
    const reservationNameID = localStorage.getItem("reservationNameID");
    const requestData = {
      confirmationNo: confirmationNo,
      reservationNameID: reservationNameID,
    };
    const resp = await queueRoom(requestData, token);
    if (resp?.data?.result === true) {
      setLoading2(false);
      showToggle(false);
      setSuccessMessage("Pre-Checked in successfully!!");
      setSuccessLoader(true);      
      setTimeout(() => {
        setSuccessLoader(false);
        navigate(CHECKIN_THANKYOU, { replace: true });
      }, 2000);
      return;
    } else {
      setLoading2(false);
      setLoading3(false);
      showToggle(true);
      setErrorModalData4((state) => ({
        ...state,
        toggleError: true,
        errorMessage: t(
          "PreCheck-in Failed. Please visit front desk for assistance"
        ),
      }));
    }
  }

  const callCheckin = async (data) =>{
    //#region Precheckin
    const preCheckin = getParametersFromLocalStorage(
      SESSION_ENUM.PRECHECKIN
    );
    if (preCheckin) {          
      await moveToQueueRoom();
    }
    //#endregion
    else {
      //if success call checkin
      const checkinData = {
        confirmationNo: userData?.pmsConfirmationNo,
        reservationNameID: userData?.reservationNameID,
        payment: data,
        confirmationNumbers: JSON.stringify(confirmationNos),
      };

      const response2 = await checkIn(checkinData, token);
      if (response2.data?.result === true) {
        setLoading2(false);
        showToggle(false);
        setSuccessMessage("Checked in successfully!!");
        setSuccessLoader(true);
        setTimeout(() => {
          setSuccessLoader(false);
          navigate(KEY, { replace: true });
        }, 2000);
        return;
      } else {
        setLoading3(false);
        setErrorModalData4((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t("Checkin failed"),
        }));
        setLoading(false);
      }
    }
  }
  return (
    <div>
      <Payment
        loading={loading}
        loading2={loading2}
        loading3={loading3}
        userData={userData}
        erroModalData={erroModalData}
        erroModalData2={erroModalData2}
        erroModalData3={erroModalData3}
        generateToken={generateToken}
        closeErrorModal={closeErrorModal}
        closeErrorModal2={closeErrorModal2}
        closeErrorModal3={closeErrorModal3}
        openModal={openModal}
        paymentToggle={paymentToggle}
        setPaymentToggle={setPaymentToggle}
        navigateUrl={KEY}
        userCheckIn={userCheckIn}
        paymentTypeHandler={paymentTypeHandler}
        successLoader={successLoader}
        successMessage={t(successMessage)}
        erroModalData4={erroModalData4}
        closeErrorModal4={closeErrorModal4}
        property={propertyData?.data}
        totalDue={totalDue}
        onBackPressed={handlebackPage}
      />
      <Footer
        page={"payment"}
        showModal={showModal}
        openModal={openModal}
        timerCounter={timerCounter}
        timerValue={timerValue}
        restartTimer={restartTimer}
        stopTimer={stopTimer}
        timeLimit={timeLimit}
      />
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
    </div>
  );
};

export default PaymentOnPassportScan;
