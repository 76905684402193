/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {
  addPrefixIfSingleLetter,
  formatDate,
  getParametersFromLocalStorage,
  homePageHandler,
  nameValidation,
} from "../../helpers/commonHelper";
import {
  fetchCountries,
  getCountryList,
} from "../../store/Checkin/reservationformSlice";
import { initReactI18next, useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ErrorPopup from "../LoaderAndNotification/ErrorPopup";
import Header from "../Layout/Header";
import LoaderPopup from "../LoaderAndNotification/LoaderPopup";
import PolicyDetailsModal from "../../components/Layout/Card/PolicyDetailsModal";
import SignaturePad from "../Forms/SignaturePad";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import { getTermsAndConditons } from "../../network/apiService";
import i18n from "i18next";

const UserReservationDetails = ({
  loading,
  loading2,
  loading3,
  erroModalData,
  erroModalData2,
  erroModalData3,
  closeErrorModal,
  closeErrorModal2,
  closeErrorModal3,
  openModal,
  reservationData,
  confirmationDetails,
  confirmationDetailsHandler,
  signature,
  setSignature,
  setUrl,
  url,
  gotSignature,
  userAgreed,
  navigateToPayment,
  handleCheckboxChange,
  clearSign,
  updateProfile,
  openCheckOutTimeModal,
  toggleCheckoutTimeModal,
  timeChanged,
  setTimeChanged,
  onChangeHandler,
  emailValidation,
  countryValidation,
  mobileNoValidation,
  enterEmailValidation,
  property,
  userData,
  countries,
  handlebackPage,
}) => {
  const [termsAndConditionData, setTermsAndConditionData] = useState();
  const settings = getParametersFromLocalStorage("settings");
  const checkOutTime =
    settings?.find((setting) => setting.parameterName === "CheckOutTime")
      ?.parameterValue ?? "12:00 AM";
  const [tnCLoader, setTnCLoader] = useState(false);
  const [erroModalData4, setErrorModalData4] = useState({
    toggleError: false,
    errorMessage: "",
  });

  const [showDownloadKeyModal, setDownloadKeyModal] = useState(false);
  const token = getParametersFromLocalStorage("token");
  const [totalDue, setTotalDue] = useState(0);
  const [printRate, setPrintRate] = useState(false);

  useEffect(() => {
    setPrintRate(userData?.printRate);
  });

  useEffect(() => {
    if (reservationData !== null && reservationData !== undefined) {
      const amount = parseFloat(reservationData.totalAmountDue);

      if (amount > 0) {
        setTotalDue(amount);
      } else {
        setTotalDue(0);
      }
    }
  }, [reservationData]);
  const openDownloadKey = () => {
    setDownloadKeyModal(true);
  };

  const closeDownloadKey = () => {
    setDownloadKeyModal(false);
  };

  const doneDownloadKey = () => {
    setDownloadKeyModal(false);
  };

  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
  }, []);

 
  const closeErrorModal4 = () => {
    setErrorModalData4((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const getTermsAndConditonsData = useCallback(async () => {
    try {
      setTnCLoader(true);
      console.log("selectedLanguage ==" + selectedLanguage);
      const response = await getTermsAndConditons(token);
      if (response.status === 200) {
        const filteredData = response.data.data.filter(
          (item) => item.language === selectedLanguage
        );
        setTermsAndConditionData(filteredData);
        setTnCLoader(false);
        return;
      }
    } catch (error) {
      setTnCLoader(false);
      // console.log(error);
      setErrorModalData4((state) => ({
        ...state,
        toggleError: true,
        errorMessage: error.message,
      }));
    }
    return;
  }, [token]);

  useEffect(() => {
    getTermsAndConditonsData();
  }, []);

  useEffect(() => {
    if (timeChanged) {
      openCheckOutTimeModal();
    }
  }, [timeChanged]);

  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    const calculateContainerHeight = () => {
      const footerHeight = document.querySelector("footer").clientHeight;
      const viewportHeight = window.innerHeight;
      setContainerHeight(`calc(${viewportHeight}px - ${footerHeight}px)`);
    };

    calculateContainerHeight();
    window.addEventListener("resize", calculateContainerHeight);

    return () => {
      window.removeEventListener("resize", calculateContainerHeight);
    };
  }, []);

  return (
    <div>
      <main>
        <LoaderPopup loading={loading} />
        <LoaderPopup loading={loading2} />
        <LoaderPopup loading={loading3} />
        <LoaderPopup loading={tnCLoader} />
        <ErrorPopup
          closeErrorModal={closeErrorModal}
          erroModalData={erroModalData}
        />
        {erroModalData?.toggleError && (
          <ErrorPopup
            closeErrorModal={closeErrorModal}
            erroModalData={erroModalData}
          />
        )}
        <ErrorPopup
          closeErrorModal={closeErrorModal2}
          erroModalData={erroModalData2}
        />
        <ErrorPopup
          closeErrorModal={closeErrorModal3}
          erroModalData={erroModalData3}
        />
        <ErrorPopup
          closeErrorModal={closeErrorModal4}
          erroModalData={erroModalData4}
        />
        {showDownloadKeyModal && (
          <PolicyDetailsModal
            closeDownloadKey={closeDownloadKey}
            doneDownloadKey={doneDownloadKey}
            policyData={
              Array.isArray(termsAndConditionData)
                ? termsAndConditionData[0]?.policyInfo
                : termsAndConditionData?.policyInfo
            }
          />
        )}

        <div
          className="main-content"
          style={{ height: containerHeight, overflowY: "auto" }}
        >
          <div class="d-flex h-100 flex-nowrap ">
            <div class="col-6  p-2  ">
              {" "}
              <div>
                <Header
                  onClick={handlebackPage}
                  setShowModal={openModal}
                  isHome={true}
                  title={""}
                  subTitle={""}
                />
              </div>
              <form className="h-70" autoComplete="off">
                <div className="flex">
                  <div
                    className="mb-20"
                    style={{
                      paddingLeft: "50px",
                      paddingRight: "50px",
                      paddingTop: "20px",
                    }}
                  >
                    <div className="terms-title">
                      <h4>{t("Reservation Details")}</h4>
                    </div>

                    <div className="d-flex reservation-detail-content">
                      <div className="reservation-width">
                        <label>{t("Name")}:</label>
                        {reservationData.name !== "" &&
                        reservationData.name !== null &&
                        reservationData.name !== undefined ? (
                          <p>
                            {nameValidation(reservationData.name, undefined)}
                          </p>
                        ) : (
                          <p>
                            {nameValidation(
                              reservationData.firstName,
                              reservationData.lastName
                            )}
                          </p>
                        )}
                      </div>
                      <div className="reservation-width-right">
                        <label>{t("Number of Nights:")}</label>
                        <p>
                          {addPrefixIfSingleLetter(reservationData.nights + "")}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex reservation-detail-content">
                      <div className="reservation-width">
                        <label>{t("Arrival Date:")}</label>
                        <p>{formatDate(reservationData?.arrivalDate)}</p>
                      </div>
                      <div className="reservation-width-right">
                        <label>{t("Departure Date:")}</label>
                        <p>{formatDate(reservationData?.departureDate)}</p>
                      </div>
                    </div>
                    <div className="d-flex reservation-detail-content">
                      <div className="reservation-width">
                        <label>{t("Daily Room Rate:")}</label>
                        <p>
                          {reservationData?.printRate === false ? (
                            "-"
                          ) : (
                            <>
                              {property.currency}{" "}
                              {parseFloat(reservationData?.roomRate).toFixed(2)}
                            </>
                          )}
                        </p>
                      </div>

                      <div className="reservation-width-right">
                        <div className="highlight">
                          <label>{t("Total Charges:")}</label>
                          <br />
                          <strong>
                            <span style={{ fontWeight: "700" }}>
                              {property.currency}{" "}
                              {parseFloat(totalDue)?.toFixed(2)}
                            </span>
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <p>
                        {Array.isArray(termsAndConditionData) ? (
                          <p>{termsAndConditionData[0]?.moreInfo}</p>
                        ) : (
                          <p>{termsAndConditionData || ""}</p>
                        )}
                      </p>
                    </div>

                    <div className="d-flex justify-content-between communication-detail">
                      <label>
                        {t("Check-out Time :")}
                        {/* <span style={{ color: "red" }}>*</span>{" "} */}
                      </label>

                      {/* <select
                        disabled
                        name="hrs"
                       // value={confirmationDetails.hrs}
                        value="11:00 AM"
                        onChange={onChangeHandler}
                        className="form-control form-control-set select-option"
                      >
                        {hrsCount.map((hr, idx) => {
                          if (
                            hr === confirmationDetails.hrs ||
                            hr !== confirmationDetails.hrs
                          ) {
                            return (
                              <option key={idx} value={hr}>
                                {hr}
                              </option>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </select> */}
                      {/* <div className="dot-div">:</div> */}
                      {/* <select
                        disabled
                        name="mins"
                        value={confirmationDetails.mins}
                        onChange={onChangeHandler}
                        className="form-control form-control-set select-option"
                      >
                        {minsCount.map((mins, idx) => {
                          if (
                            mins === confirmationDetails.hrs ||
                            mins !== confirmationDetails.hrs
                          ) {
                            return (
                              <option key={idx} value={mins}>
                                {mins}
                              </option>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </select> */}
                      <input
                        type="text"
                        name="checkoutTime"
                        placeholder={t("checkoutTime")}
                        autoComplete="off"
                        value={checkOutTime}
                        disabled
                        className="form-control form-control-set"
                      />
                    </div>

                    <div className="d-flex justify-content-between communication-detail">
                      <label>
                        {t("Country of Residence :")}
                        {/* <span style={{ color: "red" }}>*</span> */}
                        {/* <span style={{ color: "red" }}>*</span>  */}
                      </label>
                      <select
                        name="country"
                        value={confirmationDetails.country || ""}
                        onChange={confirmationDetailsHandler}
                        className={
                          countryValidation
                            ? "form-error form-control form-control-set"
                            : " form-control form-control-set"
                        }
                      >
                        <option value="" default>
                          {t("Select")}
                        </option>
                        {countries?.map((country, idx) => {
                          if (
                            country?.label === confirmationDetails?.country ||
                            country?.label !== confirmationDetails?.country
                          ) {
                            return (
                              <option
                                key={country?.CountryCode}
                                value={country?.CountryCode}
                              >
                                {country?.CountryName}
                              </option>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </select>
                    </div>
                    {/* {countryValidation && (
                      <p className="paragraph-validation error-message">
                        {t("Country of residence is required. Please enter")}{" "}
                        {t("this information to proceed.")}
                      </p>
                    )} */}
                    <div className="d-flex justify-content-between communication-detail">
                      <label>
                        {" "}
                        {t("Email :")}
                        <span style={{ color: "red" }}>*</span>{" "}
                      </label>
                      <input
                        type="email"
                        onChange={confirmationDetailsHandler}
                        name="email"
                        placeholder={t("Email")}
                        autoComplete="off"
                        value={confirmationDetails.email}
                        required
                        className={
                          emailValidation
                            ? " form-error form-control form-control-set"
                            : " form-control form-control-set"
                        }
                      />
                    </div>
                    {/* {enterEmailValidation && (
                      <p className="paragraph-validation error-message">
                        {t("Email is required for check-in. Please enter a")}{" "}
                        {t("valid email address.")}
                      </p>
                    )} */}
                    {/* {emailValidation && !enterEmailValidation && (
                      <p className="paragraph-validation error-message">
                        {t("Invalid email format. Please enter a valid email")}{" "}
                        {t("address.")}
                      </p>
                    )} */}

                    {/* <div className="communication-detail">
                            <div className="mobile-number-input-container">
                              <label htmlFor="myNumberInput">Mobile Number :</label>
                              <input
                                type="number"
                                id="myNumberInput"
                                placeholder="Mobile Number"
                                onChange={confirmationDetailsHandler}
                                autoComplete="off"
                                className="form-control form-control-set"
                                name="mobileNo"
                                value={confirmationDetails.mobileNo}
                              />
                            </div>
                            <div className="error-container">
                              <p className="paragraph-validation mobile-number-message">
                              Please provide your phone number in the format (XXX)XXXXXXX.
                              </p>
                            </div>
                          </div> */}
                    <div className="communication-detail communication-detail-mobile-number">
                      <div className="input-container">
                        <label htmlFor="myNumberInput">
                          {t("Mobile Number :")}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="mobile-number-field-with-message">
                          <input
                            type="number"
                            id="myNumberInput"
                            placeholder={t("Mobile Number")}
                            onChange={confirmationDetailsHandler}
                            autoComplete="off"
                            className={
                              mobileNoValidation
                                ? " form-error form-control form-control-set"
                                : "form-control form-control-set"
                            }
                            name="mobileNo"
                            value={confirmationDetails.mobileNo}
                          />
                          {/* <p
                            className="mobile-number-format-box"
                            style={{ fontSize: "12px" }}
                          >
                            {t(
                              "Please provide your phone number in the format (XXX)XXXXXXX"
                            )}
                          </p> */}
                        </div>
                      </div>
                    </div>
                    {/* {mobileNoValidation && (
                      <p className="paragraph-validation error-message">
                        {t("Mobile number is required for check-in. Please")}{" "}
                        {t("enter a valid phone number.")}
                      </p>
                    )} */}
                    {/* <div className="d-flex reservation-detail-content-amount " style={{display:"none !important;"}}>
                            <div className="reservation-width">
                              <label>
                                Total Amount Due: {property.currency}{" "}
                                {totalDue.toFixed(2)}
                              </label>
                            </div>
                          </div> */}
                  </div>
                </div>
              </form>
            </div>
            <div class="col-6 col-left-bg">
              {" "}
              <div className="terms-condition-width">
                <div>
                  <div className="terms-title">
                    <h4>{t("Terms and Conditions")}</h4>
                    <div className="terms-condition-scroll">
                      {Array.isArray(termsAndConditionData) ? (
                        termsAndConditionData?.map((ele, idx) => (
                          <p key={idx}>{ele.content}</p>
                        ))
                      ) : (
                        <p>{termsAndConditionData || ""}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="h-100">
                  <div>
                    <div className="mt-20 d-flex justify-content-between">
                      <div className="terms-title">
                        <h4>
                          {t("I accept the Terms & Conditions")} {t("and")}{" "}
                          <a onClick={openDownloadKey}>
                            <u>{t("Privacy Policy")}</u>
                          </a>
                        </h4>
                      </div>
                      <div style={{ marginRight: "5px" }}>
                        <button onClick={clearSign} className="terms-retry-btn">
                          <i className="bx bx-revision"></i>
                        </button>
                      </div>
                    </div>

                    <div className="sign-wrapper">
                      <SignaturePad
                        signature={signature}
                        setSignature={setSignature}
                        gotSignature={gotSignature}
                        setUrl={setUrl}
                        url={url}
                        showRetryBtn={false}
                      />

                      {t("I would like to receive information from hotel") !==
                        "" ||
                      t("I would like to receive information from hotel")
                        ?.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          <div className="accept-condition">
                            <input
                              type="checkbox"
                              style={{ borderColor: "green" }}
                              className="accept-check"
                              checked={userAgreed}
                              onChange={handleCheckboxChange}
                            />
                            <span>
                              {t(
                                "I would like to receive information from hotel"
                              )}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    <div
                      className="d-flex justify-content-end"
                      style={{ marginTop: "10px" }}
                    >
                      <button
                        disabled={!url ? true : false}
                        onClick={updateProfile}
                        className="btn button-blue confirm-number-btn"
                      >
                        {t("Proceed")}
                        {/* Proceed <i className="bx bx-chevron-right"></i> */}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default UserReservationDetails;
