/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {
  DOCUMENT_SCAN,
  FACE_VERIFICATION,
  RESERVATION_LIST_FORM,
} from "../../routes/Paths";
import {
  addItem,
  getDocumentListTypes,
  getDocumentTypes,
  getScanBackData,
  getScanData,
  getScanDocResult,
  getScanDocumentCurrentCountOfGuest,
  resetDocReponse,
  resetReponse,
  resetSannedDocumentDetails,
  scannedDocumentDetails,
  setDocumentInfo,
  validateScanDocument,
} from "../../store/Checkin/Scan/documentScanSlice";
import {
  fetchReservation,
  getProfilesListData,
  getReservationData,
  resetIsFinished,
  setModuleType,
} from "../../store/Checkin/reservationformSlice";
import {
  getAllowedDocuments,
  getScanDataFromWebhost,
  getScanDataFromWebhostBack,
} from "../../network/apiService";
import {
  getParametersFromLocalStorage,
  getProfileName,
  getScanDataFullName,
  homePageHandler,
  nameValidation,
} from "../../helpers/commonHelper";
import { postAuditLog, postStageDetails } from "../../store/stageSlice";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import DocumentVerification from "../../components/UserVerification/DocumentVerification";
import ErrorPopup from "../../components/LoaderAndNotification/ErrorPopup";
import FlipDocumentModal from "../../components/Layout/Card/FlipDocumentModal";
import Footer from "../../components/Layout/Footer";
import HomePageCard from "../../components/Layout/Card/HomePageCard";
import LoaderPopup from "../../components/LoaderAndNotification/LoaderPopup";
import React from "react";
import SuccessPopup from "../../components/LoaderAndNotification/SuccessPopup";
import { TitleCaseConverter } from "../../components/Plugins/TitleCaseCoverter";
import { getModuleConfiguration } from "../../store/Checkin/moduleConfigurationSlice";
import { getPropertyInfo } from "../../store/Checkin/Scan/propertySlice";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import i18n from "i18next";
import { timerConstantValue } from "../../constants/constant";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

const PassportScanning = () => {
  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  const timeLimit = timerConstantValue;
  const [showModal, setShowModal] = useState(false);
  const [scanData, setScanData] = useState();
  const [flipDocument, setFlipDocument] = useState(false);
  const [flipModalToggle, setFlipModalToggle] = useState(false);
  const dispatch = useDispatch();

  const [userData, setUserData] = useState({});
  const [active, setActive] = useState("");
  const [loading, setLoading] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [transactionGUID, setTransactionGUID] = useState("");
  const navigate = useNavigate();
  const token = getParametersFromLocalStorage("token");
  const [show, setShow] = useState(true);

  const guestCount = useSelector(getScanDocumentCurrentCountOfGuest);
  const propertyData = useSelector(getPropertyInfo);
  const reservationData = useSelector(getReservationData);
  const profileList = useSelector(getProfilesListData);
  const scanDocResult = useSelector(getScanDocResult);
  const totalCount = parseInt(localStorage.getItem("totalCount") || 0);
  const { type } = useParams();
  const documentTypes = useSelector(getDocumentTypes);
  const documentTypesList = useSelector(getDocumentListTypes);

  const stepMaster = useSelector((state) => getModuleConfiguration(state));
  const [lastName, setLastName] = useState("");
  const [isPrimaryGuest, setIsPrimaryGuest] = useState(false);
  const scanFrontData = useSelector(getScanData);
  const scanBackData = useSelector(getScanBackData);
  const [isChildProfile, setIsChildProfile] = useState(true);

  const settings = getParametersFromLocalStorage("settings");
  const childAgeLimit = settings?.find(
    (setting) => setting.parameterName === "ChildAgeLimit"
  )?.parameterValue;

  const adultAgeLimit = settings?.find(
    (setting) => setting.parameterName === "AdultAgeLimit"
  )?.parameterValue;
  const enableChildScan = settings?.find(
    (setting) =>
      setting.parameterName === "EnableChildScan" &&
      setting.parameterValue?.toLowerCase() === "true"
  );
  const enableAdultScan = settings?.find(
    (setting) =>
      setting.parameterName === "EnableAdultScan" &&
      setting.parameterValue?.toLowerCase() === "true"
  );

  const documentScanPage =
    stepMaster && stepMaster.length > 0
      ? stepMaster.find((ele) => ele.stepCode === "SKI02")
      : null;

  const [showDocumentScanPage, setDocumentScanPage] = useState(
    documentScanPage?.isEnabled ? true : false
  );

  useEffect(() => {
    dispatch(resetSannedDocumentDetails());
    const isChild = isChildOrAdult();
    setIsChildProfile(isChild);
  }, []);

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);

    if (
      profileList !== null &&
      profileList !== undefined &&
      profileList !== null &&
      profileList?.length > guestCount
    ) {
      // const fullName =
      //   (profileList[guestCount].title !== "null" &&
      //   profileList[guestCount].title?.length > 0
      //     ? profileList[guestCount].title + "."
      //     : "") +
      //   ((profileList[guestCount].firstName?.length > 0
      //     ? nameValidation(profileList[guestCount].firstName + " ")
      //     : "") +
      //     (profileList[guestCount].lastName?.length > 0
      //       ? nameValidation(profileList[guestCount].lastName)
      //       : ""));
      setLastName(getProfileName(null, null, profileList[guestCount]));
    }

    if (guestCount === 0) {
      setIsPrimaryGuest(true);
    }
    if (type !== undefined) setModuleType(type);
    try {
      if (!showDocumentScanPage) {
        // navigate(`${FACE_VERIFICATION}`, { replace: true });
        navigate(FACE_VERIFICATION, { replace: true });
        return;
      }
    } catch (error) {
    } finally {
      clearTimeout(timeoutId);
    }
  }, []);

  useEffect(async () => {
    if (token !== null && token !== undefined && documentTypes === null) {
      const docTypes = await getAllowedDocuments(
        {
          appType: "kiosk",
        },
        token
      );

      if (docTypes?.data?.result) {
        dispatch(setDocumentInfo(docTypes?.data?.data));
      }
    }
  }, [token]);

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  useEffect(() => {
    // Set a 5 seconds delay
    const timer = setTimeout(() => {
      // Set the button visibility to true
      setShow(true);
    }, 5000);

    // Clear the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []); // Pass an empty array to run the effect only once

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };
  const setCloseFlipModel = () => {
    setFlipModalToggle(false);
  };

  const isChildOrAdult = () => {
    if (enableChildScan) {
      if (
        reservationData?.childCount > 0 &&
        guestCount > reservationData?.adultCount
      ) {
        return true;
      }
      return false;
    }
  };
  useEffect(() => {
    if (scanDocResult === true) {
      gotoNextScreen();
    }
  }, [scanDocResult]);

  const gotoNextScreen = () => {
    dispatch(resetDocReponse());
    setSuccessLoader(false);
    if (type === "scan") {
      // navigate(`${RESERVATION_LIST_FORM}`, { replace: true });
      navigate(RESERVATION_LIST_FORM, { replace: true });
    } else {
      const isFaceMatched = scanData?.isFaceMatch ?? false;
      if (isFaceMatched === false) {
        setTimeout(() => {
          // navigate(`${FACE_VERIFICATION}`, { replace: true });
          navigate(FACE_VERIFICATION, { replace: true });
          return;
        }, 2000);
      } else {
        // navigate(`${DOCUMENT_SCAN}`, { replace: true });
        navigate(DOCUMENT_SCAN, { replace: true });
      }
    }
  };

  const addScanData = (data, backsidedata) => {
    if (type === "scan") {
      // if (backdata !== null && backdata !== undefined) {
      //   dispatch(
      //     scannedDocumentDetails({ scanData: data, scanBackData: backdata })
      //   );
      // } else if (data !== null && data !== undefined)
      //   dispatch(scannedDocumentDetails({ scanData: data }));
    } else {
      let currentProfile = null;
      if (
        profileList !== null &&
        profileList !== undefined &&
        profileList?.length > guestCount
      ) {
        currentProfile = profileList[guestCount];
      }
      if (backsidedata !== null && backsidedata !== undefined) {
        let backdata = { ...backsidedata };

        let names = [];
        if (
          scanFrontData?.GivenName?.split(" ").length >
            scanFrontData?.FullName?.split(" ").length &&
          scanFrontData?.GivenName !== frontdata.FullName
        ) {
          names = scanFrontData?.GivenName?.split(" ");
        } else {
          names = scanFrontData?.FullName?.split(" ");
        }

        let bnames = [];
        if (
          backdata?.GivenName?.split(" ").length >
            backdata?.FullName?.split(" ").length &&
          backdata?.GivenName !== backdata.FullName
        ) {
          bnames = backdata?.GivenName?.split(" ");
        } else {
          bnames = backdata?.FullName?.split(" ");
        }

        if (
          (backdata !== undefined &&
            backdata !== null &&
            backdata?.FirstName) ||
          "" === ""
        ) {
          backdata.FirstName =
            scanFrontData?.FirstName ||
            (Array.isArray(names) && names[names.length - 1]) ||
            (Array.isArray(bnames) && bnames[names.length - 1]) ||
            "";
        }
        if (
          (backdata !== undefined &&
            backdata !== null &&
            backdata?.MiddleName) ||
          "" === ""
        ) {
          backdata.MiddleName =
            scanFrontData?.MiddleName ||
            (Array.isArray(names) && names[1]) ||
            (Array.isArray(bnames) && bnames[1]) ||
            "";
        }
        if (
          (backdata !== undefined && backdata !== null && backdata?.LastName) ||
          "" === ""
        ) {
          backdata.LastName =
            scanFrontData?.LastName ||
            (Array.isArray(names) && names[0]) ||
            (Array.isArray(bnames) && bnames[0]) ||
            "";
        }

        let frontdata = { ...scanFrontData };

        if (
          (frontdata !== undefined &&
            frontdata !== null &&
            frontdata?.FirstName) ||
          "" === ""
        ) {
          frontdata.FirstName =
            backdata?.FirstName ||
            (Array.isArray(names) && names[names.length - 1]) ||
            (Array.isArray(bnames) && bnames[names.length - 1]) ||
            "";
        }
        if (
          (frontdata !== undefined &&
            frontdata !== null &&
            frontdata?.MiddleName) ||
          "" === ""
        ) {
          frontdata.MiddleName =
            backdata?.MiddleName ||
            (Array.isArray(names) && names[1]) ||
            (Array.isArray(bnames) && bnames[1]) ||
            "";
        }
        if (
          (frontdata !== undefined &&
            frontdata !== null &&
            frontdata?.LastName) ||
          "" === ""
        ) {
          frontdata.LastName =
            backdata?.LastName ||
            (Array.isArray(names) && names[0]) ||
            (Array.isArray(bnames) && bnames[0]) ||
            "";
        }

        dispatch(
          addItem({
            scanData: frontdata,
            scanBackData: backdata,
            profile: currentProfile,
            reservation: reservationData,
          })
        );
      } else if (data !== null && data !== undefined) {
        let frontdata = { ...data };

        let names = [];
        if (
          frontdata.GivenName?.split(" ").length >
            frontdata.FullName?.split(" ").length &&
          frontdata.GivenName !== frontdata.FullName
        ) {
          names = frontdata?.GivenName?.split(" ");
        } else {
          names = frontdata?.FullName?.split(" ");
        }

        if (
          frontdata !== undefined &&
          frontdata !== null &&
          (frontdata?.FirstName ?? "") === ""
        ) {
          frontdata.FirstName =
            data?.FirstName ||
            (Array.isArray(names) && names[names.length - 1]) ||
            "";
        }
        if (
          frontdata !== undefined &&
          frontdata !== null &&
          (frontdata?.MiddleName ?? "") === ""
        ) {
          frontdata.MiddleName =
            data?.MiddleName || (Array.isArray(names) && names[1]) || "";
        }
        if (
          frontdata !== undefined &&
          frontdata !== null &&
          (frontdata?.LastName ?? "") === ""
        ) {
          frontdata.LastName =
            data?.LastName || (Array.isArray(names) && names[0]) || "";
        }
        dispatch(
          addItem({
            scanData: frontdata,
            profile: currentProfile,
            reservation: reservationData,
          })
        );
      }
    }
  };

  const fetchReservationCompleted = (
    reservationList,
    scanData,
    scanBackData
  ) => {
    if (
      reservationList !== null &&
      reservationList !== undefined &&
      reservationList?.result === true
    ) {
      dispatch(resetIsFinished());
      if (
        reservationList !== null &&
        reservationList !== undefined &&
        reservationList?.data !== null &&
        reservationList.data.length > 0 &&
        type === "scan"
      ) {
        setFlipDocument(false);
        setFlipModalToggle(false);
        gotoNextScreen();
        // if (scanBackData !== null && scanBackData !== undefined) {
        //   // dispatch(
        //   //   scannedDocumentDetails({
        //   //     scanData: scanData,
        //   //     scanBackData: scanBackData,
        //   //   })
        //   // );
        // } else if (scanData !== null && scanData !== undefined)
        //   dispatch(scannedDocumentDetails({ scanData: scanData }));
        // setLoading(false);
      } else {
        setLoading(false);
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t("No reservation details found"),
        }));
      }
    } else {
      if (
        reservationList?.error !== undefined &&
        reservationList?.error !== null &&
        reservationList?.error !== ""
      ) {
        setLoading(false);
        setSuccessLoader(false);
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: reservationList?.error,
        }));
      } else if (
        reservationList?.data?.message !== undefined &&
        reservationList?.data?.message !== null &&
        reservationList?.data?.message !== ""
      ) {
        setLoading(false);
        setSuccessLoader(false);
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: reservationList?.data?.message,
        }));
      } else {
        setLoading(false);
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t("No reservation details found"),
        }));
      }
    }
  };

  const scanCompleted = async (response, scanData, scanBackData) => {
    if (response?.isFinished === true && response?.result === true) {
      dispatch(
        scannedDocumentDetails({
          scanData: scanData,
          scanBackData: scanBackData,
        })
      );
      debugger;
      if (
        response?.isFlip === true &&
        (scanBackData === null || scanBackData === undefined)
      ) {
        setLoading(false);
        setFlipDocument(true);
        setFlipModalToggle(true);
        dispatch(resetReponse());
      } else {
        if (type === "scan") {
          let LastName = "";
          if (
            scanBackData !== null &&
            scanBackData !== undefined &&
            scanBackData.LastName !== null &&
            scanBackData.LastName !== undefined &&
            scanBackData.LastName !== ""
          ) {
            LastName = scanBackData.LastName;
          } else if (
            LastName === "" &&
            scanData !== null &&
            scanData !== undefined &&
            scanData.LastName !== null &&
            scanData.LastName !== undefined &&
            scanData.LastName !== ""
          ) {
            LastName = scanData.LastName;
          } else if (
            LastName === "" &&
            scanFrontData !== null &&
            scanFrontData !== undefined &&
            scanFrontData.LastName !== null &&
            scanFrontData.LastName !== undefined &&
            scanFrontData.LastName !== ""
          ) {
            LastName = scanFrontData.LastName;
          }
          await dispatch(
            fetchReservation({
              lastName: LastName,
              propertyCode: propertyData?.data?.propertyCode,
              token: token,
            })
          )
            .then((response) => {
              if (response?.payload ?? null !== null) {
                fetchReservationCompleted(
                  response.payload,
                  scanData,
                  scanBackData
                );
              } else {
                setErrorModalData((state) => ({
                  ...state,
                  toggleError: true,
                  errorMessage: t("Failed to get response") + "!!",
                }));

                setLoading(false);
              }
            })
            .catch((error) => {
              // Handle the error here, e.g., log it or display an error message.
              setErrorModalData((state) => ({
                ...state,
                toggleError: true,
                errorMessage: error.error,
              }));
              setLoading(false); // Set loading to false in case of an error.
            });
        } else {
          let currentProfile = null;
          if (
            profileList !== null &&
            profileList !== undefined &&
            profileList?.length > guestCount
          ) {
            currentProfile = profileList[guestCount];
          }
          const scannedFullName = getScanDataFullName(scanData, scanBackData);
          await dispatch(
            validateScanDocument({
              scanData: (scanBackData === undefined || scanBackData === null ? scanData : scanBackData),
              profile: currentProfile,
              isChild: isChildProfile,
              adultAgeLimit,
              childAgeLimit,
              fullName: scannedFullName,
            })
          )
            .unwrap()
            .then((response) => {
              addScanData(scanData, scanBackData);
            });
        }
        dispatch(resetReponse());
      }
    } else {
      dispatch(resetReponse());
      setLoading(false);
      setSuccessLoader(false);
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: response?.error,
      }));
    }
  };

  const resultsRef = React.useRef(scanData);
  resultsRef.current = scanData;

  useEffect(() => {
    if (userData.LastName && userData.CheckOutDate) {
      setActive("active");
    } else {
      setActive("");
    }
  }, [userData.CheckOutDate, userData.LastName]);

  const timeoutId = setTimeout(() => {
    if (!resultsRef.current) {
      return;
    }
  }, 30000);

  //Backside
  const completedScanDone = async () => {
    try {
      setLoading(true);
      const scanDataBack = await Promise.race([
        getScanDataFromWebhostBack(-1),
        wait(30000),
      ]);
      addLog(
        JSON.stringify(scanDataBack.data),
        "",
        "Back Scan of guest " + guestCount
      );
      if (scanDataBack.data !== null && scanDataBack.data?.Result === true) {
        //scanDataBack.data.transactionGUID = transactionGUID;
        const updatedScanData = {
          ...scanDataBack.data,
          //DocumentImageSide1: scanDataBack.data?.DocumentImageSide2,
          transactionGUID: transactionGUID,
        };

        if (
          (scanData.Portrait === null || scanData.Portrait === "") &&
          (scanDataBack.data.Portrait === null ||
            scanDataBack.data.Portrait === "")
        ) {
          addStageDeatils(
            true,
            "Invalid face image detected. Please scan front side of document again.",
            ""
          );
          setErrorModalData((state) => ({
            ...state,
            toggleError: true,
            errorMessage: t(
              "Invalid face image detected. Please scan front side of document again."
            ),
          }));
          setLoading(false);

          setSuccessLoader(false);

          return;
        } else {
          setFlipDocument(false);
          const currentProfile = profileList[guestCount];
          const scannedFullName = getScanDataFullName(
            updatedScanData,
            scanData
          );
          dispatch(
            validateScanDocument({
              scanData: updatedScanData,
              profile: currentProfile,
              isChild: isChildProfile,
              adultAgeLimit,
              childAgeLimit,
              isBackSide: true,
              fullName: scannedFullName,
            })
          )
            .unwrap()
            .then((response) => {
              scanCompleted(response, null, updatedScanData);
            });
        }
      } else {
        addStageDeatils(false, "", scanData.data?.Message);
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t(
            "No document detected. Please place a valid document on the scanner for verification."
          ),
        }));
        setFlipDocument(false);
        setFlipModalToggle(false);
        setLoading(false);
        setSuccessLoader(false);

        return;
      }
    } catch (error) {
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: error.message,
      }));
      setLoading(false);
      setFlipDocument(false);
      setFlipModalToggle(false);
      setSuccessLoader(false);
      return;
    }
  };
  const addLog = (response, request, message) => {
    const confirmationNo = localStorage.getItem("confirmationNo");
    const data = {
      ActionName: "Scan",
      Message: message,
      ControllerName: "kiosk",
      Request: request ?? "",
      PMSConfirmationNo: confirmationNo,
      AppType: "kiosk",
      Response: response ?? "",

      PropertyCode: propertyData?.data?.propertyCode,
    };
    dispatch(postAuditLog({ data, token }));
  };

  const addStageDeatils = (IsSuccess, SuccessMessage, FailureReason) => {
    const confirmationNo = localStorage.getItem("confirmationNo");
    const propertyCode = localStorage.getItem("propertyCode");
    const data = {
      PMSConfirmationNo: confirmationNo,
      IsSuccess: IsSuccess,
      StepCode: "SKI03",
      SubStep: "DocumentUpload",
      AppType: "kiosk",
      SuccessMessage: SuccessMessage ?? "",
      FailureReason: FailureReason ?? "",
      PropertyCode: propertyCode,
    };

    dispatch(postStageDetails({ data, token }));
  };

  const wait = (milliseconds) =>
    new Promise((res, rej) =>
      setTimeout(
        () =>
          rej(
            new Error(`Request timed out after ${milliseconds / 1000} seconds`)
          ),
        milliseconds
      )
    );

  const scanDocument = async () => {
    try {
      setLoading(true);

      let scanData;
      let childAge;

      scanData = await Promise.race([
        getScanDataFromWebhost(guestCount),
        wait(60000),
      ]);

      addLog(
        JSON.stringify(scanData.data),
        scanData.data?.Result,
        "Front Scan of guest " + guestCount
      );

      if (scanData.data != null && scanData.data?.Result === true) {
        addStageDeatils(true, scanData.data?.Message, "");
        const uniqueId = uuidv4();
        setTransactionGUID(uniqueId);
        if (enableChildScan) {
          if (isChildOrAdult && scanData?.data?.Age > childAgeLimit) {
            setLoading(false);
            setSuccessLoader(false);
            setErrorModalData((state) => ({
              ...state,
              toggleError: true,
              errorMessage:
                t("Invalid document. Child age can not be greater than ") +
                childAgeLimit,
            }));
            return;
          }
        }
        if (
          (enableChildScan === null || enableChildScan === undefined) &&
          scanData?.data?.Age !== undefined &&
          scanData?.data?.Age !== "" &&
          parseInt(scanData?.data?.Age) < adultAgeLimit
        ) {
          setLoading(false);
          setSuccessLoader(false);
          setErrorModalData((state) => ({
            ...state,
            toggleError: true,
            errorMessage:
              t("Invalid document. Adult should be greater than") +
              adultAgeLimit,
          }));
          return;
        }

        if (
          isChildProfile &&
          scanData?.data?.Age !== undefined &&
          scanData?.data?.Age !== "" &&
          parseInt(scanData?.data?.Age) > childAgeLimit
        ) {
          setLoading(false);
          setSuccessLoader(false);
          setErrorModalData((state) => ({
            ...state,
            toggleError: true,
            errorMessage:
              t("Invalid document. Child age can not be greater than") +
              childAge,
          }));
          return;
        }
        if (
          scanData?.data?.LastName === "" &&
          scanData?.data?.GivenName === "" &&
          scanData?.data?.Portrait === ""
        ) {
          setLoading(false);
          setSuccessLoader(false);
          setErrorModalData((state) => ({
            ...state,
            toggleError: true,
            errorMessage: t(
              "Invalid document. Please scan back side of the document."
            ),
          }));
          return;
        }
        if (
          scanData?.data?.LastName === "" &&
          scanData?.data?.GivenName !== "" &&
          scanData?.data?.GivenName !== undefined &&
          scanData?.data?.GivenName !== null
        ) {
          scanData.data.LastName = scanData?.data?.GivenName;
        }

        const updatedScanData = {
          ...scanData.data,
          transactionGUID: uniqueId, // Modify the transactionGUID property
        };

        setScanData(updatedScanData);

        if (profileList !== null && profileList?.length > guestCount) {
          const currentProfile = profileList[guestCount];
          const scannedFullName = getScanDataFullName(updatedScanData, null);
          await dispatch(
            validateScanDocument({
              scanData: updatedScanData,
              profile: currentProfile,
              isChild: isChildProfile,
              adultAgeLimit,
              childAgeLimit,
              fullName: scannedFullName,
            })
          )
            .unwrap()
            .then((response) => {
              scanCompleted(response, updatedScanData, null);
            });
        } else {
          await dispatch(
            validateScanDocument({
              scanData: updatedScanData,
              isChild: isChildProfile,
              adultAgeLimit,
              childAgeLimit,
            })
          )
            .unwrap()
            .then((response) => {
              scanCompleted(response, updatedScanData, null);
            });
        }
      } else {
        addLog(JSON.stringify(scanData.data), scanData.data?.Result, "");
        addStageDeatils(false, "", scanData.data?.Message);
        setLoading(false);
        setSuccessLoader(false);
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t(
            "No document detected. Please place a valid document on the scanner for verification."
          ),
        }));
        return;
      }
    } catch (error) {
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: t("Error while scanning document"),
      }));
      setLoading(false);
      setSuccessLoader(false);
      return;
    } finally {
      clearTimeout(timeoutId);
    }
    return;
  };
  const handlebackPage = () => {
    try {
      homePageHandler(dispatch, navigate);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };
  return (
    <div>
      <main>
        <LoaderPopup loading={loading} />
        <ErrorPopup
          closeErrorModal={closeErrorModal}
          erroModalData={erroModalData}
        />

        <SuccessPopup
          loading={successLoader}
          successMessage={t("Document scanning completed successfully")}
        />
        <FlipDocumentModal
          completedScanDone={completedScanDone}
          flipModalToggle={flipModalToggle}
          closeModel={setCloseFlipModel}
        />

        {documentTypesList !== null && documentTypesList?.length > 0 && (
          <>
            <DocumentVerification
              loading={loading}
              successLoader={successLoader}
              successMessage={t("Document scanning completed successfully")}
              openModal={openModal}
              closeErrorModal={closeErrorModal}
              erroModalData={erroModalData}
              userData={userData}
              scanDocumentHandler={scanDocument}
              completedScanDone={completedScanDone}
              flipModalToggle={flipModalToggle}
              guestCount={guestCount}
              totalCount={totalCount}
              show={show}
              currentProfile={
                profileList !== undefined &&
                profileList !== null &&
                guestCount >= 0
                  ? profileList[guestCount]
                  : null
              }
              documentTypes={documentTypesList}
              onBackPressed={handlebackPage}
              lastName={lastName}
              isPrimaryGuest={isPrimaryGuest}
              closeModel={setCloseFlipModel}
              isChild={isChildProfile}
            />
          </>
        )}

        <Footer
          page={"documentscanning"}
          showModal={showModal}
          openModal={openModal}
          timerCounter={timerCounter}
          timerValue={timerValue}
          restartTimer={restartTimer}
          stopTimer={stopTimer}
          timeLimit={timeLimit}
        />
      </main>
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
    </div>
  );
};

export default PassportScanning;
