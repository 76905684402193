import { API_BASE_URL2, API_URL } from ".";
import {
  reservationResponse,
  scanIDBackDocument,
  scanIDDocument,
  scanPassportData,
  scanPassportData2,
  scanQRData,
} from "../constants/scanjsondata";
import { scanID, scanID2 } from "../constants/scanjson";

import axios from "axios";
import { formatSysDate } from "../helpers/commonHelper";

//axios header setup
axios.defaults.baseURL = API_URL;
axios.defaults.headers.common["Accept"] = "application/json, text/plain, */*";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

const axios2 = axios.create({
  // baseURL: API_BASE_URL2,
  // headers: {
  //   common: {
  //     "Accept": "application/json, text/plain, */*",
  //     "Access-Control-Allow-Origin": "*",
  //     "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  //   },
  //   post: {
  //     "Content-Type": "application/json",
  //   },
  // },
  baseURL: API_BASE_URL2,
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});

// auth api
export const loginApi = (data) => {
  return axios.post("api/Authentication/GenerateToken", data);
};

// Generate Token api
export const generateTokenApi = (data) => {
  return axios.post("api/Authentication/GenerateToken", data);
};

export const makePayment = (data) => {
  return axios.post("api/PosPayment/Sale", data);
};

//***************************For Scanning******************************* */
export const sendFolioEmail = (data, token) => {
  const searchParams = new URLSearchParams(data);
  let query = searchParams.toString();
  return axios.get(`api/Email/FolioSend?${query}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const sendCheckinMail = (data, token) => {
  const searchParams = new URLSearchParams(data);
  let query = searchParams.toString();
  return axios.get(`api/Email/SendCheckinMail?${query}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

//get user details
export const getReservationDetails = (data, token) => {
  const searchParams = new URLSearchParams(data);
  let query = searchParams.toString();
  return axios.get(`api/Kiosk/GetReservationDetails?${query}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  //return { data: reservationResponse };
};

export const getReservationPaymentInfo = (data, token) => {
  const searchParams = new URLSearchParams(data);
  let query = searchParams.toString();
  return axios.get(`api/Kiosk/GetReservationPaymentInfo?${query}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  //return { data: reservationResponse };
};

export const getKioskConfiguration = (data, token) => {
  const searchParams = new URLSearchParams(data);
  let query = searchParams.toString();
  return axios.get(`api/Kiosk/GetKioskConfiguration?${query}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getKioskData = (token) => {
  return axios.get(`api/Master/GetKioskMaster`, {
    //headers: { Authorization: `Bearer ${token}` },
  });
};
export const getCountries = (token) => {
  return axios.get(`api/Master/GetCountries`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getManualAuthentication = (data, token) => {
  const searchParams = new URLSearchParams(data);
  let query = searchParams.toString();
  return axios.get(`api/Kiosk/ManualAuthenticationAllowed?${query}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

//GetAllProfile by ConfirmationNo
export const getAllProfile = (data, token) => {
  return axios.get(`api/Kiosk/GetAllProfiles?ConfirmationNo=${data?.replace(" ","")}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const getAllReservationProfile = (data, nameId, token) => {
  return axios.get(
    `api/Kiosk/GetAllReservationProfiles?ConfirmationNo=${data?.replace(" ","")}&ReservationNameID=${nameId}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export const checkReservationDB = (data, token) => {
  return axios.post("api/Kiosk/CheckReservationDB", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

//FetchFolio by ConfirmationNo
export const fetchFolio = (data, token) => {
  const searchParams = new URLSearchParams(data);
  let query = searchParams.toString();
  return axios.get(`api/Kiosk/FetchFolio?${query}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

//GetAvailableRooms
export const getAvailableRooms = (data, token) => {
  const searchParams = new URLSearchParams(data);
  let query = searchParams.toString();
  return axios.get(`api/Kiosk/GetAvailableRooms?${query}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

//GetAllowedDocuments
export const getAllowedDocuments = (data, token) => {
  const searchParams = new URLSearchParams(data);
  let query = searchParams.toString();

  return axios.get(`api/Master/GetDocumentMaster?${query}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Is early checkin
export const isEarlyCheckin = (token,confirmationNo,reservationNameId) => {
  // const searchParams = new URLSearchParams(data);
  // let query = searchParams.toString();
  return axios.get(`api/Kiosk/VerifyEarlyCheckIn?ConfirmationNo=${confirmationNo}&ReservationNameId=${reservationNameId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// navigation get current step
export const currentStep = (data, token) => {
  const searchParams = new URLSearchParams(data);
  let query = searchParams.toString();
  return axios.get(`api/Master/GetCurrentStep?${query}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// navigation url/ stage api
export const navigationPath = (data, token) => {
  const searchParams = new URLSearchParams(data);
  let query = searchParams.toString();
  return axios.get(`api/Kiosk/GetNavigationStep?${query}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Get terms and conditions data
export const getTermsAndConditons = (token) => {
  return axios.get(`api/Reservation/GetTermsAndCondition`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getPropertyData = (token) => {
  return axios.get(`api/Master/GetPropertyInfo`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

//get user docuemnts for qr code
export const fetchProfileDocuments = (data, token) => {
  const searchParams = new URLSearchParams(data);
  let query = searchParams.toString();
  return axios.get(`api/Kiosk/GetAllProfileDocuments?${query}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// ******************** Post APIS ************************//

// AssignRoom
export const assignRoom = (data, token, query) => {
  const searchParams = new URLSearchParams(query);
  let property = searchParams.toString();
  return axios.post(`api/Kiosk/AssignRoom?${property}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

//Add Package
export const addPackage = (data, token) => {
  return axios.post("api/Kiosk/AddPackage", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Get Room status
export const getRoomStatus = (data, token) => {
  return axios.post("api/Kiosk/GetRoomStatus", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

//Room upsell
export const roomUpsell = (data, token) => {
  return axios.post("api/Kiosk/RoomUpsell", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
// Queue Room
export const queueRoom = (data, token, query) => {
  return axios.post(`api/Reservation/QueueRoom`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
//update user Profile - reservation details page
export const updateUserProfile = (data, token, query) => {
  const searchParams = new URLSearchParams(query);
  let property = searchParams.toString();
  return axios.post(`api/Kiosk/UpdateProfileDocument?${property}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

//update user Profile - reservation details page
export const updateSingleProfile = (data, token) => {
  return axios.post("api/Kiosk/UpdateProfile", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

//checkin - after payment call this api
export const checkIn = (data, token) => {
  return axios.post("api/Kiosk/CheckIn", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

//checkout
export const checkOut = (data, token) => {
  return axios.post("api/Kiosk/CheckOut", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
const wait = (milliseconds) =>
  new Promise((res, rej) => setTimeout(milliseconds));
//Encode key - create key page api call
export const encodeKey = async (data, token, keyVendor) => {
 
 
  if (keyVendor === "DORMAKABA-DISPENSER") {
    var response = await cardPosition(data?.encoderPort);
    if (response?.status === 200) {
      wait(3000);
      response = await cardAccept(data?.encoderPort);
      wait(3000);

      if (response?.status === 200) {
        wait(3000);

       
        const response3 = await saflokEncodekey(data);

        if (response3?.status === 200 && response3?.data?.result === true) {
          let response1 = await cardEject(data?.encoderPort);
          response1 = await cardDrop(data?.encoderPort);
          response1 = await ledOn(data?.encoderPort);
          response1 = await ledOff(data?.encoderPort);

          return {
            data: {
              result: response3?.data?.result ?? true,
            },
            result: response3?.data?.result ?? true,
          };
        } else {
          return {
            data: {
              result: false,
            },
            result: false,
          };
        }
      }
    }
  } else if (keyVendor === "DORMAKABA") {
    const response = await saflokEncodekey(data);

    if (response?.status === 200) {
      return {
        data: {
          result: response?.data?.result ?? true,
        },
        result: response?.data?.result ?? true,
      };
    }
  } else {
    return axios.post("api/Kiosk/EncodeKey", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }
};

// Save reg card  - (signature pad api call)
export const saveRegistrationCard = (data, token) => {
  return axios.post("api/Reservation/SaveRegistrationCard", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Save folio card  - (signature pad api call)
export const saveFolio = (data, token) => {
  return axios.post("api/Reservation/SaveFolio", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

//trigger payment
export const triggerPayment = (data, token) => {
  return axios.post("api/PosPayment/Sale", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

//payemnt update to PMS
export const postPaymentToPMS = (data, token) => {
  return axios.post("api/Kiosk/PostPaymentToPMS", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getStepWithModules = (data, token) => {
  const searchParams = new URLSearchParams(data);
  let query = searchParams.toString();

  return axios.get(`api/Master/GetStepWithModules?${query}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const getSettingList = (token) => {
  const header = {
    Authorization: `Bearer ${token}`,
  };
  return axios.get("api/Reservation/GetSettingParameter", { headers: header });
};
export const getUIConfigurations = (token) => {
  return axios.get(`api/UIConfig/GetAllUIConfiguration`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getAllUpsellPackages = async (token, ConfirmNo, ReservationNameID) => {
  try {
    return axios.get(`api/Upsell/GetAllPackages?ConfirmationNo=${ConfirmNo}&ReservationNameID=${ReservationNameID}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.error("Error verifying face:", error);
    throw error;
  }
};



//getReservationTypes
export const getReservationTypes = (data, token) => {
  const searchParams = new URLSearchParams(data);
  let query = searchParams.toString();
  return axios.get(`api/Master/getReservationTypes?${query}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

//getSettingParameter
export const getSettingParameter = (data, token) => {
 
  const searchParams = new URLSearchParams(data);
  let query = searchParams.toString();
  return axios.get(`api/Reservation/getSettingParameter?${query}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getActivePreAuth = (token) => {
  return axios.get(`api/Payment/getActivePreAuth`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const fetchLanguage = (token) => {
  return axios.get(`api/Payment/fetchLanguage`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateAdultCount = (data, token) => {
  const searchParams = new URLSearchParams(data);
  let query = searchParams.toString();
  return axios.get(`api/Kiosk/ModifyReservationBooking`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

//#region Webhost

// get clear scan/qr data
export const clearScanData = () => {
   return axios2.get(`scanner/ClearResults`, { timeout: 30000 });
  // return { data: true };  
};

// get scan/qr data

export const getScanDataFromWebhost = (guestCount) => {
  //  if (guestCount == 1) return { data: scanPassportData2 }; 
  //   if (guestCount == 2) return { data: scanPassportData3 };
  // return { data:scanPassportData};
   //else
   
  //return { data:scanID };
  return axios2.get(`scanner/scan?isflip=false`);
};
export const getScanDataFromWebhostBack = (guestCount) => {
  // if (guestCount == 1) return { data: scanPassportData2 };
  // if (guestCount == 2) return { data: scanPassportData3 };
   //return { data:scanIDBackDocument};
   return axios2.get(`scanner/scan?isflip=true`);
   //return { data:scanID2 };
};

export const getScanDataFromWebhostQR = () => {
  return axios2.get(`scanner/scan`);
  // return { data: scanQRData };
};
// get start face verification scan/qr data
export const faceVerification = () => {
  return axios2.get(`scanner/StartFaceVerification`);
};
//#region Dormakaba
export const cardPosition = (port) => {
  return axios2.get(`Key/CardPosition?Port=` + port);
};
export const cardAccept = (port) => {
  return axios2.get(`Key/CardAccept?Port=` + port);
};
export const cardEject = (port) => {
  return axios2.get(`Key/CardEject?Port=` + port);
};
export const cardDrop = (port) => {
  return axios2.get(`Key/CardDrop?Port=` + port);
};
export const ledOn = (port) => {
  return axios2.get(`Key/DeviceLedOn?Port=` + port);
};
export const ledOff = (port) => {
  return axios2.get(`Key/DeviceLedOff?Port=` + port);
};
export const saflokEncodekey = (data) => {
  data.confirmationNo = data.confirmationNo?.replace("-", "");
  data.reservationNo = data.reservationNo?.replace("-", "");

  // let today = new Date();
  // data.roomNumber = "205";
  // data.checkinDateTime = formatSysDate(today);
  // data.arrivalDate = formatSysDate(today);

  // var date = new Date();
  // // add a day
  // date.setDate(date.getDate() + 1);
  // data.checkoutDateTime = formatSysDate(date);
  // data.departureDate = formatSysDate(date);

  const basicAuth = encodeBasicAuth(
    data.encoderUsername ?? "DummyUser",
    data.encoderPassword ?? "DummyPwd"
  );

  return axios2.post(`saflok/encodekey`, data, {
    headers: { Authorization: `Basic ${basicAuth}` },
  });
};
//#endregion
// Get language master
export const getLanguageMaster = (token) => {
  return axios.get(`api/Reservation/GetLanguageMaster`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const pushAuditLog = (token,data) => {
 
  return axios.post(`api/Kiosk/AuditTrailLog`,data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const pushStageDetails= (token,data) => {
 
  return axios.post(`api/Kiosk/UpdateStageFromKiosk`,data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
const encodeBasicAuth = (username, password) => {
  return btoa(`${username}:${password}`);
};
//#endregion
