import React, { useEffect, useRef, useState } from "react";
import {
  getParametersFromLocalStorage,
  homePageHandler,
} from "../../helpers/commonHelper";
import { useDispatch, useSelector } from "react-redux";

import { Camera } from "react-camera-pro";
import FaceRcgFailedModal from "../../components/Layout/Card/FaceRcgFailedModal";
import { getconfirmationnumberData } from "../../store/Checkin/ConfirmationNumber/confirmationNumberSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ImageCaptureComponent = ({
  onCapture,
  reloadCapture,
  onManualAuthorization,
}) => {
  const { t } = useTranslation();
  const [capturedImages, setCapturedImages] = useState([]);
  const cameraRef = useRef(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [facialRecognisationFailed, setFacialRecognisationFailed] =
    useState(false);
  const userData = useSelector(getconfirmationnumberData);
  const [faceSelfieModal, setFaceSelfieModal] = useState(false);
  const settings = getParametersFromLocalStorage("settings");

  const enableSelfie = settings?.find(
    (setting) =>
      setting.parameterName === "EnableSelfie" &&
      setting.parameterValue?.toLowerCase() === "true"
  );
  const [loading, setLoading] = useState(false);




  useEffect(() => {
    if (cameraRef?.current !== null) {
      setTimeout(() => {
        setIsCameraOpen(true);
      }, 2000);

      // const checkCameraStatus = () => {
      //   const isOpen = cameraRef.current.isStreaming(); // Replace with the actual method or property
      //   setIsCameraOpen(isOpen);
      // };
      // checkCameraStatus();
      // const intervalId = setInterval(checkCameraStatus, 1000); // Check every second
      // // Clean up the interval on component unmount
      // return () => clearInterval(intervalId);
    }
  }, [cameraRef]);

  useEffect(() => {
    if (enableSelfie !== null && enableSelfie !== undefined) {
      setFaceSelfieModal(true);
    } else {
      setLoading(true);
      setFaceSelfieModal(false);
      const captureInterval = setInterval(() => {
        setLoading(false);
        captureImage();
        return;
      }, 4000);
      setTimeout(() => {
        clearInterval(captureInterval);
      }, 120000);
      return () => clearInterval(captureInterval); // Cleanup function
    }
  }, [reloadCapture]);

  const captureImage = async () => {
    try {
      const image = await cameraRef.current.takePhoto();
      setCapturedImages((prevImages) => [...prevImages, image]);
      onCapture(image);
      
    } catch (error) {
      if (enableSelfie === null || enableSelfie === undefined) {
        console.error("Error capturing image:", error);
        setFacialRecognisationFailed(true);
      }
    }
  };
  const backToHomePage = () => {
    homePageHandler(dispatch, navigate);
  };

  const retryFace = async () => {
    setFacialRecognisationFailed(false);
  };

  return (
    <>
      <div
        id="faceCaptureComponent"
        style={{
          borderRadius: "15px",
          overflow: "hidden",
          border: "4px solid #676563",
        }}
      >
        <Camera
          ref={cameraRef}
          aspectRatio={19 / 12}
          style={{ width: "100%", height: "auto" }}
        />

        {facialRecognisationFailed && (enableSelfie === null || enableSelfie === undefined) && (
          <FaceRcgFailedModal
            ficalRecognisationModal={facialRecognisationFailed}
            completedFacialRecognisation={onManualAuthorization}
            backToHomePage={backToHomePage}
            retryFace={retryFace}
            manualAuthenticationRequired={true}
          />
        )}

        {faceSelfieModal === true && (
          // <FaceCaptureModal
          //   show={faceSelfieModal}
          //   completeCapture={()=>{
          //     captureImage();
          //   }}
          // />
          <button
            disabled={isCameraOpen == true ? false : true}
            style={{ marginTop: "20px", padding: "5px", marginBottom: "10px" }}
            className="btn button-blue scan-btn"
            onClick={() => {
              captureImage();
            }}
          >
            {t("Take Selfie")}
          </button>
        )}
      </div>
    </>
  );
};

export default ImageCaptureComponent;
