import { Link } from "react-router-dom";
import LoaderPopup from "../../LoaderAndNotification/LoaderPopup";
import ErrorPopup from "../../LoaderAndNotification/ErrorPopup";

import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import { getSelectedLanguage } from "../../../store/Checkin/languageSlice";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const PaymentModal = ({

  setPaymentToggle,
  navigateUrl,
  loading2,
  loading3,
  erroModalData2,
  erroModalData3,
  erroModalData4,
  closeErrorModal2,
  closeErrorModal3,
  closeErrorModal4,
  paymentTypeHandler,
  totalAmount,
}) => {

  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
     }, []);


  return (
    <div className="modal" style={{ zIndex: "1" }}>
      <LoaderPopup loading={loading2} />
      <LoaderPopup loading={loading3} />
      <ErrorPopup
        closeErrorModal={closeErrorModal2}
        erroModalData={erroModalData2}
      />
      <ErrorPopup
        closeErrorModal={closeErrorModal3}
        erroModalData={erroModalData3}
      />

      <ErrorPopup
        closeErrorModal={closeErrorModal4}
        erroModalData={erroModalData4}
      />
      <div className="modal-dialog modal-lg modal-top">
        <div className="modal-content">
          <div className="modal-body">
            <div className="text-center">
              <div>
                <img src="assets/images/person-image.PNG" alt="" />
              </div>
              <div className="mt-30 scan-text">
                <h5 className="cardoptions">{t("Payment Method")}</h5>
              </div>
            </div>
            <div className="text-center mt-30">
              <button
                onClick={() => {
                  paymentTypeHandler("creditcard",totalAmount);
                }}
                className="btn modal-no-btn credit-card-btn"
              >
                <i className="bx bx-credit-card-alt payment-card-icon"></i>
                {t("Credit card")}
              </button>
              <button
                onClick={() => {
                  paymentTypeHandler("debitcard",totalAmount);
                }}
                className="btn modal-no-btn debit-card-btn"
              >
                <i className="bx bx-credit-card payment-card-icon"></i>Debit
                {t("Card")}
              </button>
              {/* <button
                onClick={() => {
                  paymentTypeHandler("upi");
                }}
                className="btn modal-no-btn upi-card-btn"
                style={{ marginLeft: "15px" }}
              >
                <i className="bx bx-qr-scan payment-card-icon"></i>UPI
              </button> */}
            </div>
          </div>
          <div className="payment-cancel-btn">
            <button
              onClick={() => {
                setPaymentToggle(false);
              }}
              className="btn modal-no-btn credit-card-btn"
            >
              {t("Cancel")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
