/* eslint-disable jsx-a11y/alt-text */

import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { HOME } from "../../../routes/Paths";
import { getSelectedLanguage } from "../../../store/Checkin/languageSlice";
import {
  homePageHandler,
} from "../../../helpers/commonHelper";
import i18n from 'i18next';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

const HomePageCard = ({ showModal, closeModal, resetTimerAndClosePopUp }) => {
  
  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
  }, []);

  const timeLimit = 10;
  const navigate = useNavigate();
  const [currentCount, setCurrentCount] = useState(timeLimit);

  const closeModalPopUp = () => {
    resetTimerAndClosePopUp();
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (showModal === false) handlebackPage();
  }, [showModal]);

  useEffect(() => {
    let interval = null;
    if (showModal) {
      interval = setInterval(() => {
        setCurrentCount(currentCount - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [showModal, currentCount]);

  const progressOffset = Math.round(100 - (100 * currentCount) / timeLimit);

  useEffect(() => {
    if (currentCount === 0) {
      handlebackPage();
    }
  }, [currentCount, navigate]);
  const handlebackPage = () => {
    try {
      homePageHandler(dispatch, navigate);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };
  return (
    <div className="modal" style={{ zIndex: "2" }}>
      <div className="modal-dialog modal-lg modal-top">
        <div className="modal-content modal-bg">
          <div className="modal-body">
            <div className="text-center">
              {/* <div>
                <img src={personImage} />
              </div> */}
              <div className="mt-30 modal-info">
                <h6>{t("Are you sure you want to return to the home page?")}</h6>
                <h6>{t("Any unsaved progress will be lost")}</h6>
              </div>
            </div>
            <div className="text-center mt-30">
              <button
                type="button"
                onClick={closeModalPopUp}
                className="btn modal-no-btn"
              >
                {t("No")}
              </button>
              <Link
                onClick={handlebackPage}
                to={HOME}
                className="btn modal-yes-btn"
              >
                {t("Yes")}
              </Link>
            </div>
            <div className="completed-status  modal-timer">
              <span style={{ color: "black" }}>{currentCount}</span>
              <div className="base-timer">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
                  <circle
                    cx="16"
                    cy="16"
                    r="15.9155"
                    className="progress-bar__background"
                  />
                  <circle
                    cx="16"
                    cy="16"
                    r="15.9155"
                    className="progress-bar__progress js-progress-bar"
                    style={{ strokeDashoffset: progressOffset }}
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageCard;
