import { CHECKOUT_PAYMENT, HOME, SEND_FOLIO } from "../../routes/Paths";
import {
  CONFIG_MASTER_ENUM,
  STEP_MASTER_ENUM,
  timerConstantValue,
} from "../../constants/constant";
import { checkOut, fetchFolio, saveFolio } from "../../network/apiService";
import {
  fetchModulesConfiguration,
  getModuleConfiguration,
} from "../../store/Checkin/moduleConfigurationSlice";
import {
  getConfigurationCode,
  getConfigurationCodeMandatory,
  getModuleStatus,
  getParametersFromLocalStorage,
  getStepMaster,
} from "../../helpers/commonHelper";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import AgreedForm from "../../components/TermsAndConditions/AgreedForm";
import CheckoutFooter from "../../components/Layout/CheckoutFooter";
import ErrorPopup from "../../components/LoaderAndNotification/ErrorPopup";
import HomePageCard from "../../components/Layout/Card/HomePageCard";
import LoaderPopup from "../../components/LoaderAndNotification/LoaderPopup";
import SuccessPopup from "../../components/LoaderAndNotification/SuccessPopup";
import { getCheckoutData } from "../../store/Checkout/checkoutSlice";
import { getPropertyInfo } from "../../store/Checkin/Scan/propertySlice";
import { homePageHandler } from "../../helpers/commonHelper";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

// import { timerConstantValue } from "../../constants/constant";

// import { timerConstantValue } from "../../constants/constant";

const InvoiceInformation = () => {
  const timeLimit = timerConstantValue;
  const [showModal, setShowModal] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const navigate = useNavigate();
  const data = useSelector(getCheckoutData);
  const [loading, setLoading] = useState(false);
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [errorModal, setErrorModal] = useState(null);
  // const stepMaster = JSON.parse(localStorage.getItem("setStepMasterInfo"));//useSelector(getStepMasterInfo);
  // const showFoliPage = (stepMaster && stepMaster.length
  //   ? stepMaster.find((ele) => ele.stepTitle === "Folio Page")
  //   : null) ?? null;

  // const modules = showFoliPage?.modules;
  // const showFolioPage = (modules && modules.length
  //   ? modules.find((ele) => ele.moduleName === "Show Folio").isEnabled ? true : false
  //   : false) ?? false;

  // const signatureFolioRequired = (modules && modules.length
  //   ? modules.find((ele) => ele.moduleName === "SignatureFolio Required").isEnabled ? true : false
  //   : false) ?? false;

  // const [showFolio, setshowFolio] = useState(showFolioPage != null);
  // const [signatureRequired, showSignatureRequire] = useState(signatureFolioRequired);
  const [folio, setFolio] = useState();
  const [folioReceived, setfolioReceived] = useState(false);
  const propertyData = useSelector(getPropertyInfo);
  const [signature, setSignature] = useState(null);
  const [url, setUrl] = useState(null);
  const token = getParametersFromLocalStorage("token");
  const confirmationNo = localStorage.getItem("confirmationNo");
  const reservationNameID = localStorage.getItem("reservationNameID");
  const [totalAmount, setTotalAmount] = useState(0);

  const [totalDue, setTotalDue] = useState(null);
  const userData = useSelector(getCheckoutData);
  const [successLoader, setSuccessLoader] = useState(false);

  const stepMaster = useSelector((state) => getModuleConfiguration(state));

  const stepFolioPage = getStepMaster(stepMaster, STEP_MASTER_ENUM.FOLIO_PAGE);
  const configs = stepFolioPage?.configs;
  const paymentPage = getStepMaster(stepMaster,STEP_MASTER_ENUM.CHECKOUT_PAYMENT_PAGE);

  
  const showFolioPage = getConfigurationCode(
    configs,
    CONFIG_MASTER_ENUM.FOLIO_VIEW
  );
  const signatureFolioRequired = getConfigurationCode(
    configs,
    CONFIG_MASTER_ENUM.SIGNATURE_FOLIO_REQUIRED
  );
  const signatureFolioManadatory = getConfigurationCodeMandatory(
    configs,
    CONFIG_MASTER_ENUM.SIGNATURE_FOLIO_REQUIRED
  );

  const showReservationPage = getConfigurationCode(
    configs,
    CONFIG_MASTER_ENUM.RESERVATION_INFO
  );

  const dispatch = useDispatch();
  const [showFolio, setshowFolio] = useState(showFolioPage != null);
  const [signatureRequired, showSignatureRequire] = useState(
    signatureFolioRequired
  );

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const navigateToPayment = () => {
    setTotalAmount(localStorage.getItem("balanceAmount"));
    if (totalAmount <= 0 || (paymentPage === undefined || paymentPage === null)) {
      navigate(SEND_FOLIO, { replace: true });
    } else {
      navigate(CHECKOUT_PAYMENT, { replace: true });
    }
  };
  const saveFolioCard = async () => {
    setLoading(true);
    try {
      const RegCard = {
        reservationID: 0,
        confirmationNo: confirmationNo,
        reservationNameID: reservationNameID,
        signatureBase64: url ?? "-",
        propertyCode: propertyData.propertyCode,
        documentType: "FOLIO",
      };
      const response = await saveFolio(RegCard, token);
      if (response.data.result && response.status === 200) {
        setLoading(false);
        if (totalDue <= 0 || (paymentPage === undefined || paymentPage === null)) {
          doCheckout(confirmationNo, reservationNameID);
        } else {
          navigate(CHECKOUT_PAYMENT, { replace: true });
        }
        return;
      } else {
        setLoading(false);
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t(
            "Unable to save folio at the moment.Please contact front desk"
          ),
        }));
        setErrorModal(true);

        return;
      }
    } catch (Exception) {
      setLoading(false);
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: t(
          "Unable to save folio at the moment.Please contact front desk"
        ),
      }));
    }
  };
  const doCheckout = async (confirmationNo, reservationNameID) => {
    setLoading(true);
    const checkinData = {
      confirmationNo: confirmationNo,
      reservationNameID: reservationNameID,
    };
    const response2 = await checkOut(checkinData, token);
    if (response2?.data?.result === true) {
      setLoading(false);
      setSuccessLoader(true);
      setTimeout(() => {
        navigate(SEND_FOLIO, { replace: true });
        return;
      }, 2000);
      return;
    } else {
      setLoading(false);

      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: t(
          "Check-out Not Allowed. Please visit front desk for assistance"
        ),
      }));

      setTimeout(() => {
        handlebackPage();
      }, 6000);

      return;
    }
  };
  useEffect(() => {
    if (!folioReceived) {
      fetchFolioData();
    }
  }, [folioReceived]);

  const gotSignature = () => {
    setUrl(signature.getTrimmedCanvas().toDataURL("image/png"));
  };

  const clearSign = () => {
    signature.clear();
    setUrl(null);
  };

  const fetchFolioData = async () => {
    setLoading(true);
    try {
      let totalAmount = 0;
      const folioData = await fetchFolio(
        {
          confirmationNo: confirmationNo,
          reservationNameID: reservationNameID,
        },
        token
      );
      if (folioData.data.data != null && folioData.data.result) {
        setFolio(folioData.data.data.base64File);
        setfolioReceived(true);

        totalAmount = parseFloat(folioData.data?.data?.balanceAmount ?? 0);
        //totalAmount = amount?.toFixed(2) > 0 ? amount?.toFixed(2) : 0;
        localStorage.setItem("DueAmount", totalAmount);
  

        if (totalAmount > 0) {
          setTotalDue(totalAmount);
        } else {
          setTotalDue(0);
        }
        setLoading(false);
        // if (folioData.data.data.balanceAmount < 0) {
        //   setfolioReceived(true);
        //   setLoading(false);
        //   setErrorModalData((state) => ({
        //     ...state,
        //     toggleError: true,
        //     errorMessage:
        //       "Unable to procced at this momemt, Please visit front desk for assistance.",
        //   }));

        //   setTimeout(() => {
        //     navigate(HOME, { replace: true });
        //   }, 3000);
        // } else {

        // }
      } else {
        setLoading(false);
        setfolioReceived(true);
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t(
            "Unable to generate folio at the moment.Please contact front desk"
          ),
        }));
        setTimeout(() => {
          handlebackPage();
        }, 6000);
      }
    } catch (error) {
      setLoading(false);
      setfolioReceived(true);
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: t(
          "Unable to generate folio at the moment.Please contact front desk"
        ),
      }));

      setTimeout(() => {
        handlebackPage();
      }, 6000);
      // navigate("/home-page");
    }
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const handlebackPage = () => {
    try {
      homePageHandler(dispatch, navigate);
    } catch (error) {
      console.error("Error when redirecting to home page.", error);
    }
  };

  return (
    <div>
      <SuccessPopup
        loading={successLoader}
        successMessage={t("You have been successfully Checked-out")}
      />
      <LoaderPopup loading={loading} />
      <AgreedForm
        // loading={loading}
        openModal={openModal}
        // closeErrorModal={closeErrorModal}
        // erroModalData={erroModalData}
        navigateToPayment={navigateToPayment}
        savefolioCard={saveFolioCard}
        folio={folio}
        clearSign={clearSign}
        data={data[0]}
        setSignature={setSignature}
        setUrl={setUrl}
        signature={signature}
        gotSignature={gotSignature}
        showFolio={showFolio}
        url={url}
        signatureRequired={signatureRequired}
        onBackPress={handlebackPage}
        showReservationPage={showReservationPage}
        signatureFolioManadatory={signatureFolioManadatory}
      />
      <CheckoutFooter
        page={"agreedform"}
        showModal={showModal}
        openModal={openModal}
        timerCounter={timerCounter}
        timerValue={timerValue}
        restartTimer={restartTimer}
        stopTimer={stopTimer}
        timeLimit={timeLimit}
      />
      <ErrorPopup
        closeErrorModal={closeErrorModal}
        erroModalData={erroModalData}
      />

      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
    </div>
  );
};

export default InvoiceInformation;
