/* eslint-disable jsx-a11y/alt-text */

import { Link, useNavigate } from "react-router-dom";
import { initReactI18next, useTranslation } from 'react-i18next';

import { getSelectedLanguage } from "../../../store/Checkin/languageSlice";
import i18n from 'i18next';
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";

const QueueRoomConfirmationCard = ({ onConfirm, resetTimerAndClosePopUp }) => {
  const timeLimit = 10;
  const navigate = useNavigate();
  const [currentCount, setCurrentCount] = useState(timeLimit);

  const closeModalPopUp = () => {
    resetTimerAndClosePopUp();
  };

  const progressOffset = Math.round(100 - (100 * currentCount) / timeLimit);
  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
     }, []);


  return (
    <div className="modal" style={{ zIndex: "2" }}>
      <div className="modal-dialog modal-lg modal-top">
        <div className="modal-content modal-bg">
          <div className="modal-body">
            <div className="text-center">
              <div className="mt-30 modal-info">
                <h3>{t("Rooms are not ready")}</h3>     
                <h4>{t("Do you want to proceed?")}</h4>           
              </div>
            </div>
            <div className="text-center mt-30">
              <button
                type="button"
                onClick={closeModalPopUp}
                className="btn modal-no-btn"
              >
                {t("No")}
              </button>
              <Link
                onClick={onConfirm}                
                className="btn modal-yes-btn"
              >
                {t("Yes")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QueueRoomConfirmationCard;
