import {
  fetchModulesConfiguration,
  getModuleConfiguration,
} from "../../store/Checkin/moduleConfigurationSlice";
import {
  fetchPropertyInfo,
  getIsExpired,
  getPropertyInfo,
  getTokenExpiry,
} from "../../store/Checkin/Scan/propertySlice";
import {
  generateNewSessionToken,
  getParametersFromLocalStorage,
  isSessionTokenExpired,
  saveParametersIntoLocalStorage,
  saveTokenInSessionStorage,
} from "../../helpers/commonHelper";
import {
  generateTokenApi,
  getAllowedDocuments,
} from "../../network/apiService";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { HOME } from "../../routes/Paths";
import ThemeComponent from "../Theme/ThemeComponent";
import { homePageHandler } from "../../helpers/commonHelper";
import { setDocumentInfo } from "../../store/Checkin/Scan/documentScanSlice";
import { useEffect } from "react";

const BaseLayout = (type) => {
  const propertyData = useSelector(getPropertyInfo);
  const token = getParametersFromLocalStorage("token");
  const stepMaster = useSelector(getModuleConfiguration);
  const isExpired = useSelector(getIsExpired);
  //const isSessionTokenExpired = useSelector(state => state.session.isExpired);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handlePopstate = () => {
      navigate(HOME);
    };
    window.addEventListener('popstate', handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [navigate]);

  useEffect(() => {
    dispatch(getTokenExpiry());
  }, [dispatch]);

  useEffect(() => {
    if (isExpired === true) {
      sessionStorage.removeItem("token");
      generateTokenCall();
    }
  }, [dispatch, isExpired]);


  useEffect(async () => {
    generateTokenCall();
  }, [token]);

  const generateTokenCall = async()=>{
    var param = {
      userName: JSON.parse(localStorage.getItem("username")),
      password: JSON.parse(localStorage.getItem("password")),
    };
    if (
      (token === null ||
        token === undefined ||
        Object.keys(token).length === 0) &&
      param?.userName !== null &&
      param?.userName !== undefined &&
      param?.password !== null &&
      param?.password !== undefined
    ) {
      const response = await generateTokenApi(param);
      if (response.status === 200) {
        saveTokenInSessionStorage(response.data.token);
        const docTypes = await getAllowedDocuments(
          {
            appType: "kiosk",
          },
          response.data.token
        );

        if (docTypes?.data?.result) {
          dispatch(setDocumentInfo(docTypes?.data?.data));
        }
      }
    }
    if (
      param?.userName === null ||
      param?.userName === undefined ||
      param?.password === null ||
      param?.password === undefined
    ) {
      navigate(`/`, { replace: true });
    }
  }
  useEffect(async () => {
    if (
      (propertyData === null ||
        propertyData === undefined ||
        propertyData?.data === null ||
        propertyData?.data === undefined) &&
      token !== null &&
      token !== undefined
    ) {
      dispatch(fetchPropertyInfo(token));
    } else {
      if (
        performance.getEntriesByType("navigation")[0].type === "reload" &&
        !performance
          .getEntriesByType("navigation")[0]
          .name.includes("home-page")
      ) {
        homePageHandler(dispatch, navigate);
        window.location.reload();
      } else {
      }
    }
  }, [propertyData, token]);

  useEffect(async () => {
    if (
      (stepMaster === null ||
        stepMaster === undefined ||
        stepMaster.length === undefined ||
        stepMaster.length <= 0) &&
      token !== null &&
      token !== undefined
    ) {
      // if (

      //   token !== null &&
      //   token !== undefined
      // ) {

      dispatch(
        fetchModulesConfiguration({
          IsStepEnabled: true,
          IsModuleEnabled: true,
          imageSpecified: true,
          Apptype: "kiosk",
          StepType: "checkin",
          token: token,
        })
      );
    }
  }, [stepMaster, token]);

  return (
    <>
      {token && type && (
        <>
          <ThemeComponent></ThemeComponent>
        </>
      )}
    </>
  );
};

export default BaseLayout;
