import { Link, useNavigate } from "react-router-dom";
import {
  MODULE_MASTER_ENUM,
  STEP_MASTER_ENUM,
  timerConstantValue,
} from "../../constants/constant";
import { getModuleStatus, getStepMaster } from "../../helpers/commonHelper";
import { initReactI18next, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import HomePageCard from "../../components/Layout/Card/HomePageCard";
import { SEARCH_RESERVATION } from "../../routes/Paths";
import confirmNumber from "../../assets/images/confirmation-number.png";
import { getModuleConfiguration } from "../../store/Checkin/moduleConfigurationSlice";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import { homePageHandler } from "../../helpers/commonHelper";
import i18n from 'i18next';
import passportScan from "../../assets/images/passport-scanner.png";
import qrScanImage from "../../assets/images/qr-scan.png";
import { replace } from "lodash";
import { userReservationDetailsCheckoutPage } from "../../store/Checkout/checkoutSlice";

const Confirmation = () => {

  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);
 

  const timeLimit = timerConstantValue;
  const [showModal, setShowModal] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stepMaster = useSelector((state) => getModuleConfiguration(state));

  const stepMasterHome = getStepMaster(
    stepMaster,
    STEP_MASTER_ENUM.RESERVATION_SEARCH_PAGE
  );
  const modules = stepMasterHome?.modules;

  const documentScan = getModuleStatus(
    modules,
    MODULE_MASTER_ENUM.DOCUMENT_SCAN
  );
  const ConfirmationNoSearch = getModuleStatus(
    modules,
    MODULE_MASTER_ENUM.CONFIRMATION_NUMBER_SEARCH
  );
  const QRCode = getModuleStatus(modules, MODULE_MASTER_ENUM.QR_CODE);

  const [documentScanOption, setdocumentScanOption] = useState(documentScan);
  const [confirmationNoSearchOption, setconfirmationNoSearchOption] =
    useState(ConfirmationNoSearch);
  const [QRCodeOption, setQRCodeOption] = useState(QRCode);

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (
      documentScan === false &&
      ConfirmationNoSearch === false &&
      QRCode === false
    ) {
      navigate(SEARCH_RESERVATION, { replace: true });
    } else {
      setdocumentScanOption(documentScan);
      setconfirmationNoSearchOption(ConfirmationNoSearch);
      setQRCodeOption(QRCode);
    }
  }, [documentScan, ConfirmationNoSearch, QRCode]);

  useEffect(() => {
    homePageHandler(dispatch);
    i18n.changeLanguage(selectedLanguage);
  }, []);

  const stopTimer = () => {
    setRestartTimer(false);
  };
  const handlebackPage = () => {
    try {
      homePageHandler(dispatch, navigate);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };

  return (
    <div>
      <main>
        <div className="main-content">
          <Header
            onClick={handlebackPage}
            setShowModal={openModal}
            isHome={true}
            title={""}
            subTitle={""}
          />
          <div className="checkin-section content-center">
            {QRCodeOption ? (
              <>
                <div className="checkIn-white-box">
                  <div className="qr-scan-img-flex">
                    <div>
                      <div>
                        <h5 className="mb-20 text-center font-30">
                        {t("QR Code Check-In")}
                        </h5>
                        <div>
                          <Link to="/qr-code-scanning">
                            <button
                              type="button"
                              className="btn button-blue qr-checkin-btn"
                            >
                               {t("Scan QR Code")}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="qr-scan-img">
                        <img src={qrScanImage} />
                      </div>
                    </div>
                  </div>
                </div>

                {confirmationNoSearchOption || documentScanOption ? (
                  <>
                    <div className="text-center mt-20">
                      <div className="line-center">
                        <span>{t("Don't have QR code?")}</span>
                      </div>
                    </div>
                  </>
                ) : null}
              </>
            ) : null}

            {confirmationNoSearchOption || documentScanOption ? (
              <>
                <div className="scan-confirm-flex mt-20">
                  {documentScanOption ? (
                    <>
                      <div>
                        <div className="confirm-number-box">
                          <div className="qr-scan-img-flex">
                            <div>
                              <div>
                                <p>{t("Passport/ID Document Check-In")}</p>
                                <div>
                                  <Link
                                    to={`/passport-scanning/${"scan"}`}
                                    replace
                                  >
                                    {/* to={{ pathname: '/passport-scanning', state:  'scan'  }}> */}
                                    <button
                                      type="button"
                                      className="btn button-blue  scan-passport-btn"
                                    >
                                      {t("Scan Passport")}
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="passport-scan-img">
                                <img src={passportScan} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {confirmationNoSearchOption ? (
                    <div>
                      <div className="confirm-number-box">
                        <div className="qr-scan-img-flex">
                          <div>
                            <div>
                              <p>{t("Confirmation Number Check-In")}</p>
                              <div>
                                <Link to="/confirmation-number" replace>
                                  <button
                                    type="button"
                                    className="btn button-blue confirm-number-btn"
                                  >
                                    {t("Enter Confirmation Number")}
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="confirm-num-img">
                              <img src={confirmNumber} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
        </div>
        <Footer
          page={"confirmation"}
          showModal={showModal}
          openModal={openModal}
          timerCounter={timerCounter}
          timerValue={timerValue}
          restartTimer={restartTimer}
          stopTimer={stopTimer}
          timeLimit={timeLimit}
        />
      </main>
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
    </div>
  );
};

export default Confirmation;
