import React from "react";

export const TitleCaseConverter = ({ text }) => {
  const convertToTitleCase = (fullName) => {
    // Split the fullName into words using spaces as delimiters
    let words = fullName.split(" ");

    // Capitalize each word and handle parts with periods
    const capitalizedWords = words.map((word) => {
      // Split the word by periods if they exist, then capitalize each part
      let subWords = word.split(".").map((subWord) => {
        return subWord.length > 0
          ? subWord[0].toUpperCase() + subWord.slice(1).toLowerCase()
          : subWord;
      });

      // Join the parts back with periods
      const str = subWords.join("");
      if(str.includes(".")){
        return subWords.join(" ");
      }
      else{
        return subWords.join(".");
      }
    });

    // Join the capitalized words back into a single string
    const capitalizedFullName = capitalizedWords.join(" ");

    return capitalizedFullName;
  };

  const titleCaseText = convertToTitleCase(text);

  return titleCaseText;
};
