import { Box, IconButton, Modal, Typography } from "@mui/material";
import { initReactI18next, useTranslation } from 'react-i18next';

import CloseIcon from "@mui/icons-material/Close";
import { getSelectedLanguage } from "../../../store/Checkin/languageSlice";
import i18n from 'i18next';
import { useEffect } from "react";
import { useSelector } from "react-redux";

const FlipDocumentModal = ({
  flipModalToggle,
  completedScanDone,
  closeModel,
}) => {

  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  
  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
     }, []);


  return (
    <div>
      <Modal open={flipModalToggle} style={{ zIndex: "1" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "600px",
            backgroundColor: "#f5f5f5",
            border: "2px #000",
            boxShadow: "0 0 24px rgba(0, 0, 0, 0.5)",
            borderRadius: "20px",
          }}
        >
          <button className="close-icon" onClick={closeModel}>
            <CloseIcon
              style={{ float: "right", margin: "5px", color: "white" }}
              onClick={closeModel}
            />
          </button>

          <div className="flex" style={{ padding: "70px" }}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, textAlign: "center", fontSize: "1.8rem"}}
            >
              {t("Please flip your document and click on continue to proceed.")}
            </Typography>
            <div style={{ textAlign: "center" }}>
              {/* <div className="lds-ellipsis">
              <div></div>
              <div></div>
            </div> */}
              <button
                style={{ marginTop: "20px", padding: "5px" }}
                className="btn button-blue scan-btn"
                onClick={() => {
                  completedScanDone();
                }}
              >
                 {t("Continue")}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default FlipDocumentModal;
