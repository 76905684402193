import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

const SuccessPopup = ({ loading, successMessage }) => {
  
  return (
    <div>
      <Modal open={loading} style={{ zIndex: "9999999999" }}>
        <Box
          sx={{
            zIndex:"9999999999",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "500px",
            backgroundColor: "#f5f5f5",
            border: "2px #000",
            boxShadow: "0 0 24px rgba(0, 0, 0, 0.5)",
            padding: "16px",
            borderRadius: "20px",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <i
              className="bx bx-check-circle"
              style={{ fontSize: "55px", color: "#0ec80e" }}
            ></i>

            {/* <LottieHelper animationData="key_card.json"/> */}
          </div>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: "center", fontSize: "1.8rem" }}
          >
            {successMessage}
          </Typography>
          <div style={{ textAlign: "center" }}>
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default SuccessPopup;
