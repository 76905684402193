/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {
  clearScanData,
  getScanDataFromWebhost,
} from "../../network/apiService";
import {
  getParametersFromLocalStorage,
  homePageHandler,
} from "../../helpers/commonHelper";
import i18n, { t } from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import ErrorPopup from "../../components/LoaderAndNotification/ErrorPopup";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import HomePageCard from "../../components/Layout/Card/HomePageCard";
import LoaderPopup from "../../components/LoaderAndNotification/LoaderPopup";
import { RESERVATION_LIST_FORM } from "../../routes/Paths";
import { fetchReservation } from "../../store/Checkin/reservationformSlice";
import { getPropertyInfo } from "../../store/Checkin/Scan/propertySlice";
import { getReservationDetails } from "../../network/apiService";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import { timerConstantValue } from "../../constants/constant";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { userReservationDetailsPassport } from "../../store/Checkin/Scan/scanPassportSlice";
import { userReservationDetailsQr } from "../../store/Checkin/QrCode/qrCodeSlice";

const QrScanning = () => {
  const timeLimit = timerConstantValue;
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [restartTimer, setRestartTimer] = useState(false);
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const token = getParametersFromLocalStorage("token");
  const propertyData = useSelector(getPropertyInfo);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(11);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const wait = (milliseconds) =>
    new Promise((res, rej) =>
      setTimeout(
        () =>
          rej(
            new Error(`Request timed out after ${milliseconds / 1000} seconds`)
          ),
        milliseconds
      )
    );

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.target.tagName !== "INPUT") {
        const input = document.querySelector(".my-input");
        if (input) {
          input.focus();
          input.value += e.key; // Append the pressed key to the existing value
          e.preventDefault();
        }
      }
    };

    document.addEventListener("keypress", handleKeyPress);

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  const getConfirmationDetails1 = async () => {
    try {
      setLoading(true);
      // call getScanData

      const clearData = await Promise.race([clearScanData({}), wait(30000)]);

      const scanData = await Promise.race([
        getScanDataFromWebhost({}),
        wait(30000),
      ]);

      // const scanHandler = () => {
      //   setLoading(true);
      //   setTimeout(() => {
      //     setLoading(false);
      //     navigate("/passport-scanning-date-selection");
      //   }, 3000);
      // };

      if (scanData.data != null && scanData.data.BarCodeData != null) {
        const response = await getReservationDetails(
          {
            ConfirmationNo: scanData.data.BarCodeData?.replace(" ",""),

            //scanData.data.BarCodeData,//"20003993"//scanData.data.BarCodeData, //"20003993",//scanData.data.BarCodeData, //19533596 scanData.data.BarCodeData,
          },
          token
        );
        if (response.data.statusCode === 200 && response.data.data != null) {
          const confirmNo = response.data.data.pmsConfirmationNo;

          const response2 = await getReservationDetails(
            {
              ConfirmationNo: confirmNo?.replace(" ",""),

              PropertyCode: propertyData.propertyCode,
            },
            token
          );
          if (response2.data.statusCode === 200 && response.data.data != null) {
            setLoading(false);
            localStorage.setItem("confirmationNo",response?.data?.data?.pmsConfirmationNo);
            localStorage.setItem("LastName",JSON.stringify(response2.data.data.lastName));

            dispatch(userReservationDetailsQr(response2.data.data));
            dispatch(userReservationDetailsPassport(response2.data.data));
            navigate(RESERVATION_LIST_FORM, { replace: true });
            // navigate("/qr-code-scanning-reservation-form");
            return;
          } else {
            setErrorModalData((state) => ({
              ...state,
              toggleError: true,
              errorMessage: t(
                "Unable to retrieve reservation. Please check your details or proceed to the front desk for assistance."
              ),
            }));
            setLoading(false);
            return;
          }         
        } else {
          setErrorModalData((state) => ({
            ...state,
            toggleError: true,
            errorMessage: t(
              "Oops! The scanned QR code does not match our records. Please ensure you are scanning the correct QR code provided for check-in."
            ),
          }));
          setLoading(false);
          return;
        }
      } else {
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t(
            "Oops! The scanned QR code does not match our records. Please ensure you are scanning the correct QR code provided for check-in."
          ),
        }));
        setLoading(false);
        return;
      }
    } catch (error) {
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: error.message,
      }));
      setLoading(false);
      return;
    }
    return;
  };

  const getInputValue = async () => {
    return new Promise((resolve, reject) => {
      const intervalId = setInterval(() => {
        const value = document.getElementById("my-input")?.value ?? null;
        if (value) {
          clearInterval(intervalId); // Stop the interval
          // setLoading(false);
          // console.log("setLoading(false)");
          resolve(value); // Resolve the promise with the value
        }
      }, 3000); // Check for the value every second
    });
  };

  // Call getConfirmationDetails when the scan button is clicked
  const handleScanButtonClick = async () => {
    try {
      let inputValue = "";
      setLoading(true);
      inputValue = await getInputValue();
      try {
        if (
          inputValue !== null &&
          inputValue !== undefined &&
          inputValue !== ""
        ) {
          const confirmNo = inputValue;
          document.querySelector(".my-input").value = "";

          dispatch(
            fetchReservation({
              confirmationNo: confirmNo,
              propertyCode: propertyData?.data?.propertyCode,
              token: token,
            })
          )
            .unwrap()
            .then((rep) => {
              fetchReservationCompleted(rep);
            })
            .catch((error) => {
              setLoading(false);
              setErrorModalData((state) => ({
                ...state,
                toggleError: true,
                errorMessage: error?.error ?? t("Some error occured"),
              }));
              return;
            });

        } else {
          setErrorModalData((state) => ({
            ...state,
            toggleError: true,
            errorMessage: t(
              "Oops! The scanned QR code does not match our records. Please ensure you are scanning the correct QR code provided for check-in."
            ),
          }));
          setLoading(false);
          return;
        }
      } catch (error) {
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: error.message,
        }));
        setLoading(false);
        return;
      }

      // Continue with your logic using the input value
    } catch (error) {
      console.error("Error:", error);
      // Handle the error if needed
    }
  };

  const fetchReservationCompleted = (reservationList) => {
    if (reservationList?.result === true) {
      if (
        reservationList !== null &&
        reservationList !== undefined &&
        reservationList?.data !== null &&
        reservationList?.data !== undefined
      ) {
        setLoading(false);
        dispatch(userReservationDetailsPassport(reservationList?.data));
        navigate(RESERVATION_LIST_FORM + "/search", { replace: true });
        return;
      } else if (
        reservationList?.error !== undefined &&
        reservationList?.error !== null &&
        reservationList?.error === "No reservation found." &&
        !reservationList?.loading
      ) {
        setLoading(false);
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t(
            "Sorry, we couldn't find your booking. Please double-check your details or proceed to the front desk for assistance."
          ),
        }));
        return;
      } else {
        setLoading(false);
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t(
            "Unable to retrieve reservation. Please check your details or proceed to the front desk for assistance."
          ),
        }));
        return;
      }
    } else {
      setLoading(false);
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: reservationList?.message ?? t("Some error occured"),
      }));
    }
  };
  const handlebackPage = () => {
    try {
      homePageHandler(dispatch, navigate);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };
  return (
    <div>
      <main>
        <LoaderPopup loading={loading} />
        <ErrorPopup
          closeErrorModal={closeErrorModal}
          erroModalData={erroModalData}
        />
        <div className="main-content">
          <div className="h-100">
            <div className="content-bg h-100">
              <div className="row h-100">
                <div className="col-sm-4 pl-0">
                  <div className="h-100">
                    <div>
                      {/* <div>
                        <button
                          onClick={() => {
                            navigate(-1);
                          }}
                          className="btn button-blue back-btn"
                        >
                          <i className="bx bx-chevron-left mr-3"></i>BACK
                        </button>
                      </div> */}

                      <Header
                        onClick={handlebackPage}
                        setShowModal={openModal}
                        isHome={true}
                        title={""}
                        subTitle={""}
                      />
                    </div>
                    <div className="position-relative">
                      <div className="section-center">
                        <div className="scan-text">
                          <h5 className="text-center font-14 font-weight-500">
                            {t("Scan your")} <strong> {t("QR code")}</strong>.
                          </h5>
                          <br />
                          <h5 className="text-center font-14 font-weight-500">
                            {t("Tap Scan to begin.")}
                          </h5>
                        </div>
                        <div className="text-center mt-30">
                          <button
                            className="btn button-blue scan-btn"
                            onClick={handleScanButtonClick}
                          >
                            <i className="bx bx-barcode-reader scan-icon"></i>
                            {t("Scan")}
                          </button>
                          <input
                            type="text"
                            style={{ position: "absolute", left: "-10000px" }}
                            id="my-input"
                            className="my-input"
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-8 pr-0">
                  <div className="scan-img">
                    <img src="./assets/images/scan.gif" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer
          page={"documentscanning"}
          showModal={showModal}
          openModal={openModal}
          timerCounter={timerCounter}
          timerValue={timerValue}
          restartTimer={restartTimer}
          stopTimer={stopTimer}
          timeLimit={timeLimit}
        />
      </main>
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
    </div>
  );
};

export default QrScanning;
