import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getStepWithModules } from "../../network/apiService";

const initialState = {
  moduleConfigurationData: {},
  mainModuleConfigurationData: null,
  LanguageSelected : 'en',
};

export const fetchModulesConfiguration = createAsyncThunk(
      "getModuleConfiguration",
      async ( { IsStepEnabled, IsModuleEnabled, imageSpecified,Apptype,StepType, token} , _thunkAPI) => {
        let param = null;

        param = {
          IsStepEnabled: IsStepEnabled,
          IsModuleEnabled: IsModuleEnabled,
          imageSpecified: true,
          Apptype:  Apptype,
          StepType: StepType
        };
    
      const response = await getStepWithModules(param, token);

      if(response?.data?.data === null){
        return _thunkAPI.rejectWithValue({
          error: 'No reservation found.',
        });
      }
      if(response?.data?.result === false){
        return _thunkAPI.rejectWithValue({
          error: 'No reservation found.',
        });
      }
      return response?.data?.data;
    }
);

const moduleConfigurationSlice = createSlice({
  name: "moduleConfiguration",
  initialState,
  reducers: {
    moduleConfigurationInfo: (state, action) => {
      state.mainModuleConfigurationData = action.payload;
    },
    
  },
  extraReducers: (builder) => {  
    builder.addCase(fetchModulesConfiguration.fulfilled, (state, action) => {

      state.moduleConfigurationData = action.payload;

    });
    builder.addCase(fetchModulesConfiguration.rejected, (state, action) => {
      state.moduleConfigurationData = null;
    });
  },
});

export const getModuleConfiguration = (state) => state.moduleConfiguration.moduleConfigurationData;
export const getMainModuleConfiguration = (state) => state.moduleConfiguration.mainModuleConfigurationData;

export const { moduleConfigurationInfo} = moduleConfigurationSlice.actions;

export default moduleConfigurationSlice.reducer;




