import React, { useEffect, useState } from "react";
import {
  getParametersFromLocalStorage,
  saveParametersIntoLocalStorage,
} from "../../helpers/commonHelper";

import { getUIConfigurations } from "../../network/apiService";

const ThemeComponent = () => {
  const token = getParametersFromLocalStorage("token");
  const [themeColors, setThemeColors] = useState([]);

  const fetchTheme = async (token) => {
    const theme = getParametersFromLocalStorage("theme");
    if (theme !== null && theme !== undefined) {
      setThemeColors(theme);
    } else {
      const response = await getUIConfigurations(token);
      if (response.data.result === true) {
        saveParametersIntoLocalStorage("theme", response.data.data);
        setThemeColors(response.data.data);
        //updateCssVariables(response.data.data);
        //setThemeData(response.data.data || []);
        // updateCssVariables(response.data.data || []);
      }
    }
  };

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "")
      fetchTheme(token);
  }, [token]); // Dependency array ensures this effect runs when the token changes

  useEffect(() => {
    const root = document.documentElement;
    themeColors?.forEach((item) => {
      // if (item?.uiKey ?? "" === "logo") {
      //   const logoImg = document.getElementById("logoImg");
      //   logoImg.src = item?.uiValue ?? "";
      // }
      root.style.setProperty("--" + item?.uiKey, item?.uiValue);      
    });    
  }, [themeColors]);

  return <></>;
};

export default ThemeComponent;
