import {
  addPrefixIfSingleLetter,
  capitalizeFullName,
  formatDate,
  nameValidation,
} from "../../helpers/commonHelper";
import { initReactI18next, useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";

import { TitleCaseConverter } from "../Plugins/TitleCaseCoverter";
import {
  getParametersFromLocalStorage,
} from "../../helpers/commonHelper";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import i18n from "i18next";
import moment from "moment";
import { useSelector } from "react-redux";

const ReservationFormTemplate = ({
  reservationData,
  multipleUserData,
  propertyDetails
}) => {
  const { t } = useTranslation();
  const settings = getParametersFromLocalStorage("settings");
  const selectedLanguage = useSelector(getSelectedLanguage);
  const hideChildCount = settings?.find(
    (setting) =>
      setting.parameterName === "HideChildCount" &&
      setting.parameterValue?.toLowerCase() === "true"
     
  );

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage); 
  }, []);

  const findNumberOfNights = (arrivalDate, departureDate) => {
    let noOfNights = null;
    if (arrivalDate && departureDate) {
      const date1 = new Date(moment(arrivalDate).format("YYYY-MM-DD"));
      const date2 = new Date(moment(departureDate).format("YYYY-MM-DD"));
      const timeDiff = date2.getTime() - date1.getTime();
      const nights = Math.ceil(timeDiff / (1000 * 3600 * 24));
      noOfNights = nights;
    }
    return noOfNights;
  };

  return (
    <div>
      {/* <div className="reservation-card-width"> */}
      {/* <div className="card"> */}
      <div className="card-header ">
        <div>
          <h6>
            {t("Confirmation Number")} :{" "}
            <strong>{reservationData?.pmsConfirmationNo}</strong>
          </h6>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          {Array.isArray(multipleUserData) && multipleUserData.length > 1 && (
            <div className="col-6">
              <label className="reservation-card-label">{t("Name")}:</label>
              <div>
                <p>
                  {TitleCaseConverter({
                    text:
                      (reservationData?.title !== "" &&
                      reservationData?.title !== null
                        ? reservationData?.title?.includes(".")
                          ? reservationData?.title + " "
                          : reservationData?.title + "." + " "
                        : "") +
                      reservationData?.firstName +
                      " " +
                      reservationData?.lastName,
                  })}
                </p>
              </div>
            </div>
          )}
          <div className="col-6">
            <label className="reservation-card-label">{t("Room Type")}:</label>
            <div>
              <p>{reservationData?.roomTypeDescription}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <label className="reservation-card-label">
              {t("Arrival Date")}:
            </label>
            <div>
              <p>{formatDate(reservationData?.arrivalDate)}</p>
            </div>
          </div>
          <div className="col-4">
            <label className="reservation-card-label">
              {t("Departure Date")}
            </label>
            <div>
              <p>{formatDate(reservationData?.departureDate) || ""}</p>
            </div>
          </div>
          <div className="col-4">
            <label className="reservation-card-label">
              {" "}
              {t("Number of Nights")}:
            </label>
            <div>
              <p>
                {addPrefixIfSingleLetter(
                  findNumberOfNights(
                    reservationData?.arrivalDate,
                    reservationData?.departureDate
                  ) + ""
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <label className="reservation-card-label"> {t("Adult(s)")}:</label>
            <div>
              <p>{reservationData?.adultCount}</p>
            </div>
          </div>          
          <div className="col-8" hidden={hideChildCount}>
            <label className="reservation-card-label">
              {t("Children (0-12years old)")}:
            </label>
            <div>
              <p>{reservationData?.childCount || 0}</p>
            </div>
          </div>
        </div>
        {/* <div className="row">
        <div className="col-12">
        <label className="reservation-card-label">{t("Room Type")}:</label>
              <div>
                <p>{reservationData?.roomTypeDescription}</p>
              </div>
        </div>
      </div> */}
      </div>
    </div>
  );
};

export default ReservationFormTemplate;
