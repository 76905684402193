import AuthReducer from "./AuthSlice";
import checkoutReducer from "./Checkout/checkoutSlice"
import { configureStore } from "@reduxjs/toolkit";
import confirmationNumberReducer from "./Checkin/ConfirmationNumber/confirmationNumberSlice";
import documentTypeSlice from "./Checkin/Scan/documentTypeSlice";
import faceVerificationReducer from "./Checkin/faceVerificationSlice";
import moduleConfigurationSlice from "./Checkin/moduleConfigurationSlice";
import propertySlice from "./Checkin/Scan/propertySlice";
import qrcodeReducer from "./Checkin/QrCode/qrCodeSlice";
import reservationDetailsReducer from "./Checkin/reservationDetailsSlice";
import reservationFormReducer from "./Checkin/reservationformSlice";
import scandocumentReducer from  "./Checkin/Scan/documentScanSlice";
import scanpassportReducer from "./Checkin/Scan/scanPassportSlice";
import stageSlice from "./stageSlice";
import upgradeRoomReducer from "./Checkin/upgradeRoomsSlice";
import languageSlice from "./Checkin/languageSlice";

const store = configureStore({
  reducer: {
    AuthReducer,
    scanpassport: scanpassportReducer,
    scandocument: scandocumentReducer,
    confirmation: confirmationNumberReducer,
    qrscanning: qrcodeReducer,
    checkout: checkoutReducer,
    reservationform: reservationFormReducer,
    userFaceVerification: faceVerificationReducer,
    upgradeUserRoom: upgradeRoomReducer,
    reservationDetails: reservationDetailsReducer,
    property:propertySlice,
    documentType:documentTypeSlice,
    moduleConfiguration: moduleConfigurationSlice,
    stage:stageSlice,
    language: languageSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
