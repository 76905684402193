import React, { useEffect, useState } from 'react';

import Lottie from 'react-lottie';

const LottieHelper = (props) => {


    // const defaultOptions = {
    //     loop: true,
    //     autoplay: true,
    //     animationData: srcImg,
    //     rendererSettings: {
    //     preserveAspectRatio: 'xMidYMid slice',
    //     },
    // };
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: require('../../assets/lottie/'+props.animationData),
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

    useEffect(() => {
        // You can use setIsPlaying to control the animation playback dynamically
        // For example, setIsPlaying(true) to play the animation or setIsPlaying(false) to pause it
    }, []);

    return (
       <div style={{marginTop:"40px"}}>
            <Lottie
                options={defaultOptions}
            />
        </div>
    )
}

export default LottieHelper;