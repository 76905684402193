import { STEP_MASTER_ENUM, timerConstantValue } from "../../constants/constant";
import {
  checkReservationDB,
  getReservationDetails,
} from "../../network/apiService";
import {
  getParametersFromLocalStorage,
  getStepMaster,
  homePageHandler,
} from "../../helpers/commonHelper";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

import CheckInCheckOutToggleForm from "../../components/Forms/CheckInCheckOutToggleForm";
import CheckoutFooter from "../../components/Layout/CheckoutFooter";
import ErrorPopup from "../../components/LoaderAndNotification/ErrorPopup";
import { FOLIO } from "../../routes/Paths";
import Header from "../../components/Layout/Header";
import HomePageCard from "../../components/Layout/Card/HomePageCard";
import LoaderPopup from "../../components/LoaderAndNotification/LoaderPopup";
import { getModuleConfiguration } from "../../store/Checkin/moduleConfigurationSlice";
import { getPropertyInfo } from "../../store/Checkin/Scan/propertySlice";
import { setEmailDetails } from "../../store/Checkin/Scan/scanPassportSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { userReservationDetailsCheckoutPage } from "../../store/Checkout/checkoutSlice";

const CheckOutPage = () => {
  const { t } = useTranslation();
  const [activeCase, setActiveCase] = useState("left");
  const timeLimit = timerConstantValue;
  const [checkinDate, setCheckinDate] = useState();
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [lastName, setLastNameChange] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [confirmationNumberData, setConfirmationNumberData] = useState({
    lastName: "",
    confirmationNumber: "",
    checkinDate: "",
    roomNumber: "",
  });
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = getParametersFromLocalStorage("token");
  const propertyData = useSelector(getPropertyInfo);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const calendarRef = useRef(null);

  const stepMaster = useSelector((state) => getModuleConfiguration(state));
  const stepConfigs = getStepMaster(
    stepMaster,
    STEP_MASTER_ENUM.CHECKOUT_RESERVATION_SEARCH_PAGE
  )?.configs;

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setShowCalendar(false);
    // const month = pad(date.getMonth() + 1);
    // const day = pad(date.getDate());
    // const year = date.getFullYear();
    // const fullDate = day + "/" + month + "/" + year;
    // document.getElementsByName("selectedDate")[0].value = fullDate;
    const fullDate = formatDate(date)
    document.getElementsByName("selectedDate")[0].value = fullDate;
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarRef]);

  const isOutsideSelectedMonth = ({ date }) => {
    return date.getMonth() !== selectedDate.getMonth();
  };

  const tileDisabled = ({ activeStartDate, date, view }) => {
    if (view === "month") {
      return date.getMonth() !== activeStartDate.getMonth();
    }
  };

  const handleLastNameChange = (e) => {
    setLastNameChange(e.target.value);
    updateFormFilledState(e.target.value, checkinDate);
  };

  const handleCheckoutDateChange = (e) => {
    setCheckinDate(e.target.value);
    updateFormFilledState(lastName, e.target.value);
  };

  const updateFormFilledState = (confirmation, checkout) => {
    setIsFormFilled(confirmation !== "" && checkout !== "");
  };

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const confirmationDataChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setConfirmationNumberData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleKeyDown = (event) => {
    // Prevent entering 'e', '+', '-', and '.'
    if (['e', 'E', '+', '-', '.'].includes(event.key)) {
      event.preventDefault();
    }
  };
  const pad = (number) => {
    return number < 10 ? "0" + number : number;
  };

  const formatDate = (date) => {
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const year = date.getFullYear();
    return year + "-" + month + "-" + day + "T00:00:00";
  };

  const getCheckoutDetails = async () => {
    try {
      setLoading(true);
      let form = formatDate(selectedDate);
      confirmationNumberData.checkinDate = form;
      // if (activeCase === 'left') {
      //   confirmationNumberData.lastName = null;
      // } else {
      //   confirmationNumberData.confirmationNumber = '';
      // }

      const newObj = {
        LastName: confirmationNumberData.lastName?.trimEnd()?.trimStart(),
        // CheckInDate: confirmationNumberData.checkinDate,
        // ConfirmationNo: confirmationNumberData.confirmationNumber,
        PropertyCode: propertyData.data.propertyCode,
        RoomNumber: confirmationNumberData.roomNumber?.replace(" ", ""),
        ReservationStatus: "dueout",
      };
      const response = await getReservationDetails(newObj, token);
      if (response.data.statusCode === 200 && response.data.data != null) {
        if (!response.data.data?.isCheckoutAllowed) {
          setLoading(false);
          setErrorModalData((state) => ({
            ...state,
            toggleError: true,
            errorMessage: t(
              "Sorry, unable to check out. Reservation is already checked out."
            ),
          }));
          return;
        }
        await checkReservationDB(
          {
            confirmationNo: response.data.data.pmsConfirmationNo,
            reservationNameID: response.data.data.reservationNameID,
          },
          token
        );
        if (activeCase === "left") {
          setLoading(false);

          dispatch(
            setEmailDetails({
              mail: response?.data?.data?.email ?? "",
              phone: response?.data?.data?.phoneNo_UAE ?? "",
            })
          );

          dispatch(userReservationDetailsCheckoutPage(response.data.data));
          setConfirmationNumberData({
            lastName: "",
            checkinDate: "",
            confirmationNumber: "",
          });
          localStorage.setItem(
            "confirmationNo",
            response.data.data.pmsConfirmationNo
          );
          localStorage.setItem(
            "reservationNameID",
            response.data.data.reservationNameID
          );

          navigate(FOLIO, { replace: true });
          return;
        } else if (
          activeCase === "right" &&
          response?.data?.data?.lastName &&
          response?.data?.data?.lastName.toUpperCase() ===
            confirmationNumberData.lastName.toUpperCase()
        ) {
          setLoading(false);
          dispatch(userReservationDetailsCheckoutPage(response.data.data));
          setConfirmationNumberData({
            lastName: "",
            checkinDate: "",
            confirmationNumber: "",
            roomNumber: "",
          });
          localStorage.setItem(
            "confirmationNo",
            response.data.data.pmsConfirmationNo
          );
          localStorage.setItem(
            "reservationNameID",
            response.data.data.reservationNameID
          );
          navigate(FOLIO, { replace: true });
          return;
        } else if (
          activeCase === "right" &&
          response?.data?.data[0] &&
          response?.data?.data[0]?.lastName.toUpperCase() ===
            confirmationNumberData.lastName.toUpperCase()
        ) {
          setLoading(false);
          dispatch(userReservationDetailsCheckoutPage(response.data.data[0]));
          setConfirmationNumberData({
            lastName: "",
            checkinDate: "",
            confirmationNumber: "",
          });
          localStorage.setItem(
            "confirmationNo",
            response.data.data[0].pmsConfirmationNo
          );
          localStorage.setItem(
            "reservationNameID",
            response.data.data[0].reservationNameID
          );
          navigate(FOLIO, { replace: true });
          return;
        } else if (
          activeCase === "right" &&
          response?.data?.data?.lastName.toUpperCase() !==
            confirmationNumberData.lastName.toUpperCase()
        ) {
          setLoading(false);
          setErrorModalData((state) => ({
            ...state,
            toggleError: true,
            errorMessage: t(
              "Last name does not match the booking. Please ensure the provided last name matches the reservation."
            ),
          }));
          return;
        }
        return;
      } else {
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t(
            "Unable to retrieve reservation. Please check your details or proceed to the front desk for assistance."
          ),
        }));
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: error.message,
      }));
      console.log(error);
      return;
    }
    return;
  };

  // useEffect(() => {
  //
  //   if (activeCase === 'right' && confirmationNumberData.lastName && confirmationNumberData.roomNumber) {
  //     setActive(true);
  //   } else if (activeCase === 'left' && confirmationNumberData.confirmationNumber) {
  //     setActive(true);
  //   } else if (activeCase === 'left' && confirmationNumberData.lastName && confirmationNumberData.roomNumber) {
  //     setActive(true);
  //   }
  //   else {
  //     setActive(false);
  //   }
  // }, [
  //   confirmationNumberData.confirmationNumber,
  //   confirmationNumberData.lastName,
  //   confirmationNumberData.roomNumber,
  // ]);

  const handlebackPage = () => {
    try {
      homePageHandler(dispatch, navigate);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };

  return (
    <div>
      <main>
        <div className="main-content confirmation-white">
          <div>
            <Header
              onClick={handlebackPage}
              setShowModal={openModal}
              isHome={true}
              title={t("Check-Out")}
              subTitle={t("Please enter your details")}
            />
          </div>

          {/* <CheckInCheckOutToggleForm
            activeCase={activeCase}
            handleLeftClick={handleLeftClick}
            handleRightClick={handleRightClick}
            confirmationNumberData={confirmationNumberData}
            confirmationDataChangeHandler={confirmationDataChangeHandler}
            selectedDate={selectedDate}
            setShowCalendar={setShowCalendar}
            showCalendar={showCalendar}
            handleDateChange={handleDateChange}
            tileDisabled={tileDisabled}
            getConfirmationDetails={getCheckoutDetails}
            active={active}
            type='CheckOut'
          /> */}

          {stepConfigs && (
            <>
              <CheckInCheckOutToggleForm
                activeCase={activeCase}
                confirmationNumberData={confirmationNumberData}
                confirmationDataChangeHandler={confirmationDataChangeHandler}
                selectedDate={selectedDate}
                setShowCalendar={setShowCalendar}
                showCalendar={showCalendar}
                handleDateChange={handleDateChange}
                tileDisabled={tileDisabled}
                getConfirmationDetails={getCheckoutDetails}
                active={active}
                type="CheckOut"
                stepConfigs={stepConfigs}
                onKeyDown={handleKeyDown}
              />
            </>
          )}
        </div>
        <LoaderPopup loading={loading} />
        <ErrorPopup
          closeErrorModal={closeErrorModal}
          erroModalData={erroModalData}
        />
        <CheckoutFooter
          page={"confirmation"}
          showModal={showModal}
          openModal={openModal}
          timerCounter={timerCounter}
          timerValue={timerValue}
          restartTimer={restartTimer}
          stopTimer={stopTimer}
          timeLimit={timeLimit}
        />
      </main>
      {showModal && (
        <HomePageCard
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
          showModal={showModal}
        />
      )}
    </div>
  );
};

export default CheckOutPage;
