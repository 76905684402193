import {
  CONFIG_MASTER_ENUM,
  STEP_MASTER_ENUM,
  timerConstantValue,
} from "../../constants/constant";
import { HOME, SEND_FOLIO } from "../../routes/Paths";
import {
  checkOut,
  postPaymentToPMS,
  triggerPayment,
} from "../../network/apiService";
import {
  formatCardExpiry,
  getConfigurationCode,
  getParametersFromLocalStorage,
  getStepMaster,
  homePageHandler,
} from "../../helpers/commonHelper";
import { useDispatch, useSelector } from "react-redux";

import CheckoutFooter from "../../components/Layout/CheckoutFooter";
import HomePageCard from "../../components/Layout/Card/HomePageCard";
import Payment from "../../components/Payment/Payment";
import { getCheckoutData } from "../../store/Checkout/checkoutSlice";
import { getModuleConfiguration } from "../../store/Checkin/moduleConfigurationSlice";
import { getPropertyInfo } from "../../store/Checkin/Scan/propertySlice";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const CheckoutPayment = () => {
  const { t } = useTranslation();
  const timeLimit = timerConstantValue;
  const [showModal, setShowModal] = useState(false);
  const [paymentToggle, setPaymentToggle] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [erroModalData2, setErrorModalData2] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [erroModalData3, setErrorModalData3] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [erroModalData4, setErrorModalData4] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const token = getParametersFromLocalStorage("token");
  const totalAmount = localStorage.getItem("DueAmount");
  const navigate = useNavigate();
  const propertyData = useSelector(getPropertyInfo);
  const userData = useSelector(getCheckoutData);
  const deviceConfig = localStorage.getItem("PaymentDevice");
  const [i, setI] = useState(0);

  const stepMaster = useSelector((state) => getModuleConfiguration(state));
  const steps = getStepMaster(stepMaster, STEP_MASTER_ENUM.FOLIO_PAGE);
  const configs = steps?.configs;
  const isSettleByPMS = getConfigurationCode(
    configs,
    CONFIG_MASTER_ENUM.SETTLE_BY_PMS
  );

  const stepsPay = getStepMaster(stepMaster, STEP_MASTER_ENUM.PAYMENT_PAGE);
  const isTogglePay = getConfigurationCode(
    stepsPay?.configs,
    CONFIG_MASTER_ENUM.TOGGLE_PAY
  );

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const closeErrorModal2 = () => {
    setErrorModalData2((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const closeErrorModal3 = () => {
    setErrorModalData3((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const closeErrorModal4 = () => {
    setErrorModalData4((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };
  const incrementI = () => {
    setI(i + 1);
  };
  const enablePaymentToggle = async () => {
    if (isTogglePay === true) {
      setPaymentToggle(true);
    } else {
      paymentTypeHandler();
    }
  };

  const userCheckOut = async () => {
    try {
      setLoading3(true);

      const confirmNo = localStorage.getItem("confirmationNo");
      const ReservationNameID = localStorage.getItem("reservationNameID");

      const checkinData = {
        confirmationNo: confirmNo,
        reservationNameID: ReservationNameID,
        roomNumber: userData?.roomNo,
        isNoPost: true,
        isPreAuthSpecified: false,
        preAuthAmount: 0,
        // payment: {
        //   vendor: "Q3",
        //   paymentType: "CA",
        //   terminalId: "100010001",
        //   trxNumber: "txn98980098",
        //   trxAmount: 10,
        //   currency: "AED",
        //   workStation: "KIOSK1",
        //   orderId: "OR1090090",
        // },
      };
      const response2 = await checkOut(checkinData, token);
      if (response2.data?.result === true) {
        setLoading3(false);
        setSuccessLoader(true);
        setTimeout(() => {
          setSuccessLoader(false);
          navigate(SEND_FOLIO, { replace: true });
        }, 2000);
        return;
      }
    } catch (error) {
      setLoading3(false);
      setErrorModalData3((state) => ({
        ...state,
        toggleError: true,
        errorMessage: error.message,
      }));
      console.log(error);
      return;
    }
    return;
  };

  const doCheckout = async (data) => {
    const confirmNo = localStorage.getItem("confirmationNo");
    const ReservationNameID = localStorage.getItem("reservationNameID");

    const checkOutData = {
      confirmationNo: confirmNo,
      reservationNameID: ReservationNameID,
      data, // paymentType,
    };
    const response2 = await checkOut(checkOutData, token);
    if (response2?.data?.result === true) {
      setLoading3(false);
      setPaymentToggle(false);
      setSuccessLoader(true);
      setTimeout(() => {
        setSuccessLoader(false);
        navigate(SEND_FOLIO, { replace: true });
        return;
      }, 2000);
    } else {
      setLoading2(false);
      setLoading3(false);
      if (isTogglePay === true) {
        setPaymentToggle(true);
      }
      setErrorModalData4((state) => ({
        ...state,
        toggleError: true,
        errorMessage: t(
          "Check-out Not Allowed. Please visit front desk for assistance"
        ),
      }));
      setTimeout(() => {
        handlebackPage();
      }, 6000);
      return;
    }
  };

  const paymentTypeHandler = async (paymentMode) => {
    try {
      incrementI();
      setLoading2(true);

      const confirmNo = localStorage.getItem("confirmationNo");
      const ReservationNameID = localStorage.getItem("reservationNameID");

      const paymentType = {
        account: userData?.account,
        amount: Number.parseFloat(totalAmount).toFixed(2),
        poiID: deviceConfig, // deviceConfig,//deviceID.settingsValue,//"AMS1-000168230430477",
        currency: propertyData?.data?.currency,
        reservationNameID: ReservationNameID,
        pmsConfirmationNo: confirmNo,
      };

      if (isSettleByPMS === true) {
        await doCheckout(null);
        return;
      }
      const response = await triggerPayment(paymentType, token);
      if (response?.data?.result) {
        setLoading2(false);
        setLoading3(true);

        const paymentData = {
          pmsConfirmationNo: confirmNo,
          reservationNameID: ReservationNameID,
          postDate: new Date().toISOString(),
          amount: totalAmount,
          info: response?.data?.data?.refNumber,
          terminalID: deviceConfig, //"KIOSK",
          folioWindow: 1,
          //creditCardType: response?.data?.data?.cardType?.toUpperCase(),//"CA", //Use this in production
          creditCardType: response?.data?.data?.cardType?.toUpperCase() ?? "CA", //Use this in Developer Env
          creditCardNumber: response?.data?.data?.cardNumber, // "4151500000000008",
          creditCardExpiry:
            response?.data?.data?.cardExpiry !== null &&
            response?.data?.data?.cardExpiry !== undefined
              ? formatCardExpiry(response?.data?.data?.cardExpiry)
              : null,
          preAuthRule: "",
          reference: "",
        };

        const response3 = await postPaymentToPMS(paymentData, token);
        if (response3.status === 200 && response.data.result) {
          await doCheckout(null);
        } else {
          if (i === 3) {
            setLoading3(false);
            if (isTogglePay === true) {
              setPaymentToggle(true);
            }
            setTimeout(() => {
              setErrorModalData2((state) => ({
                ...state,
                toggleError: true,
                errorMessage: t(
                  "Check-out Not Allowed. Please visit front desk for assistance"
                ),
              }));
              handlebackPage();
            }, 6000);

            return;
          } else {
            setLoading3(false);
            if (isTogglePay === true) {
              setPaymentToggle(true);
            }
            setTimeout(() => {
              setErrorModalData2((state) => ({
                ...state,
                toggleError: false,
                errorMessage: t(
                  "Payment unsuccessful. Please review your payment details and try again, or proceed to the front desk for assistance."
                ),
              }));
            }, 6000);
          }
        }
      } else {
        if (i === 3) {
          setLoading2(false);
          setLoading3(false);
          if (isTogglePay === true) {
            setPaymentToggle(true);
          }
          setTimeout(() => {
            setErrorModalData2((state) => ({
              ...state,
              toggleError: true,
              errorMessage: t(
                "Payment unsuccessful. Please visit front desk for assistance."
              ),
            }));
            handlebackPage();
          }, 6000);
          return;
        } else {
          setLoading2(false);
          setLoading3(false);
          if (isTogglePay === true) {
            setPaymentToggle(true);
          }
          setTimeout(() => {
            setErrorModalData3((state) => ({
              ...state,
              toggleError: false,
              errorMessage: t(
                "Payment unsuccessful. Please review your payment details and try again, or proceed to the front desk for assistance."
              ),
            }));
          }, 6000);
        }
      }
    } catch (error) {
      setLoading2(false);
      setLoading3(false);
      if (isTogglePay === true) {
        setPaymentToggle(true);
      }
      setErrorModalData2((state) => ({
        ...state,
        toggleError: true,
        errorMessage: t(
          "Payment unsuccessful. Please review your payment details and try again, or proceed to the front desk for assistance."
        ),
      }));
      console.log(error);
      return;
    }
  };
  const dispatch = useDispatch();
  const handlebackPage = () => {
    try {
      homePageHandler(dispatch, navigate);
    } catch (error) {
      console.error("Error returning back:", error);
    }
  };
  return (
    <div>
      <Payment
        loading={loading}
        loading2={loading2}
        loading3={loading3}
        userData={userData}
        erroModalData={erroModalData}
        erroModalData2={erroModalData2}
        erroModalData3={erroModalData3}
        generateToken={enablePaymentToggle}
        closeErrorModal={closeErrorModal}
        closeErrorModal2={closeErrorModal2}
        closeErrorModal3={closeErrorModal3}
        openModal={openModal}
        paymentToggle={paymentToggle}
        setPaymentToggle={setPaymentToggle}
        navigateUrl={SEND_FOLIO}
        userCheckIn={userCheckOut}
        paymentTypeHandler={paymentTypeHandler}
        successLoader={successLoader}
        successMessage={t("You have been successfully Checked-out")}
        erroModalData4={erroModalData4}
        closeErrorModal4={closeErrorModal4}
        property={propertyData?.data}
        totalDue={totalAmount}
        onBackPressed={handlebackPage}
      />
      <CheckoutFooter
        page={"payment"}
        showModal={showModal}
        openModal={openModal}
        timerCounter={timerCounter}
        timerValue={timerValue}
        restartTimer={restartTimer}
        stopTimer={stopTimer}
        timeLimit={timeLimit}
      />
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
    </div>
  );
};

export default CheckoutPayment;
