import Thankyou from "../../components/ThankYou/Thankyou";
import { getPassportScanData } from "../../store/Checkin/Scan/scanPassportSlice";
import { getModuleConfiguration } from "../../store/Checkin/moduleConfigurationSlice";
import { useSelector } from "react-redux";
import { STEP_MASTER_ENUM } from "../../constants/constant";
import { getStepMaster } from "../../helpers/commonHelper";

const ThankyouPassportScan = ()=>{
  const userData = useSelector(getPassportScanData);
  const roomPin =  localStorage.getItem("RoomPin");

  const stepMaster = useSelector(state => getModuleConfiguration(state));
  const thankYouPage = getStepMaster(stepMaster,STEP_MASTER_ENUM.CHECKIN_THANKYOU_PAGE);

  return thankYouPage ? ( <Thankyou 
        userData={userData}
        roomPin={roomPin}
        
        />
    ): null;
}

export default ThankyouPassportScan;