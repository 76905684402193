/* eslint-disable jsx-a11y/alt-text */

import { Link, useNavigate } from "react-router-dom";
import { homePageHandler, removeParametersFromLocalStorage } from "../../helpers/commonHelper";
import { useEffect, useState } from "react";

import CheckoutFooter from "../Layout/CheckoutFooter";
import Header from "../Layout/Header";
import HomePageCard from "../Layout/Card/HomePageCard";
import LinkImg from "../../assets/images/thankyou.png";
import { thankyouPageTimerValue } from "../../constants/constant";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { userReservationDetailsCheckoutPage } from "../../store/Checkout/checkoutSlice";

const Thankyou2 = () => {
  const timeLimit = thankyouPageTimerValue;
  const [showModal, setShowModal] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const homePageHandler1 = () => {
    try {
      homePageHandler(dispatch, navigate);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
    return;
  };
  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };
  // useEffect(() => {
  //   if (timerValue === 0) {
  //     homePageHandler(dispatch, navigate);
  //   }
  // }, [timerValue]);

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(false);
    handlebackPage();
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };
  const handlebackPage = () => {
    try {
      homePageHandler1(dispatch, navigate);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };
  const { t } = useTranslation();
  return (
    <div>
      <main>
        <div className="main-content bg-content">
          <div className="vh-100 bg-content">
            <div>
              <div>
                <Header
                  onClick={handlebackPage}
                  setShowModal={openModal}
                  isHome={true}
                  title={""}
                  subTitle={""}
                />
              </div>
            </div>
            <div className="thank-you-box thank-you-center">              
              <div className="mt-30 font-weight-500 font-14">
                <h4 className="mb-0 text-primary">
                   {t("Thank you for staying with us")}                  
                </h4>
                <p className="mt-10 mb-0">
                {/* {t("Please drop your <span>keycard</span> on the countertop.")}  */}
                {t("Please drop your")} <span>{t("keycard")}</span> {t("on the countertop")}
                </p>
              </div>{" "}
              <br />
              <div>
                <img src={LinkImg} className="thank-logo-img" style={{marginTop:"30px", marginBottom:"30px"}} />
              </div>
            </div>
          </div>
        </div>
        <CheckoutFooter
          page={"thankyou"}
          showModal={showModal}
          openModal={openModal}
          timerCounter={timerCounter}
          timerValue={timerValue}
          restartTimer={restartTimer}
          stopTimer={stopTimer}
          timeLimit={timeLimit}
        />
      </main>
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
    </div>
  );
};

export default Thankyou2;
