import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { getParametersFromLocalStorage } from "../../../helpers/commonHelper";
import { initReactI18next, useTranslation } from "react-i18next";

const ConfirmAdultCountModal = ({
  showConfirmModal,
  confirmAdultCount,
  closeModal,
  adultCount
}) => {
  const { t } = useTranslation();
  const [selectedAdultCount, setSelectedAdultCount] = useState(adultCount);
  const [isFocused, setIsFocused] = useState(false);

  const handleSelectChange = (event) => {
    const selectedValue = parseInt(event.target.value);
    setSelectedAdultCount(Math.min(selectedValue, 3)); // Limiting to maximum of 3
  };

  return (
    <div>
      <Modal open={showConfirmModal} style={{ zIndex: "1" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "800px",
            backgroundColor: "#f5f5f5",
            border: "2px #000",
            boxShadow: "0 0 24px rgba(0, 0, 0, 0.5)",
            padding: "70px",
            borderRadius: "20px",
          }}
        >
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: "center", fontSize: "1.8rem" }}
          >
            <p>{t("Would you like to change Adult Count")+"?"}</p>
          </Typography>

          <div style={{ textAlign: "center" }}>
            <div style={{ marginBottom: "20px" }}>
            <select
              value={selectedAdultCount}
              onChange={handleSelectChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              style={{
                padding: "10px",
                width: "100px", // Adjust width as needed
                border: isFocused ? "2px solid #007bff" : "2px solid #ced4da", // Highlight border color
                outline: "none", // Remove default focus outline
                borderRadius: "5px", // Rounded corners
                transition: "border-color 0.2s ease", // Smooth transition
              }}
            >
                {[1, 2, 3].map((count) => (
                    <option key={count} value={count} selected={selectedAdultCount}>
                    {count}
                    </option>
                ))}
                </select>
            </div>

            <div style={{ marginBottom: "20px" ,  marginTop: "50px"}}>
                <button
                style={{ marginTop: "20px", padding: "15px", marginLeft: "15px" }}
                className="btn button-blue scan-btn"
                onClick={() => {
                    confirmAdultCount(selectedAdultCount);
                }}
                >
                {t("Yes")}
                </button>

                <button
                style={{ marginTop: "20px", padding: "15px", marginLeft: "15px" }}
                className="btn button-blue scan-btn"
                onClick={() => {
                    closeModal();
                }}
                >
                {t("No")}
                </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ConfirmAdultCountModal;
