import {
  CONFIG_MASTER_ENUM,
  STEP_CONFIG_ENUM,
  STEP_MASTER_ENUM,
  timerConstantValue,
} from "../../constants/constant";
import {
  FACE_VERIFICATION,
  KEY,
  KEY_NEW,
  RESERVATION_LIST_FORM,
} from "../../routes/Paths";
import React, { useRef, useState } from "react";
import { fetchProfiles, fetchReservation } from "../../store/Checkin/reservationformSlice";
import {
  formatDate,
  formatSysDate,
  getConfigurationCode,
  getParametersFromLocalStorage,
  getStepMaster,
  homePageHandler,
  saveIntoLocalStorage,
} from "../../helpers/commonHelper";
import { useDispatch, useSelector } from "react-redux";

import CheckInCheckOutToggleForm from "../../components/Forms/CheckInCheckOutToggleForm";
import ErrorPopup from "../../components/LoaderAndNotification/ErrorPopup";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import HomePageCard from "../../components/Layout/Card/HomePageCard";
import KeyFooter from "../../components/Layout/KeyFooter";
import LoaderPopup from "../../components/LoaderAndNotification/LoaderPopup";
import { getModuleConfiguration } from "../../store/Checkin/moduleConfigurationSlice";
import { getPropertyInfo } from "../../store/Checkin/Scan/propertySlice";
import { getReservationDetails } from "../../network/apiService";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { userReservationDetailsCheckoutPage } from "../../store/Checkout/checkoutSlice";
import { userReservationDetailsPassport } from "../../store/Checkin/Scan/scanPassportSlice";

const KeyReservationSearch = () => {
  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  const timeLimit = timerConstantValue;
  const [showModal, setShowModal] = useState(false);
  const [confirmationNumberData, setConfirmationNumberData] = useState({
    lastName: "",
    confirmationNumber: "",
    checkoutDate: "",
  });

  const [loading, setLoading] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);

  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const calendarRef = useRef(null);

  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const propertyData = useSelector(getPropertyInfo);
  

  const stepMaster = useSelector((state) => getModuleConfiguration(state));
  // const stepConfigs = [
  //   {
  //     stepConfigurationCode: STEP_CONFIG_ENUM.LASTNAME_CHKDT,
  //     stepConfigurationName: "",
  //   },
  // ];
  const stepConfigs = getStepMaster(stepMaster, STEP_MASTER_ENUM.KEY_ENCODE_PAGE);

  const stepConfigsKey = getStepMaster(stepMaster, STEP_MASTER_ENUM.KEY_SEARCH);

  const configs = stepConfigsKey?.configs;
  const isFaceMatchEnabled = getConfigurationCode(
    stepConfigs?.configs,
    CONFIG_MASTER_ENUM.FACE_MATCH
  );

  saveIntoLocalStorage("faceMatch", isFaceMatchEnabled);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = getParametersFromLocalStorage("token");
  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };
  const confirmationDataChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setConfirmationNumberData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const pad = (number) => {
    return number < 10 ? "0" + number : number;
  };
  const tileDisabled = ({ activeStartDate, date, view }) => {
    if (view === "month") {
      return date.getMonth() !== activeStartDate.getMonth();
    }
  };
  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  //   setShowCalendar(false);
  //   const month = pad(date.getMonth() + 1);
  //   const day = pad(date.getDate());
  //   const year = date.getFullYear();
  //   const fullDate = day + "/" + month + "/" + year;
  //   document.getElementsByName("selectedDate")[0].value = fullDate;
  // };
  const handleDateChange = (date) => {
   
    // const month = pad(date.getMonth() + 1);
    // const day = pad(date.getDate());
    // const year = date.getFullYear();
    // const fullDate = day + "/" + month + "/" + year;
    setSelectedDate(date);
    setShowCalendar(false);
    const fullDate = formatDate(date)
    document.getElementsByName("selectedDate")[0].value = fullDate;
  };
  
  const getConfirmationDetails = async () => {
    try {
      setLoading(true);
      // if (selectedDate !== null && selectedDate !== undefined) {
      //   let checkoutDateFormatted = formatSysDate(selectedDate);
      //   confirmationNumberData.checkoutDate = checkoutDateFormatted;
      // }
      if (selectedDate !== null && selectedDate !== undefined) {
        let checkoutDateFormatted = formatDate(selectedDate);
        confirmationNumberData.checkoutDate = checkoutDateFormatted;
      }
      setLoading(true);
      //let form = formatSysDate(selectedDate);
      //confirmationNumberData.checkinDate = form;
      const newObj = {
        LastName: confirmationNumberData.lastName?.trimEnd()?.trimStart(),
        ConfirmationNo: confirmationNumberData.confirmationNumber?.replace(" ",""),
        PropertyCode: propertyData.data.propertyCode,
        RoomNumber: confirmationNumberData.roomNumber?.replace(" ",""),
        CheckOutDate: confirmationNumberData.checkoutDate,
        ReservationStatus: "inhouse",
      };
      const response = await getReservationDetails(newObj, token);
      if (response.data.statusCode === 200 && response.data.data != null) {
        if (response.data.data?.isCheckinAllowed) {
          setLoading(false);
          setErrorModalData((state) => ({
            ...state,
            toggleError: true,
            errorMessage: t(
              "Sorry, unable to fetch reservation. Reservation is already checked out."
            ),
          }));
          return;
        } else {
          
          dispatch(userReservationDetailsCheckoutPage(response.data.data));
          dispatch(userReservationDetailsPassport(response.data.data));
          setConfirmationNumberData({
            lastName: "",
            checkinDate: "",
            confirmationNumber: "",
          });
          localStorage.setItem(
            "confirmationNo",
            response.data.data.pmsConfirmationNo
          );
          localStorage.setItem(
            "reservationNameID",
            response.data.data.reservationNameID
          );
          if (isFaceMatchEnabled) {
            await dispatch(
              fetchProfiles({
                ConfirmationNo: response.data.data.pmsConfirmationNo,
                ReservationNameID: response.data.data.reservationNameID,
                token,
              })
            ).then(async (profileList) => {
              let reservation = profileList?.payload?.reservation;
              dispatch(userReservationDetailsPassport(reservation));
              setLoading(false);
              navigate(FACE_VERIFICATION + "/pickupkey", { replace: true });
            });
          } else {
            setLoading(false);
            navigate(KEY + "/key", { replace: true });
          }

          return;
        }
      } else {
        setLoading(false);
        setErrorModalData((state) => ({
          ...state,
          toggleError: true,
          errorMessage: t(
            "Sorry, unable to fetch reservation. Reservation is already checked out."
          ),
        }));
        return;
      }
    } catch (error) {
      console.log("error2" + error);
      setLoading(false);
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: t(error),
      }));
      return;
    }
  };

  const handlebackPage = () => {
    try {
      homePageHandler(dispatch, navigate);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };
  return (
    <div>
      <main>
        <div className="main-content confirmation-white">
          <div>
            <Header
              onClick={handlebackPage}
              setShowModal={setShowModal}
              isHome={true}
              title={t("Welcome!")}
              subTitle={t("Please choose your way to Key Pickup")}
            />
          </div>

          {configs && (
            <>
              <CheckInCheckOutToggleForm
                confirmationNumberData={confirmationNumberData}
                confirmationDataChangeHandler={confirmationDataChangeHandler}
                selectedDate={selectedDate}
                setShowCalendar={setShowCalendar}
                showCalendar={showCalendar}
                handleDateChange={handleDateChange}
                tileDisabled={tileDisabled}
                getConfirmationDetails={getConfirmationDetails}
                type="Key"
                stepConfigs={configs}
              />
            </>
          )}
        </div>

        <KeyFooter
          page={"search"}
          showModal={showModal}
          openModal={openModal}
          timerCounter={timerCounter}
          timerValue={timerValue}
          restartTimer={restartTimer}
          stopTimer={stopTimer}
          timeLimit={timeLimit}
          isFaceMatchEnabled={isFaceMatchEnabled}
        />

        <LoaderPopup loading={loading} />
        <ErrorPopup
          closeErrorModal={closeErrorModal}
          erroModalData={erroModalData}
        />
      </main>
      {showModal && (
        <HomePageCard
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
          showModal={showModal}
        />
      )}
    </div>
  );
};

export default KeyReservationSearch;
