import { initReactI18next, useTranslation } from "react-i18next";

import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import i18n from "i18next";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const SubHeader = ({
  onClick,
  setShowModal,
  isHome,
  title,
  subTitle,
  highlight,
}) => {
  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
  }, []);

  return (
    <>
      <>
        {isHome === true ? (
          <>
            <div
              className="row"
              style={{
                display: "inline",
                position: "relative",
                zIndex: "99999",
              }}
            >
              <button onClick={onClick} className="btn home-btn">
                <i className="ri-home-8-line mr-3"></i>
                {t("Home")}
              </button>
            </div>
          </>
        ) : (
          <div>
            <div>
              <button onClick={onClick} className="btn button-blue back-btn">
                <i className="bx bx-chevron-left"></i>
                {t("BACK")}
              </button>
            </div>
            <div>
              <button onClick={setShowModal} className="btn home-btn">
                <i className="ri-arrow-go-back-line mr-3"></i>
                {t("RESTART")}
              </button>
            </div>
          </div>
        )}
      </>
      <div
        className="row text-center"
        style={{ marginTop: "-40px", marginBottom: "20px" }}
      >
        <div>
          <div className="page-title-name">
          <div className="d-flex text-center justify-content-center">
              <h4 className="highlight-title" style={{ display: "inline" }}>
                {highlight}{"  "}
              </h4>
              <h4 className="welcome-title" style={{marginLeft:"5px"}}>{title}</h4>
            </div>
          </div>
        </div>
        <div className="page-title mt-20">{subTitle}</div>
      </div>
    </>
  );
};

export default SubHeader;
