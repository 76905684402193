import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseApi from "../../network/baseApi";

export const upgradeRoom = createAsyncThunk("upgradeRoom", async(qd)=>{
    const response = await baseApi.post(`api/Document/ProcessDocuments`, qd);
    return response.data;
})

const initialState = {
  roomData: { loading: false, data: [], error: "" },
};

const upgradeRoomSlice = createSlice({
  name: "upgrade",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(upgradeRoom.pending, (state) => {
      state.roomData.loading = true;
    });
    builder.addCase(upgradeRoom.fulfilled, (state, action) => {
      state.roomData.loading = false;
      state.roomData.data = action.payload;
      state.roomData.error = "";
    });
    builder.addCase(upgradeRoom.rejected, (state, action) => {
      state.roomData.loading = false;
      state.roomData.data = [];
      state.roomData.error = action.error.message;
    });
  },
});

export const getUpgradeData = (state) => state.upgradeUserRoom.roomData;

export default upgradeRoomSlice.reducer;