/* eslint-disable jsx-a11y/alt-text */

import { Link, useNavigate } from "react-router-dom";
import { getParametersFromLocalStorage, homePageHandler, removeParametersFromLocalStorage } from "../../helpers/commonHelper";
import { useEffect, useState } from "react";

import CheckoutFooter from "../Layout/CheckoutFooter";
import Header from "../Layout/Header";
import HomePageCard from "../Layout/Card/HomePageCard";
import KeyFooter from "../Layout/KeyFooter";
import LinkImg from "../../assets/images/thankyou.png";
import { thankyouPageTimerValue } from "../../constants/constant";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { userReservationDetailsCheckoutPage } from "../../store/Checkout/checkoutSlice";

const Thankyou3 = () => {
  const timeLimit = thankyouPageTimerValue;
  const [showModal, setShowModal] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();  
  const isFaceMatchEnabled = getParametersFromLocalStorage("faceMatch");

  // useEffect(() => {
  //   if (timerValue === 0) {
  //     homePageHandler(dispatch, navigate);
  //   }
  // }, [timerValue]);

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };
  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };
  const handlebackPage = () => {
    try {
      homePageHandler(dispatch, navigate);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };
  return (
    <div>
      <main>
        <div className="main-content bg-content">
          <div className="vh-100 bg-content">
            <div>
              <div>
                {/* <Link to="/home-page" className="btn home-btn">
                  <i className="ri-arrow-go-back-line mr-3"></i>RESTART
                </Link> */}
                <Header
                  onClick={handlebackPage}
                  setShowModal={openModal}
                  isHome={true}
                  title={""}
                  subTitle={""}
                />
              </div>
            </div>
            <div className="thank-you-box thank-you-center">
              <i
                class="bx bxs-check-circle"
                style={{ fontSize: "55px", color: "#0ec80e" }}
              ></i>
              <div className="mt-30 font-weight-500 font-14">
                <h4 className="mb-0 text-primary">{t("All Done")}</h4>
                <p className="mt-10 mb-0">{t("Key issued successfully!!")}</p>
              </div>{" "}
              <br />
              <div>
                {/* onClick={openDownloadKey} */}
                <img
                  src={LinkImg}
                  className="thank-logo-img"
                  style={{ marginTop: "30px", marginBottom: "30px" }}
                />
              </div>
            </div>
          </div>
        </div>
        <KeyFooter
          page={"thankyou"}
          showModal={showModal}
          openModal={openModal}
          timerCounter={timerCounter}
          timerValue={timerValue}
          restartTimer={restartTimer}
          stopTimer={stopTimer}
          timeLimit={timeLimit}
          isFaceMatchEnabled={isFaceMatchEnabled}
        />
      </main>
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
    </div>
  );
};

export default Thankyou3;
