//const API_BASE_URL = "https://samso-engage.ae-samsotechapp.com";

//const API_BASE_URL = "https://engage.rotana.site/DigitalAPI";
//const API_BASE_URL ="http://192.168.52.142:781";
//const API_BASE_URL ="https://yaamava-kiosk.us-samsotechapp.com/dgjapi/";

//const API_BASE_URL ="https://192.168.50.81:1218";

// const API_BASE_URL ="http://10.5.23.170:8091/"
//const API_BASE_URL ="http://192.168.52.184:7222/"
//const API_BASE_URL ="http://192.168.52.184:2345/dgj"

const API_BASE_URL = "https://yaamava-kiosk.us-samsotechapp.com/dgjapi";
//const API_BASE_URL ="https://192.168.50.81:460";

//const API_BASE_URL = "https://rot-sam-uat01.rotana.com:444";
//export const API_BASE_URL2 = "http://localhost:4000/";

//export const API_BASE_URL2 = "http://192.168.52.185:8443/";

//reksh machne

export const API_BASE_URL2 = "http://localhost:8443/";
//kiosk server

export const API_URL = API_BASE_URL;
