import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetails: {},
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    userReservationDetailsCheckoutPage: (state, action) => {
      state.userDetails = action.payload;
    },
  },
});

export const getCheckoutData = (state) => state.checkout.userDetails;

export const { userReservationDetailsCheckoutPage } = checkoutSlice.actions;

export default checkoutSlice.reducer;