import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnEmptyString: true,
    fallbackLng: 'en', // Fallback language
    
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // Path to your translation files
      crossDomain: true
    },
    react: {
      useSuspense: false,
    },
  });
  i18n.changeLanguage('en');      
export default i18n;

// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";

// import LanguageDetector from "i18next-browser-languagedetector";
// import englishLang from "./store/english.json";
// import arabicLang from "./store/arabic.json";

// const availableLanguages = ["english", "arabic"];
// console.log('testing');
// const resources = {
//   english: { translation: englishLang },
//   arabic: { translation: arabicLang },
// };

// const option = {
//   order: ["navigator", "htmlTag", "path", "subdomail"],
//   checkWhitelist: true,
// };

// i18n
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     resources,
//     fallbackLng: "english",
//     interpolation: {
//       escapeValue: false,
//     },
//   });

//   console.log('resources:' + JSON.stringify(resources));
// export default i18n;
