import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DoneModal = ({ closeModal, navigateUrl }) => {
  const { t } = useTranslation();
  return (
    <div className="modal" style={{ zIndex: "1" }}>
      <div className="modal-dialog modal-lg modal-top">
        <div className="modal-content modal-bg">
          <div className="modal-body">
            <div className="text-center">
              <div>
                <h6>
                  {t("Do you want to skip this step without")} <br />
                  {t("selecting packages?")}
                </h6>
              </div>

              <div>
                <div className="done-modal-table">
                  <div className="d-flex justify-content-end total-text">
                    <p className="">{t("Total")}</p>
                  </div>

                  <div className="d-flex justify-content-between first-row-content">
                    <div className="">
                      <p>{t("Breakfast")}</p>
                    </div>
                    <div>
                      <p>$25.78</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between second-row-content">
                    <div className="">
                      <p>{t("Room")}</p>
                    </div>
                    <div>
                      <p>$156</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end tax-row">
                    <p className="">{t("Taxes:")}</p>
                    <p>$6.99</p>
                  </div>
                  <div className="d-flex justify-content-end all-total-text">
                    <p className="">
                      <strong>{t("Total:")} </strong>
                      <span> $ </span>188.77
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-30">
              <button
                className="btn modal-no-btn"
                data-bs-dismiss="modal"
                onClick={closeModal}
              >
                {t("No")}
              </button>
              <Link to={navigateUrl}>
                <button className="btn modal-yes-btn bg-transperent">
                  {t("Yes")}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoneModal;
