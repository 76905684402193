import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import LoaderPopup from "../LoaderAndNotification/LoaderPopup";
import Modal from "@mui/material/Modal";
import SuccessPopup from "../LoaderAndNotification/SuccessPopup";
import emailImg from "../../assets/images/email.png";
import { getCheckoutData } from "../../store/Checkout/checkoutSlice";
import { getMailDetails } from "../../store/Checkin/Scan/scanPassportSlice";
import { getModuleConfiguration } from "../../store/Checkin/moduleConfigurationSlice";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import i18n from "i18next";
import i18next from "i18next";
import smsImg from "../../assets/images/SMS.png";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import whatsImg from "../../assets/images/WhatsAPP.png";

const SendMessage = ({
  updateEmailHandler,
  emailValidation,
  title,
  phoneValidation,
  successLoader,
  loading,
  successMessage,
  emailRequired,
  smsRequired,
  whatsappRequired,
}) => {
  //const stepMaster = useSelector((state) => getModuleConfiguration(state));

  const [loader, setLoader] = useState(loading);
  // const [counter, setCounter] = useState(0);

  // const stepEmailPage = getStepMaster(
  //   stepMaster,
  //   STEP_MASTER_ENUM.CHECKIN_THANKYOU_PAGE
  // );
  // const configs = stepEmailPage?.configs;
  // const emailRequired = getConfigurationCode(
  //   configs,
  //   CONFIG_MASTER_ENUM.EMAIL_REQUIRED
  // );
  // const smsRequired = getConfigurationCode(
  //   configs,
  //   CONFIG_MASTER_ENUM.SMS_REQUIRED
  // );
  // const whatsappRequired = getConfigurationCode(
  //   configs,
  //   CONFIG_MASTER_ENUM.WHATSAPP_REQUIRED
  // );

  const userData = useSelector(getCheckoutData);
  const [email, setEmail] = useState(userData?.email || "");
  const [phone, setPhone] = useState(userData?.phone || "");
  const [showMail, setShowMail] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [showWhatsApp, setShowWhatsApp] = useState(false);
  const profileData = useSelector(getMailDetails);

  useEffect(() => {
    setEmail(profileData?.mail);
    setPhone(profileData?.phone);
  }, []);

  const emailChangeHandler = (e) => {
    let value = e.target.value;
    // Auto-fill only if not already auto-filled and specific patterns are matched
    const isBackspacing = e.nativeEvent.inputType === "deleteContentBackward";
    let newValue = value;
    if (!isBackspacing) {
      if (value.endsWith("@y") && !value.includes("@yahoo.com")) {
        newValue = value.replace("@y", "@yahoo.com");
      } else if (value.endsWith("@g") && !value.includes("@gmail.com")) {
        newValue = value.replace("@g", "@gmail.com");
      }
    }
    setEmail(newValue);
  };

  const showModalEmailHandler = () => {
    setShowMail(true);
  };
  const showModalPhoneHandler = () => {
    setShowPhone(true);
  };
  const showWhatsAppModal = () => {
    setShowWhatsApp(true);
  };
  const phoneChangeHandler = (e) => {
    setPhone(e.target.value);
  };

  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
  }, []);

  const titleMsg =
    title === "Invoice"
      ? t("Would you like to get the invoice by")
      : t("Would you like to get the Checkin-Slip by");
  const titleMsg1 =
    title === "Invoice"
      ? t("Send me the Invoice via")
      : t("Send me the Checkin-Slip via");
  const callEmailSendmessage = () => {
    setShowMail(false);
    setLoader(true);
    // const i = [emailRequired, smsRequired, whatsappRequired].filter(true).length;
    updateEmailHandler(email, null, null, "email");
  };
  const callPhoneSendmessage = () => {
    setShowWhatsApp(false);
    setShowPhone(false);
    setLoader(true);
    // const i = [emailRequired, smsRequired, whatsappRequired].filter(true).length;
    updateEmailHandler(null, phone, null, "sms");
  };
  
  const callPhoneWhatsAPPmessage = () => {
    setShowWhatsApp(false);
    setShowPhone(false);
    setLoader(true);
    // const i = [emailRequired, smsRequired, whatsappRequired].filter(true).length;
    updateEmailHandler(null, phone, null, "whatsApp");
  };
  useEffect(() => {
    if (successLoader === true) {
      setLoader(false);
    }
  }, [successLoader]);
  return (
    <>
      <div className="send-box flex justify-content-center align-self-center">
        <h5>
          {titleMsg1}
          {/* Send me the <span className="txt-primary">Invoice</span> via */}
        </h5>

        <div className="d-flex">
          {emailRequired && (
            <>
              <div
                className="send-box-type p-1"
                style={{ marginRight: "10px" }}
                onClick={showModalEmailHandler}
              >
                <br />
                <img src={emailImg} />
                <br />
                <strong>{t("e-Mail")}</strong>
                <br />
                <br />
              </div>
            </>
          )}

          {smsRequired && (
            <>
              <div
                className="send-box-type p-1 justify-content-center"
                onClick={showModalPhoneHandler}
                style={{ marginRight: "10px" }}
              >
                <br />
                <img src={smsImg} />
                <br />
                <strong>{t("SMS")}</strong>
                <br />
                <br />
              </div>
            </>
          )}

          {whatsappRequired && (
            <>
              <div
                className="send-box-type p-1 justify-content-center"
                onClick={showWhatsAppModal}
              >
                <br />
                <img src={whatsImg} />
                <br />
                <strong>{t("WhatsApp")}</strong>
                <br />
                <br />
              </div>
            </>
          )}
        </div>
      </div>
      <LoaderPopup loading={loader} />
      <SuccessPopup loading={successLoader} successMessage={successMessage} />

      <Modal open={showMail}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "800px",
            backgroundColor: "#f5f5f5",
            border: "2px #000",
            boxShadow: "0 0 24px rgba(0, 0, 0, 0.5)",
            padding: "20px",
            borderRadius: "20px",
          }}
        >
          <button className="close-icon" onClick={() => setShowMail(false)}>
            X
          </button>
          <div className="flex">
            <div className="page-title invoice-mail-title text-center">
              {titleMsg + t("Mail")}
            </div>
            <div className="reservation-confirm-box">
              <div className="reservation-confirm">
                <label>{t("Please provide your mail id")}</label>
                <input
                  type="email"
                  value={email}
                  autoComplete="off"
                  onChange={emailChangeHandler}
                  className="form-control form-control-set font-weight-600"
                  style={{ width: "100%" }}
                />
                {emailValidation && (
                  <p className="email-error-msg">
                    {t("Please enter a valid Email Address")}
                  </p>
                )}
              </div>
            </div>
            <div className="reservation-btn-set">
              <div className="d-flex">
                <button
                  onClick={callEmailSendmessage}
                  disabled={!email && emailRequired}
                  className="btn button-blue proceed-blue-btn"
                >
                  {t("Confirm")}
                </button>
                {"   "}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal open={showPhone}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "800px",
            backgroundColor: "#f5f5f5",
            border: "2px #000",
            boxShadow: "0 0 24px rgba(0, 0, 0, 0.5)",
            padding: "20px",
            borderRadius: "20px",
          }}
        >
          <button className="close-icon" onClick={() => setShowPhone(false)}>
            {t("X")}
          </button>
          <div className="flex">
            <div className="page-title invoice-mail-title text-center">
              {titleMsg + t("SMS")}
            </div>
            <div className="reservation-confirm-box">
              <div className="reservation-confirm w-500">
                <label>{t("Please provide your phone number")}</label>
                <input
                  type="number"
                  value={phone}
                  autoComplete="off"
                  onChange={phoneChangeHandler}
                  className="form-control form-control-set font-weight-600"
                  style={{ width: "100%" }}
                />
                {phoneValidation && (
                  <p className="email-error-msg">{t("Please enter a valid phone")}</p>
                )}
              </div>
            </div>
            <div className="reservation-btn-set">
              <div className="d-flex">
                <button
                  onClick={callPhoneSendmessage}
                  // onClick={() => updateEmailHandler(null, phone)}
                  disabled={!phone && smsRequired}
                  className="btn button-blue proceed-blue-btn"
                >
                  {t("Confirm")}
                </button>
                {"   "}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal open={showWhatsApp}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "800px",
            backgroundColor: "#f5f5f5",
            border: "2px #000",
            boxShadow: "0 0 24px rgba(0, 0, 0, 0.5)",
            padding: "20px",
            borderRadius: "20px",
          }}
        >
          <button className="close-icon" onClick={() => setShowWhatsApp(false)}>
            {t("X")}
          </button>
          <div className="flex">
            <div className="page-title invoice-mail-title text-center">
              {titleMsg + t("WhatsApp")}
            </div>
            <div className="reservation-confirm-box">
              <div className="reservation-confirm w-341">
                <label>{t("Please provide your phone number")}</label>
                <input
                  type="number"
                  value={phone}
                  autoComplete="off"
                  onChange={phoneChangeHandler}
                  className="form-control form-control-set font-weight-600"
                  style={{ width: "100%" }}
                />
                {phoneValidation && (
                  <p className="email-error-msg">Please enter a valid phone</p>
                )}
              </div>
            </div>
            <div className="reservation-btn-set">
              <div className="d-flex">
                <button
                  onClick={callPhoneWhatsAPPmessage}
                  disabled={!phone && whatsappRequired}
                  className="btn button-blue proceed-blue-btn"
                >
                  {t("Confirm")}
                </button>
                {"   "}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default SendMessage;
