import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import { useState } from "react";
import { useTranslation } from "react-i18next";

function PdfViewer({ folio }) {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [renderNavButtons, setRenderNavButtons] = useState(false);

  const [zoom, setZoom] = useState(1); // Initial zoom level

  const handleZoomIn = () => {
    setZoom(zoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoom(zoom - 0.1);
  };

  const base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64

    if (data != null) {
      const base64WithoutPrefix = data.substr(
        "data:application/pdf;base64,".length
      );

      const bytes = atob(base64WithoutPrefix);
      let length = bytes.length;
      let out = new Uint8Array(length);

      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }

      return new Blob([out], { type: "application/pdf" });
    }
  };

  // const blob = base64toBlob(folio);
  // const url = URL.createObjectURL(blob);
  const pdfData = "data:application/pdf;base64," + folio;

  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  //   setPageNumber(1);
  //   setRenderNavButtons(true);
  // }

  const onDocumentLoadSuccess = (numPages) => {
    setNumPages(numPages);
    setPageNumber(1);
    setRenderNavButtons(true);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };
  const previousPage = () => {
    changePage(-1);
  };
  const nextPage = () => {
    changePage(+1);
  };

  // style={{ textAlign: "center", position:"relative" }}
  return (
    <div style={{ textAlign: "center", position: "relative" }}>
      <header>
        <div>
          <Document
            file={pdfData}
            onLoadSuccess={({ numPages }) => {
              setNumPages(numPages);
              setRenderNavButtons(true);
              onDocumentLoadSuccess(numPages);
            }}
          >
            <Page textAlign="center" scale={zoom} pageNumber={pageNumber} />
          </Document>
        </div>

        {renderNavButtons && (
          <div>
            <button
              style={{
                textAlign: "center",
                position: "relative",
                marginTop: "20px",
                marginBottom: "20px",
              }}
              disabled={pageNumber <= 1}
              onClick={previousPage}
              variant="primary"
            >
              {t("Previous")}
            </button>
            {"  "}
            <button
              disabled={pageNumber === numPages}
              onClick={nextPage}
              variant="primary"
            >
              {t("Next")}
            </button>
          </div>
        )}

        <p style={{ textAlign: "center", position: "relative" }}>
          {t("Page")} {pageNumber} {t("of")} {numPages}
        </p>
      </header>
      <center></center>
    </div>
  );
}

export default PdfViewer;
