import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetails: {},
  guestCount: 1,
  mailDetails: {},

};

const passportScanSlice = createSlice({
  name: "scanpassport",
  initialState,
  reducers: {
    userReservationDetailsPassport: (state, action) => {
     
      state.userDetails = action.payload;
      //console.log("Josn=="+JSON.stringify(state.userDetails))
    },

    scanPassportCountOfTheGuests: (state, action) => {
      state.guestCount = state.guestCount + action.payload;
    },
    setEmailDetails: (state, action) => {      
      state.mailDetails = action.payload;
    },
    resetScanPassportGuestCount: (state) => {
      state.guestCount = 1;
    },
  },
});
export const getPassportScanData = (state) => state.scanpassport.userDetails;
export const getMailDetails = (state) => state.scanpassport.mailDetails;

export const getScanPassportCurrentCountOfGuest = (state) =>
  state.scanpassport.guestCount;

export const {
  userReservationDetailsPassport,
  setEmailDetails,
  scanPassportCountOfTheGuests,
  resetScanPassportGuestCount,
} = passportScanSlice.actions;

export default passportScanSlice.reducer;
