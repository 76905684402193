import Routes from "./routes/Routes"
import ThemeComponent from "./components/Theme/ThemeComponent";
import { Suspense } from 'react';
import { useTranslation} from 'react-i18next';

function App() {
  return (
    <div className="App">
      <Routes />
    </div>
  );
}
export default function WrappedApp() {
  return (
    <Suspense fallback="...loading">
      <App />
    </Suspense>
  )
}
//export default App;
