import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchLanguage } from "../../network/apiService";

const initialState = {
  languageData: {},
  LanguageSelected: localStorage.getItem("defaultLanguage")??'en',
};

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    languageSliceInfo: (state, action) => {
      state.languageData = action.payload;
    },
    setSelectedLanguage: (state, action) => {
     
      state.LanguageSelected = action.payload;
    },
  }
});

export const getLanguage = (state) => state.language.languageData;
export const getSelectedLanguage = (state) => state.language.LanguageSelected;

export const { languageSliceInfo, setSelectedLanguage} = languageSlice.actions;

export default languageSlice.reducer;




