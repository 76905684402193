import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllProfile,
  getAllReservationProfile,
  getCountries,
  getReservationDetails,
} from "../../network/apiService";

import { useTranslation } from "react-i18next";

export const fetchReservation = createAsyncThunk(
  "fetchReservation",
  async (
    { lastName, confirmationNo, propertyCode, token, checkOutDate, roomNumber },
    _thunkAPI
  ) => {
   //const { t } = useTranslation();
    const param = {};

    if (confirmationNo !== undefined && confirmationNo !== null)
      param.ConfirmationNo = confirmationNo?.replace(" ", "");
    if (lastName !== undefined && lastName !== null)
      param.LastName = lastName?.trimEnd()?.trimStart();
    if (propertyCode !== undefined && propertyCode !== null)
      param.PropertyCode = propertyCode;
    if (checkOutDate !== undefined && checkOutDate !== null)
      param.CheckOutDate = checkOutDate;
    param.ReservationStatus = "DUEIN";

    const response = await getReservationDetails(param, token);
    if (response?.data?.data === null) {
      return _thunkAPI.rejectWithValue({
        // error: response?.data?.message ?? "No reservation found.",
        error:
        (response?.data?.message !== "" && response?.data?.message !== null ) ? response?.data?.message : "This Reservation is already checked in/Reservation not found.Please visit front desk for assistance.",
      });
    }
    if (response?.data?.result === false) {
      return _thunkAPI.rejectWithValue({
        // error: response?.data?.message ?? "No reservation found.",
        error:
          (response?.data?.message !== "" && response?.data?.message !== null ) ? response?.data?.message : "This Reservation is already checked in/Reservation not found.Please visit front desk for assistance.",
      });
    }
    return response.data;
  }
);

export const fetchProfiles = createAsyncThunk(
  "fetchProfiles",
  async ({ ConfirmationNo, ReservationNameID, token }) => {
    const response = await getAllReservationProfile(ConfirmationNo,ReservationNameID, token);
    return response?.data;
  }
);
export const fetchCountries = createAsyncThunk(
  "fetchCountries",
  async (token) => {
    const response = await getCountries(token);
    return response.data?.data;
  }
);

const initialState = {
  profilesData: { loading: false, data: [], error: null, isFinished: false },
  reservationData: {
    loading: false,
    data: null,
    error: null,
    isFinished: null,
  },
  moduleType: "",
  countries: null,
};

const reservationFormSlice = createSlice({
  name: "reservationform",
  initialState,
  reducers: {
    resetReservations: (state, action) => {
      state.reservationData = { loading: false, data: null, error: "" };
      state.profilesData = { loading: false, data: [], error: "" };
    },
    setModuleType: (state, action) => {
      state.moduleType = action.payload;
    },
    resetIsFinished: (state, action) => {
      state.profilesData.isFinished = false;
      state.reservationData.isFinished = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReservation.pending, (state) => {
      state.reservationData.loading = true;
    });
    builder.addCase(fetchReservation.fulfilled, (state, action) => {
      state.reservationData.loading = false;
      state.reservationData.data = action.payload;
      state.reservationData.error = "";
      state.reservationData.isFinished = true;
    });

    builder.addCase(fetchReservation.rejected, (state, action) => {
      state.reservationData.loading = false;
      state.reservationData.data = null;
      state.reservationData.error = action.payload
        ? action.payload.error
        : "Unknown error";
      state.reservationData.isFinished = true;
    });

    builder.addCase(fetchProfiles.pending, (state) => {
      state.profilesData.loading = true;
    });
    builder.addCase(fetchProfiles.fulfilled, (state, action) => {
      state.profilesData.loading = false;
      state.profilesData.data = action.payload;
      state.profilesData.error = "";
      state.profilesData.isFinished = true;
    });
    builder.addCase(fetchProfiles.rejected, (state, action) => {
      state.profilesData.loading = false;
      state.profilesData.data = [];
      state.profilesData.error = action.payload
        ? action.payload.error
        : "Unknown error";
      state.profilesData.isFinished = true;
    });

    builder.addCase(fetchCountries.fulfilled, (state, action) => {
      state.countries = action.payload;
    });
    builder.addCase(fetchCountries.rejected, (state, action) => {
      state.countries = null;
    });
  },
});

export const { resetReservations, setModuleType, resetIsFinished } =
  reservationFormSlice.actions;

export const getModuleType = (state) => state.reservationform.moduleType;
export const getCountryList = (state) => state.reservationform.countries;

export const getReservationListData = (state) =>
  state.reservationform.reservationData;

export const getReservationData = (state) =>
  state.reservationform.profilesData?.data?.reservation;

export const getProfilesListData = (state) =>
  state.reservationform.profilesData?.data?.profiles;

export default reservationFormSlice.reducer;
