import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getPropertyData } from "../../../network/apiService";

const initialState = {
  propertyDetails: {},
  deviceConfiguration: [{}],
  isSessionExpired: false,
  stepMasterData: null,
};

export const fetchPropertyInfo = createAsyncThunk(
  "getPropertyInfo",
  async (token) => {
    const response = await getPropertyData(token);
    return response.data;
  }
);

const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    propertyInfo: (state, action) => {
      state.propertyDetails = action.payload;
    },
    setDeviceConfiguration(state, action) {
      state.deviceConfiguration = action.payload;
    },
    setStepMasterInfo(state, action) {
      state.stepMasterData = action.payload;
    },
    getTokenExpiry(state, action) {
      const expiryTimestamp = sessionStorage?.getItem("tokenExpiry");
      if (!expiryTimestamp) {
        state.isSessionExpired = true;
      }
      const expiryTime = new Date(expiryTimestamp).getTime();
      if (Date.now() > expiryTime) {
        //returns true if session token is expired
        state.isSessionExpired = true;
        //sessionStorage.removeItem("token");
      }
    },    
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPropertyInfo.fulfilled, (state, action) => {
      state.propertyDetails = action.payload;

      if (state.propertyDetails?.data != null) {
        localStorage.setItem("currency", state.propertyDetails?.data?.currency);
        localStorage.setItem(
          "propertyCode",
          state.propertyDetails?.data?.propertyCode
         
        );
        localStorage.setItem("defaultLanguage", state.propertyDetails?.data?.defaultLanguage??'en');
      }
    });
    builder.addCase(fetchPropertyInfo.rejected, (state, action) => {
      state.propertyDetails = null;
    });
  },
});

export const getPropertyInfo = (state) => state.property.propertyDetails;
export const getDeviceDetails = (state) => state.property.deviceConfiguration;
export const getStepMasterInfo = (state) => state.property.stepMasterData;
export const getIsExpired = (state) => state.property.isSessionExpired;

export const {
  propertyInfo,
  setDeviceConfiguration,
  setStepMasterInfo,
  getTokenExpiry,
} = propertySlice.actions;

export default propertySlice.reducer;
