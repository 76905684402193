/* eslint-disable jsx-a11y/alt-text */

import { initReactI18next, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import LottieHelper from "../LottieHelper";
import { STEP_MASTER_ENUM } from "../../../constants/constant";
import { getModuleConfiguration } from "../../../store/Checkin/moduleConfigurationSlice";
import { getSelectedLanguage } from "../../../store/Checkin/languageSlice";
import i18n from "i18next";
import { useSelector } from "react-redux";

const KeyCard = ({ closeKeyModal, keyModal }) => {
  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
  }, []);

  const stepMaster = useSelector((state) => getModuleConfiguration(state));
  const keyPage =
    stepMaster && stepMaster.length > 0
      ? stepMaster.find((ele) => ele.stepCode === STEP_MASTER_ENUM.KEY_PAGE)
      : null;

  return (
    <div className="modal" style={{ zIndex: "1" }}>
      <div className="modal-dialog modal-lg modal-top">
        <div className="modal-content">
          <div className="modal-body">
            <div className="text-center">
              {keyPage?.stepImage?.includes(".json") ? (
                <>{/* <LottieHelper animationData="payment_terminal" /> */}</>
              ) : (
                <>
                  <img
                    src={keyPage?.stepImage}
                    alt=""
                    style={{ backgroundSize: "center" }}
                  />
                </>
              )}

              <div className="mt-30 modal-info">
                <h3>{t("Key issued successfully!!")}</h3>
                <p>{t("Do you want additional Key?")}</p>
              </div>
            </div>
            <div className="text-center mt-30">
              <button
                onClick={() => {
                  closeKeyModal();
                }}
                className="btn modal-no-btn key-modal-no"
              >
                {t("No")}
              </button>
              <button
                className="btn modal-yes-btn key-modal-yes"
                onClick={() => {
                  keyModal(true);
                }}
              >
                {t("Yes")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyCard;
