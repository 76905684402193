/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { Link } from "react-router-dom";
import SkipImage from "../../../assets/images/person-image.PNG";
import { useTranslation, initReactI18next } from 'react-i18next';

const SkipModal = ({ closeModal, skipHandler,navigateUrl }) => {
  const { t } = useTranslation();
  return (
    <div
      className="modal"
    >
      <div className="modal-dialog modal-lg modal-top">
        <div className="modal-content modal-bg">
          <div className="modal-body">
            <div className="text-center">
              <div>
                <img src={SkipImage} className="w-110" />
              </div>
              <div className="mt-30">
                <h6>
                  {t("Do you want to skip this step without")} <br></br>{t("selecting packages?")}
                </h6>
              </div>
            </div>
            <div className="text-center mt-30">
              <button
                className="btn modal-no-btn"
                data-bs-dismiss="modal"
                onClick={closeModal}
              >
                {t("No")}
              </button>
              <Link to={navigateUrl}>
                <button
                  className="btn modal-yes-btn bg-transperent"
                  onClick={() => {
                    skipHandler();
                  }}
                >
                  {t("Yes")}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkipModal;
