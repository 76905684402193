import { DOCUMENT_SCAN, HOME, KEY, UPGRADE } from "../../routes/Paths";
import { STEP_MASTER_ENUM, timerConstantValue } from "../../constants/constant";
import {
  clearScanData,
  getManualAuthentication,
  getScanDataFromWebhostQR,
} from "../../network/apiService";
import {
  faceVerificationCall,
  resetFaceData,
} from "../../store/Checkin/faceVerificationSlice";
import { fetchStep, postAuditLog, setIsSkipped } from "../../store/stageSlice";
import {
  getParametersFromLocalStorage,
  getProfileName,
  getScanDataFullName,
  homePageHandler,
  nameValidation,
} from "../../helpers/commonHelper";
import { getPassportScanData, setEmailDetails } from "../../store/Checkin/Scan/scanPassportSlice";
import {
  getScanBackData,
  getScanData,
  getScanDocResult,
  getScanDocumentCurrentCountOfGuest,
  getTotalScannedDocument,
  incrementScanDocumentCount,
  resetDocReponse,
  scanDocumentRetryFace,
  updateFaceItem,
} from "../../store/Checkin/Scan/documentScanSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ErrorPopup from "../../components/LoaderAndNotification/ErrorPopup";
import FaceVerification from "../../components/UserVerification/FaceVerification";
import Footer from "../../components/Layout/Footer";
import HomePageCard from "../../components/Layout/Card/HomePageCard";
import KeyFooter from "../../components/Layout/KeyFooter";
import LoaderPopup from "../../components/LoaderAndNotification/LoaderPopup";
import { fetchProfileDocuments } from "../../network/apiService";
import { getModuleConfiguration } from "../../store/Checkin/moduleConfigurationSlice";
import { getProfilesListData } from "../../store/Checkin/reservationformSlice";
import { getPropertyInfo } from "../../store/Checkin/Scan/propertySlice";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import { getStepMaster } from "../../helpers/commonHelper";
import { getconfirmationnumberData } from "../../store/Checkin/ConfirmationNumber/confirmationNumberSlice";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const FaceVerificationQrScanning = () => {
  const { t } = useTranslation();
  const { screen } = useParams();

  const selectedLanguage = useSelector(getSelectedLanguage);
  const isFaceMatchEnabled = getParametersFromLocalStorage("faceMatch");
  
  const timeLimit = timerConstantValue;
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");
  const [successLoader, setSuccessLoader] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const [facialRecognisationFailed, setFacialRecognisationFailed] =
    useState(false);
  const [erroModalData, setErrorModalData] = useState({
    toggleError: false,
    errorMessage: "",
  });
  const [isReload, setReload] = useState(false);
  const token = getParametersFromLocalStorage("token");
  const userData = useSelector(getPassportScanData);
  const scanData = useSelector(getScanData);
  const scanBackData = useSelector(getScanBackData);

  const [faceImage, setFaceImage] = useState(null);

  const signedInUserName = nameValidation(
    userData.firstName,
    userData.lastName
  );
  const dispatch = useDispatch();
  const guestCount = useSelector(getScanDocumentCurrentCountOfGuest);
  const totalScannedCount = useSelector(getTotalScannedDocument);
  // const totalCount = parseInt(localStorage.getItem("totalCount") || 0);
  const totalCount = getParametersFromLocalStorage("totalCount");

  const [capturedImages, setCapturedImages] = useState([]);
  const [verificationInitiated, setVerificationInitiated] = useState(false);
  const [reloadCapture, setReloadCapture] = useState(false);
  const [retryCounter, setRetryCounter] = useState(0);
  const [transactionGuid, setTransactionGuid] = useState("");
  const [startFaceMatch, setStartFaceMatch] = useState(null);
  const [userName, setUserName] = useState();
  const propertyData = useSelector(getPropertyInfo);
  const profileList = useSelector(getProfilesListData);
  const docResult = useSelector(getScanDocResult);
  const reservationData = useSelector(getconfirmationnumberData);

  const [documentImages, setDocumentImages] = useState();

  const stepMaster = useSelector((state) => getModuleConfiguration(state));

  const documentScanPage = getStepMaster(
    stepMaster,
    STEP_MASTER_ENUM.SCAN_DOCUMENT_PAGE
  );
  const faceVerificationPage = getStepMaster(
    stepMaster,
    STEP_MASTER_ENUM.FACEVERIFICATION_PAGE
  );
  const manualAuthenticationPage = getStepMaster(
    stepMaster,
    STEP_MASTER_ENUM.MANUAL_AUTHENTICATION_PAGE
  );

  const settings = getParametersFromLocalStorage("settings");
  const enableChildScan = settings?.find(
    (setting) =>
      setting.parameterName === "EnableChildScan" &&
      setting.parameterValue?.toLowerCase() === "true"
  );
  const [showDocumentScanPage, setDocumentScanPage] =
    useState(documentScanPage);
  const [showfaceVerificationPage, setfaceVerificationPage] =
    useState(faceVerificationPage);
  const [manualAuthenticationRequired, setManualAuthenticationRequired] =
    useState(manualAuthenticationPage);

  useEffect(() => {
    if (enableChildScan) {
      if (
        reservationData?.childCount > 0 &&
        guestCount > reservationData?.adultCount
      ) {
        gotoScreen();
      }
    }

    dispatch(setIsSkipped(null));
    if (!showfaceVerificationPage || !showDocumentScanPage) {
      navigate(UPGRADE, { replace: true });
      return;
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);

    dispatch(resetFaceData());

    if (
      (scanData !== null &&
        scanData?.Portrait !== null &&
        scanData?.Portrait !== undefined &&
        scanData?.Portrait !== "") ||
      (scanBackData !== null &&
        scanBackData?.Portrait !== null &&
        scanBackData?.Portrait !== undefined &&
        scanBackData?.Portrait !== "")
    ) {
      if (
        scanData?.Portrait !== undefined &&
        scanData?.Portrait !== null &&
        scanData?.Portrait !== "" &&
        scanData?.DocumentImageSide1 !== undefined &&
        scanData?.DocumentImageSide1 !== null
      ) {
        setDocumentImages({
          DocumentBase64Image: scanData?.DocumentImageSide1,
          FaceImageBase64Image: scanData?.Portrait ?? "",
        });
        setFaceImage(null);
        setFaceImage("data:image/jpeg;base64," + scanData?.Portrait ?? "");
      }

      if (
        scanBackData?.Portrait !== undefined &&
        scanBackData?.Portrait !== null &&
        scanBackData?.Portrait !== "" &&
        scanBackData?.DocumentImageSide1 !== undefined &&
        scanBackData?.DocumentImageSide1 !== null
      ) {
        setDocumentImages({
          DocumentBase64Image: scanBackData?.DocumentImageSide1,
          FaceImageBase64Image: scanBackData?.Portrait ?? "",
        });
        setFaceImage("data:image/jpeg;base64," + scanBackData?.Portrait ?? "");
      }
      setTransactionGuid(scanData?.transactionGUID ?? "");
      // if (
      //   scanData.LastName !== undefined &&
      //   scanData.LastName !== null &&
      //   scanData.LastName !== ""
      // ) {
      //   // setUserName(
      //   //   scanData.Title === null || scanData.Title === undefined
      //   //     ? ""
      //   //     : scanData.Title + " " + scanData.GivenName
      //   // );
      //   setUserName(scanData.Title+" "+ scanData.FirstName+" "+scanData.LastName);
      // } else if (
      //   scanBackData.LastName !== undefined &&
      //   scanBackData.LastName !== null &&
      //   scanBackData.LastName !== ""
      // ) {
      //   setUserName(
      //     scanBackData.Title === null || scanBackData.Title === undefined
      //       ? ""
      //       : scanBackData.Title + " " + scanBackData.FirstName+" "+ scanBackData.LastName
      //   );
      // }
      //getFullName();
      let currentProfile = null;
      if ((profileList?.length ?? 0) > guestCount)
        currentProfile = profileList[guestCount];
      setUserName(getProfileName(scanData, scanBackData, currentProfile));
      onStartFaceMatch();
    } else if (
      faceImage === null ||
      faceImage === undefined ||
      faceImage?.FaceImageBase64Image === null ||
      faceImage?.FaceImageBase64Image === undefined ||
      faceImage?.FaceImageBase64Image === ""
    ) {
      getDocumentImage();
    }
  }, []);

  const onStartFaceMatch = () => {
    resetCounter();
    setStartFaceMatch(true);
    setFacialRecognisationFailed(false);
    setReload(false);
    setLoading(false);
  };

  useEffect(() => {
    if (docResult === true) {
      dispatch(resetDocReponse());
      verifyStageInface();
      //setFacialRecognisationFailed(true);
    }
  }, [docResult, showfaceVerificationPage]);

  const getDocumentImage = async () => {
    try {
      setLoading(true);
      setLoadingMsg("Loading face image..");
      const docresponse = await fetchProfileDocuments(
        {
          ConfirmationNo: userData?.pmsConfirmationNo,
          type: "CHECKIN",
        },
        token
      );
      if (docresponse.status === 200 && docresponse.data != null) {
        setTransactionGuid(docresponse.data.data[0].TransactionGUID);
        let currentProfile = null;
        if ((profileList?.length ?? 0) > guestCount)
          currentProfile = profileList[guestCount];


        dispatch(
          setEmailDetails({
            mail: docresponse.data?.profiles?.email,
            phone: docresponse.data?.profiles?.phoneNo_UAE,
          })
        );

        let docImage = null;
        if (
          Array.isArray(docresponse.data.data) &&
          docresponse.data.data?.length > 0
        ) {
          const firstElement = docresponse.data.data[0];
          if (firstElement) {
            docImage =
              firstElement.PMSProfileID === currentProfile?.pmsProfileID
                ? firstElement
                : null;
          }
        } else {
          docImage = docresponse.data.data?.find(
            (ele) => ele?.PMSProfileID === currentProfile?.pmsProfileID
          );
        }
        if (docImage != null) {
          // setDocumentImages(docImage);
          //uniqueValues.push(docImage.DocumentBase64Image);
          let faceImg = "";
          if (
            Array.isArray(docresponse.data.data) &&
            docresponse.data.data.length > 0
          ) {
            const firstElement = docresponse.data.data[0];
            if (firstElement) {
              faceImg =
                firstElement.PMSProfileID === currentProfile?.pmsProfileID &&
                firstElement?.FaceImageBase64Image !== ""
                  ? firstElement
                  : null;
            }
          } else {
            faceImg = docresponse.data.data?.find(
              (ele) =>
                ele?.PMSProfileID === currentProfile?.pmsProfileID &&
                ele?.FaceImageBase64Image !== ""
            );
          }
          setFaceImage(
            "data:image/jpeg;base64," + faceImg?.FaceImageBase64Image
          );
          // const title =
          //   docImage?.Title === null || docImage?.Title === undefined
          //     ? ""
          //     : docImage?.Title + " ";
          // const username =
          //   title + "" + docImage?.FirstName + " " + docImage?.LastName;
          // debugger;
          // setUserName(username);
          setUserName(getProfileName(null, null, currentProfile));
          setDocumentImages({
            DocumentBase64Image: docImage?.DocumentBase64Image,
            FaceImageBase64Image: faceImg?.FaceImageBase64Image ?? "",
          });
          dispatch(incrementScanDocumentCount());
          dispatch(incrementScanDocumentCount());
          restartCapture();
          onStartFaceMatch();
        } else {
          console.error("Error fetching document image:");
          setLoadingMsg("Please wait while we process your request");
          setLoading(false);
          setFacialRecognisationFailed(false);
          setErrorModalData((state) => ({
            ...state,
            toggleError: true,
            errorMessage: t(
              "Error in fetching document.Please contact front desk."
            ),
          }));
          setTimeout(() => {
            handlebackPage();
          }, 4000);
        }
      }
    } catch (error) {
      console.error("Error fetching document image:", error);
      // Handle error
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: t(
          "Error in fetching document.Please contact front desk."
        ),
      }));
      setLoadingMsg("Please wait while we process your request");
      setSuccessLoader(false);
      setLoading(false);
      setTimeout(() => {
        handlebackPage();
      }, 4000);
    }
  };
  useEffect(() => {
    resetCounter();
    const timeout = setTimeout(() => {
      // setCaptureImages(true);
    }, 6000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (retryCounter > 0 && showfaceVerificationPage) {
      setReloadCapture(true); // Trigger reload after state reset
    }
  }, [retryCounter, showfaceVerificationPage]);

  const updateCounter = () => setRetryCounter(retryCounter + 1);
  const resetCounter = () => setRetryCounter(0);

  const handleRetry = () => {
    setReloadCapture(false); // Toggle reload state
    updateCounter();
    setCapturedImages([]);
    setReload(true);
    if (retryCounter < 3) {
      setVerificationInitiated(false);
      dispatch(scanDocumentRetryFace(1));
      setFacialRecognisationFailed(false);
      restartCapture();
      setCapturedImages([]);
    } else {
      setFacialRecognisationFailed(false);
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: t(
          "Face verification failed. Please visit front desk for assistance."
        ),
      }));
      gotoHome();
    }
  };

  const gotoHome = () => {
    resetCounter();
    const timeoutId = setTimeout(() => {
      setFacialRecognisationFailed(false);
      setErrorModalData((state) => ({
        ...state,
        toggleError: true,
        errorMessage: t(
          "Face verification failed. Please visit front desk for assistance."
        ),
      }));
      setCapturedImages([]);
      setStartFaceMatch(false);

      handlebackPage();
    }, 3000);
    dispatch(resetFaceData());

    return () => clearTimeout(timeoutId);
  };

  useEffect(async () => {
    if (
      capturedImages.length === 1 &&
      startFaceMatch === true &&
      showfaceVerificationPage
    ) {
      const confirmationNo = localStorage.getItem("confirmationNo");
      const params = {
        imageData: capturedImages,
        transactionGuid: transactionGuid,
        docImg: documentImages?.FaceImageBase64Image ?? "",
        token: token,
        pmsConfirmationNo: confirmationNo,
      };
      setVerificationInitiated(true);
      //await Promise.race([dispatch(faceVerificationCall(params)), wait(10000)]);
      await dispatch(faceVerificationCall(params))
        .unwrap()
        .then((resp) => {
          faceVerificationCompleted(resp);
        });
      //dispatch(faceVerificationCall(params));
    }
  }, [capturedImages, startFaceMatch, showfaceVerificationPage]);
  const enableSelfie = settings?.find(
    (setting) =>
      setting.parameterName === "EnableSelfie" &&
      setting.parameterValue?.toLowerCase() === "true"
  );
  const faceVerificationCompleted = (faceResponse) => {
    addLog(
      JSON.stringify(faceResponse),
      faceResponse?.result,
      "Selfie of guest " + userName
    );
    if (
      faceResponse !== null &&
      faceResponse !== undefined &&
      showfaceVerificationPage
    ) {
      if (faceResponse?.result === true) {
        let currentProfile = null;
        const scannedCount = totalScannedCount;
        if (totalScannedCount <= 0) {
          if ((profileList?.length ?? 0) > guestCount)
            currentProfile = profileList[guestCount];
        } else {
          if ((profileList?.length ?? 0) > guestCount)
            currentProfile = profileList[guestCount];
        }

        updateFaceImg(
          null,
          currentProfile,
          capturedImages[0],
          totalScannedCount
        );
        //   updateFaceItem({
        //     profile: currentProfile,
        //     reservation: reservationData,
        //     scanData:
        //       scanBackData !== null &&
        //       scanBackData !== undefined &&
        //       scanBackData !== ""
        //         ? scanBackData
        //         : scanData,
        //     faceImg: capturedImages[0],
        //     indexToUpdate: totalScannedCount,
        //   })
        // );
        if (enableSelfie !== null && enableSelfie !== undefined) {
          setSuccessMessage(t("Face captured successfully"));
        } else {
          setSuccessMessage(t("Face verification completed successfully"));
        }

        setSuccessLoader(true);
        setTimeout(() => {
          setSuccessLoader(false);
          return;
        }, 3000);
      } else {
        setLoading(false);
        setSuccessLoader(false);
        if (
          enableSelfie === null ||
          enableSelfie === undefined ||
          enableSelfie?.parameterValue === "false"
        )
          setFacialRecognisationFailed(true);
      }
    }
  };
  const addLog = (response, request, message) => {
    const confirmationNo = localStorage.getItem("confirmationNo");
    const data = {
      ActionName: "Facial Verification",
      Message: message ?? "",
      ControllerName: "kiosk",
      Request: request ?? "",
      PMSConfirmationNo: confirmationNo,
      AppType: "kiosk",
      Response: response ?? "",

      PropertyCode: propertyData?.data?.propertyCode,
    };
    dispatch(postAuditLog({ data, token }));
  };

  const verifyStageInface = async () => {
    if (profileList !== null && profileList !== undefined && userData) {
      let currentProfile = null;
      if ((profileList?.length ?? 0) > guestCount)
        currentProfile = profileList[guestCount];

      await dispatch(
        fetchStep({
          confirmationNo: userData?.pmsConfirmationNo,
          propertyCode: propertyData?.data?.propertyCode,
          type: "checkin",
          token: token,
          stepCode: "SEI02",
          profile: currentProfile,
          guestCount: guestCount,
        })
      )
        .unwrap()
        .then(async (isSkipped) => {
          gotoNextScreen(isSkipped);
        });
    }
  };

  const gotoNextScreen = (isSkipped) => {
    if (screen === "pickupkey") {
      setLoading(false);
      setSuccessLoader(false);
      navigate(KEY + "/key", { replace: true });
      return;
    } else {
      if (guestCount === totalCount) {
        setTimeout(() => {
          dispatch(resetFaceData());
          setLoading(false);
          setSuccessLoader(false);
          navigate(UPGRADE, { replace: true });
          return;
        }, 3000);
      } else if (guestCount < totalCount) {
        dispatch(resetFaceData());
        setTimeout(() => {
          dispatch(resetFaceData());
          setSuccessLoader(false);
          navigate(DOCUMENT_SCAN, { replace: true });
          return;
        }, 3000);
      }

      return;
    }
  };

  const gotoScreen = () => {
    if (screen === "pickupkey") {
      setLoading(false);
      setSuccessLoader(false);
      navigate(KEY + "/key", { replace: true });
      return;
    } else {
      if (guestCount === totalCount) {
        dispatch(resetFaceData());
        setLoading(false);
        setSuccessLoader(false);
        navigate(UPGRADE, { replace: true });
        return;
      } else if (guestCount < totalCount) {
        dispatch(resetFaceData());
        setSuccessLoader(false);
        navigate(DOCUMENT_SCAN, { replace: true });
        return;
      }
    }
  };
  const wait = (milliseconds) =>
    new Promise((res, rej) =>
      setTimeout(
        () => rej(),
        // new Error(`Request timed out after ${milliseconds / 1000} seconds`)
        milliseconds
      )
    );

  const restartCapture = () => {
    setCapturedImages([]); // Clear captured images
    setVerificationInitiated(false); // Reset verification flag
    setReloadCapture(false); // Toggle reload state
  };

  const manualFaceVerification = async () => {
    let credentials = [];
    setFacialRecognisationFailed(false);
    try {
      setLoading(true);
      const scanDataQR = await Promise.race([
        getScanDataFromWebhostQR({}),
        wait(30000),
      ]);
      if (scanDataQR.data != null && scanDataQR.data.BarCodeData != null) {
        if (
          scanDataQR.data.BarCodeData !== null &&
          scanDataQR.data.BarCodeData !== undefined &&
          scanDataQR.data.BarCodeData !== ""
        ) {
          credentials = scanDataQR.data.BarCodeData.split(";");
        } else {
          setFacialRecognisationFailed(true);
          return;
        }

        const confirmNo = localStorage.getItem("confirmationNo");
        const response = await getManualAuthentication(
          {
            username: credentials[0],
            password: credentials[1],
            ConfirmationNo: confirmNo,
          },
          token
        );

        if (response.data.result === true) {
          let currentProfile = null;
          if (totalScannedCount <= 0) {
            if ((profileList?.length ?? 0) > guestCount)
              currentProfile = profileList[guestCount];
          } else {
            if ((profileList?.length ?? 0) > guestCount)
              currentProfile = profileList[guestCount];
          }
          dispatch(resetDocReponse());
          updateFaceImg(
            credentials[0],
            currentProfile,
            null,
            totalScannedCount
          );
          // verifyStageInface();
        } else {
          setLoading(false);
          setFacialRecognisationFailed(true);
          return;
        }
      } else {
        setLoading(false);
        setFacialRecognisationFailed(true);
        return;
      }
    } catch (error) {
      setLoading(false);
      setFacialRecognisationFailed(true);
      return;
    }
  };

  const updateFaceImg = (authBy, currentProfile, img, totalScannedCount) => {
    let backdata = null;
    if (scanBackData !== null && scanBackData !== undefined) {
      backdata = { ...scanBackData };
    }

    let frontdata = { ...scanData };

    if (scanData !== null && scanData !== undefined) {
      frontdata = { ...scanData };
    }

    let names = [];
    if (frontdata !== undefined && frontdata !== null) {
      if (
        frontdata?.GivenName?.split(" ").length >
          frontdata?.FullName?.split(" ").length &&
        frontdata?.GivenName !== frontdata.FullName
      ) {
        names = frontdata?.GivenName?.split(" ");
      } else {
        names = frontdata?.FullName?.split(" ");
      }
    }

    let bnames = [];
    if (backdata !== undefined && backdata !== null) {
      if (
        backdata?.GivenName?.split(" ").length >
          backdata?.FullName?.split(" ").length &&
        backdata?.GivenName !== backdata.FullName
      ) {
        bnames = backdata?.GivenName?.split(" ");
      } else {
        bnames = backdata?.FullName?.split(" ");
      }

      if (
        backdata?.FirstName !== undefined &&
        (backdata?.FirstName || "" === "")
      ) {
        backdata.FirstName =
          frontdata?.FirstName ||
          (Array.isArray(names) && names[names.length - 1]) ||
          (Array.isArray(bnames) && bnames[names.length - 1]) ||
          "";
      }
      if (
        backdata?.MiddleName !== undefined &&
        (backdata?.MiddleName || "" === "")
      ) {
        backdata.MiddleName =
          frontdata?.MiddleName ||
          (Array.isArray(names) && names[1]) ||
          (Array.isArray(bnames) && bnames[1]) ||
          "";
      }
      if (
        backdata?.LastName !== undefined &&
        (backdata?.LastName || "" === "")
      ) {
        backdata.LastName =
          frontdata?.LastName ||
          (Array.isArray(names) && names[0]) ||
          (Array.isArray(bnames) && bnames[0]) ||
          "";
      }
    }
    if (frontdata !== undefined && frontdata !== null) {
      if (
        frontdata?.FirstName !== undefined &&
        (frontdata?.FirstName || "" === "")
      ) {
        frontdata.FirstName =
          backdata !== null && backdata?.FirstName !== undefined
            ? backdata?.FirstName
            : (Array.isArray(names) && names[names.length - 1]) ||
              (Array.isArray(bnames) && bnames[names.length - 1]) ||
              "";
      }
      if (
        frontdata?.MiddleName !== undefined &&
        (frontdata?.MiddleName || "" === "")
      ) {
        frontdata.MiddleName =
          backdata !== null && backdata?.MiddleName !== undefined
            ? backdata?.MiddleName
            : (Array.isArray(names) && names[1]) ||
              (Array.isArray(bnames) && bnames[1]) ||
              "";
      }
      if (
        frontdata?.LastName !== undefined &&
        (frontdata?.LastName || "" === "")
      ) {
        frontdata.LastName =
          backdata !== null && backdata?.LastName !== undefined
            ? backdata?.LastName
            : (Array.isArray(names) && names[0]) ||
              (Array.isArray(bnames) && bnames[0]) ||
              "";
      }
    }

   
    dispatch(
      updateFaceItem({
        profile: currentProfile,
        reservation: reservationData,
        scanData:
          backdata !== null && backdata !== undefined && backdata !== ""
            ? backdata
            : frontdata,
        faceImg: img ?? null,
        indexToUpdate: totalScannedCount,
        manualAuthby: authBy,
        transactionGuid: transactionGuid,
      })
    );
  };

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };

  const timerCounter = (value) => {
    setTimerValue(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stopTimer = () => {
    setRestartTimer(false);
  };

  const closeErrorModal = () => {
    setErrorModalData((state) => ({
      ...state,
      toggleError: false,
      errorMessage: "",
    }));
  };

  const handleImageCapture = async (capturedImage) => {
    try {
      if (capturedImages.length < 1) {
        setCapturedImages((prevImages) => {
          const updatedImages = [...prevImages, capturedImage];
          return updatedImages;
        });
      }
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };

  const handlebackPage = () => {
    try {
      homePageHandler(dispatch, navigate);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };

  return (
    <div>
      <ErrorPopup
        closeErrorModal={closeErrorModal}
        erroModalData={erroModalData}
      />
      {userName && faceImage ? (
        <>
          <FaceVerification
            loading={loading}
            successLoader={successLoader}
            successMessage={successMessage}
            openModal={openModal}
            closeErrorModal={closeErrorModal}
            erroModalData={erroModalData}
            signedInUserName={signedInUserName}
            facialRecognisationFailed={facialRecognisationFailed}
            completedFacialRecognisation={manualFaceVerification}
            manualAuthenticationRequired={manualAuthenticationRequired}
            backToHomePage={handlebackPage}
            faceImage={faceImage}
            scanName={userName}
            guestCount={guestCount}
            retryFace={handleRetry}
            onImageCapture={handleImageCapture}
            isReload={isReload}
            enableSelfie={
              enableSelfie?.parameterValue === "true" ? true : false
            }
            loadingMsg={loadingMsg}
          />
        </>
      ) : (
        <>
          <LoaderPopup loading={true} />
        </>
      )}

      {screen === "pickupkey" ? (
        <>
          <KeyFooter
            page={"face"}
            showModal={showModal}
            openModal={openModal}
            timerCounter={timerCounter}
            timerValue={timerValue}
            restartTimer={restartTimer}
            stopTimer={stopTimer}
            timeLimit={timeLimit}
            isFaceMatchEnabled={isFaceMatchEnabled}
          />
        </>
      ) : (
        <>
          <Footer
            page={"facerecognition"}
            showModal={showModal}
            openModal={openModal}
            timerCounter={timerCounter}
            timerValue={timerValue}
            restartTimer={restartTimer}
            stopTimer={stopTimer}
            timeLimit={timeLimit}
          />
        </>
      )}

      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
    </div>
  );
};

export default FaceVerificationQrScanning;
