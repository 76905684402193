/* eslint-disable jsx-a11y/alt-text */
import i18next from "i18next";
import personImage from "../../../assets/images/person-image.PNG";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import {  useTranslation } from "react-i18next";

const CheckoutTimeModal = ({
  togglePackageApi,
  setTogglePackageApi,
  closeCheckoutTimeModal,
}) => {
  const { t } = useTranslation();
  const closeModalPopUp = () => {
    closeCheckoutTimeModal();
  };

  const homePageHandler = () => {
    setTogglePackageApi(true);
    closeCheckoutTimeModal();
  };

  return (
    <div className="modal" style={{ zIndex: "1" }}>
      <div className="modal-dialog modal-lg modal-top">
        <div className="modal-content">
          <div className="modal-body">
            <div className="text-center">
              <div className="mt-30 modal-info">
                <h6>
                  {t("Please note that the late check-outs (after 12:00) are subject")}
                  {t("to availability and at an additional cost.")}
                </h6>
              </div>
            </div>
            <div className="text-center mt-30">
              <button
                type="button"
                onClick={closeModalPopUp}
                className="btn modal-no-btn"
              >
                {t("Close")}
              </button>
              <button onClick={homePageHandler} className="btn modal-yes-btn">
                {t("Accept")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutTimeModal;
