import AppStoreQr from "../../../assets/images/appstoreqr.png"
import PlayStoreQr from "../../../assets/images/googleplayqr.png"
import { useTranslation } from "react-i18next";

const DownloadKeyModal = ({
  closeModal,
  closeDownloadKey,
  doneDownloadKey,
}) => {
  const { t } = useTranslation();
  return (
    <div className="modal" style={{ zIndex: "1" }}>
      <div className="modal-dialog modal-lg modal-top">
        <div className="modal-content modal-bg">
          <div className="modal-body">
            <div className="text-center download-key-width">
              <div className="mt-30 modal-info">
                <p>
                {t("Download the all-new Rotana Rewards mobile app and")}
                {t("enjoy all the benefits at your fingertips including")}
                {t("Digital Room Key (where supported).")}{" "}
                </p>
              </div>
              <div>
                {/* <img src={KeyImage} /> */}

                <div className="download-key-section">
                  <div className="download-app-store">
                    <div className="download-qr-image">
                      <img src={AppStoreQr} alt="" />
                    </div>
                    {/* <div className="app-store-image">
                      <img src={AppStoreImage} alt="" />
                    </div> */}
                  </div>
                  <div className="download-app-store">
                    <div className="download-qr-image">
                      <img src={PlayStoreQr} alt="" />
                    </div>
                    {/* <div className="app-store-image">
                      <img src={PlayStoreImage} alt="" />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-30">
              <button
                onClick={closeDownloadKey}
                className="btn cancel-modal-btn"
              >
                {t("Cancel")}
              </button>
              <button
                className="btn button-blue done-modal-btn"
                onClick={doneDownloadKey}
              >
                {t("Done")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadKeyModal;
