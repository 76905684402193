import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  documentTypeData: {},
  documentListTypes: []
};

const documentTypeSlice = createSlice({
  name: "documentType",
  initialState,
  reducers: {
    documentInfo: (state, action) => {

      state.documentTypeData = action.payload;
      //state.documentTypes.push(action.payload.BODocumentType);
      state.documentTypeData.forEach((el)=>{
        state.documentTypes.push(el.BODocumentType);
      });
     // console.log("doctype=="+JSON.stringify(state.documentTypes));
    },

  },
});

export const getDocumentDataTypes = (state) => state.documentType.documentTypeData;
export const getDocumentTypes = (state) => state.documentType.documentTypes;


export const {
  documentInfo,


} = documentTypeSlice.actions;

export default documentTypeSlice.reducer;
