import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import i18n from "i18next";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LoaderPopup = ({ loading, message }) => {
  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
  }, []);

  return (
    <div>
      <Modal open={loading} style={{ zIndex: "1" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "300px",
            backgroundColor: "#f5f5f5",
            border: "2px #000",
            boxShadow: "0 0 24px rgba(0, 0, 0, 0.5)",
            padding: "10px",
            borderRadius: "20px",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
            </div>
          </div>
          {message === null || message === undefined || message === "" ? (
            <>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, textAlign: "center", fontSize: "1.2rem" }}
              >
                {t("Please wait while we process your request.")}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, textAlign: "center", fontSize: "1.2rem" }}
              >
                {t(message)}
              </Typography>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default LoaderPopup;
