import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { initReactI18next, useTranslation } from "react-i18next";
const MultipleRegCard = ({
  showConfirmModal,
  closeModal,
  confirmSharerReg
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Modal open={showConfirmModal} style={{ zIndex: "1" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "800px",
            backgroundColor: "#f5f5f5",
            border: "2px #000",
            boxShadow: "0 0 24px rgba(0, 0, 0, 0.5)",
            padding: "70px",
            borderRadius: "20px",
          }}
        >
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: "center", fontSize: "1.8rem" }}
          >
             <h5>{t("Please sign sharer registration card")} </h5>
          </Typography>

          <div style={{ textAlign: "center" }}>

            <div style={{ marginBottom: "20px" }}>
         
            </div>

            <div style={{ marginBottom: "20px" ,  marginTop: "50px"}}>
                <button
                style={{ marginTop: "20px", padding: "15px", marginLeft: "15px" }}
                className="btn button-blue scan-btn"
                onClick={() => {
                    confirmSharerReg();
                }}
                >
                {t("OK")}
                </button>
              
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default MultipleRegCard;
