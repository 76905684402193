/* eslint-disable jsx-a11y/alt-text */

import { initReactI18next, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import DrawIcon from "@mui/icons-material/Draw";
import FooterTimer from "./FooterTimer";
import { STEP_MASTER_ENUM } from "../../constants/constant";
import { getModuleConfiguration } from "../../store/Checkin/moduleConfigurationSlice";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import { getStepMaster } from "../../helpers/commonHelper";
import i18n from "i18next";
import { useSelector } from "react-redux";

const CheckoutFooter = ({
  page,
  openModal,
  timerValue,
  showModal,
  restartTimer,
  stopTimer,
  timerCounter,
  timeLimit,
}) => {
  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);
  const stepMaster = useSelector((state) => getModuleConfiguration(state));
  const paymentPage = getStepMaster(
    stepMaster,
    STEP_MASTER_ENUM.CHECKOUT_PAYMENT_PAGE
  );

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
  }, []);

  const pages = ["agreedform", "payment", "receipt", "thankyou"];

  const activeIcons = {
    agreedform: "activeAgreedFormIcon",
    payment: "activePaymentIcon",
    receipt: "activeReceiptIcon",
    thankyou: "activeAllDoneIcon",
  };

  let activeIcon = "";
  let activeState = {};
  let pageIndex = pages.indexOf(page);

  if (pageIndex !== -1) {
    for (let i = 0; i <= pageIndex; i++) {
      let currentPage = pages[i];
      activeIcon =
        activeIcons[currentPage] === "activeAllDoneIcon"
          ? "active"
          : activeIcons[currentPage];

      if (activeIcons[currentPage] !== activeIcons[pages[pageIndex]]) {
        activeState[currentPage + "Activated"] = "activated";
      }
      if (activeIcons[currentPage] === "activeAllDoneIcon") {
        activeState[currentPage + "Activated"] = "activated";
      }
    }
  }

  return (
    <footer>
      <div className="footer-logo">
        <img id="footer-logo" alt="Logo" />
      </div>
      <div className="tracker-status">
        <div
          className={`mat-icon-wrap ${
            page === "confirmation" ? "active" : "activated"
          }`}
        >
          <span className="bx bx-user-check mat-icon material-symbols-rounded"></span>
          <span className="title">{t("Confirmation")}</span>
        </div>
        <span className="mat-line"></span>
        <div
          className={`mat-icon-wrap ${
            activeIcon === "activeAgreedFormIcon"
              ? "active"
              : activeState?.agreedformActivated
          }`}
        >
          <span className=" mat-icon material-symbols-rounded">
            <DrawIcon fontSize="small" />
          </span>
          <span className="title">{t("Signature")}</span>
        </div>
        <span className="mat-line"></span>
        {paymentPage !== null && paymentPage !== undefined && (
          <>
            <div
              className={`mat-icon-wrap ${
                activeIcon === "activePaymentIcon"
                  ? "active"
                  : activeState?.paymentActivated
              }`}
            >
              <span className="bx bxs-credit-card mat-icon material-symbols-rounded"></span>
              <span className="title">{t("Payment")}</span>
            </div>
            <span className="mat-line"></span>
          </>
        )}
        <div
          className={`mat-icon-wrap ${
            activeIcon === "activeReceiptIcon"
              ? "active"
              : activeState?.receiptActivated
          }`}
        >
          <span className="bx bx-envelope mat-icon material-symbols-rounded"></span>
          <span className="title">{t("Sent receipt")}</span>
        </div>
        <span className="mat-line"></span>
        <div
          className={`mat-icon-wrap ${
            activeIcon === "activeAllDoneIcon"
              ? "active"
              : activeState?.thankyouActivated
          }`}
        >
          <span className="mat-icon material-symbols-rounded">thumb_up</span>
          <span className="title">{t("All Done")}</span>
        </div>
      </div>
      {/* 80 */}
      <FooterTimer
        openModal={openModal}
        timerValue={timerValue}
        timerCounter={timerCounter}
        restartTimer={restartTimer}
        showModal={showModal}
        stopTimer={stopTimer}
        timeLimit={timeLimit}
      />
    </footer>
  );
};

export default CheckoutFooter;
