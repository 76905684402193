/* eslint-disable jsx-a11y/anchor-is-valid */

import {
  CONFIG_MASTER_ENUM,
  STEP_MASTER_ENUM,
  timerConstantValue,
} from "../../constants/constant";
import { Link, useNavigate } from "react-router-dom";
import {
  getConfigurationCode,
  getStepMaster,
} from "../../helpers/commonHelper";
import {
  getParametersFromLocalStorage,
  homePageHandler,
} from "../../helpers/commonHelper";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { CHECKOUT_THANKYOU } from "../../routes/Paths";
import { Card } from "@mui/material";
import CheckoutFooter from "../../components/Layout/CheckoutFooter";
import Header from "../../components/Layout/Header";
import HomePageCard from "../../components/Layout/Card/HomePageCard";
import LoaderPopup from "../../components/LoaderAndNotification/LoaderPopup";
import { Modal } from "react-bootstrap";
import SendMessage from "../../components/Forms/SendMessage";
import SuccessPopup from "../../components/LoaderAndNotification/SuccessPopup";
import { getCheckoutData } from "../../store/Checkout/checkoutSlice";
import { getModuleConfiguration } from "../../store/Checkin/moduleConfigurationSlice";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import { sendFolioEmail } from "../../network/apiService";
import { useTranslation } from "react-i18next";

const SendInvoiceOnMail = () => {
  const timeLimit = timerConstantValue;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timerValue, setTimerValue] = useState(timeLimit);
  const [restartTimer, setRestartTimer] = useState(false);
  const userData = useSelector(getCheckoutData);
  const [email, setEmail] = useState(userData?.email || "");
  const [emailValidation, setEmailValidation] = useState(false);
  const [phoneValidation, setPhoneValidation] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const confirmationNo = localStorage.getItem("confirmationNo");

  const token = getParametersFromLocalStorage("token");

  const stepMaster = useSelector((state) => getModuleConfiguration(state));
  const stepEmailPage = getStepMaster(
    stepMaster,
    STEP_MASTER_ENUM.CHECKOUT_THANKYOU_PAGE
  );
  const configs = stepEmailPage?.configs;
  const emailRequired = getConfigurationCode(
    configs,
    CONFIG_MASTER_ENUM.EMAIL_REQUIRED
  );
  const smsRequired = getConfigurationCode(
    configs,
    CONFIG_MASTER_ENUM.SMS_REQUIRED
  );
  const whatsappRequired = getConfigurationCode(
    configs,
    CONFIG_MASTER_ENUM.WHATSAPP_REQUIRED
  );
  // const emailPage =(
  //                       stepMaster &&
  //                       stepMaster.length
  //                         ? stepMaster.find((ele) => ele.stepCode === "SKO03")
  //                         : null
  //                     ) ?? null;
  //const modules = emailPage?.configs;
  // const emailRequired = (modules && modules.length
  //                       ? modules.find((ele) => ele.stepConfigurationCode === "ESR")?.isEnabled? true:false
  //                       : false) ?? false;
  const [counter, setCounter] = useState(0);
  const [requiredEmail, needRequiredEmail] = useState(emailRequired);

  const resetTimerAndClosePopUp = () => {
    if (!timerValue) {
      setTimerValue(timeLimit);
    }
    setRestartTimer(true);
    setShowModal(false);
  };
  const timerCounter = (value) => {
    setTimerValue(value);
  };
  const openModal = () => {
    setShowModal(true);
  };
  const stopTimer = () => {
    setRestartTimer(false);
  };
  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
  };
  useEffect(() => {
    if (
      emailRequired !== null &&
      emailRequired !== undefined &&
      emailRequired !== false
    ) {
      setCounter((prevCount) => prevCount + 1);
    }
    if (
      smsRequired !== null &&
      smsRequired !== undefined &&
      smsRequired !== false
    ) {
      setCounter((prevCount) => prevCount + 1);
    }
    if (
      whatsappRequired !== null &&
      whatsappRequired !== undefined &&
      whatsappRequired !== false
    ) {
      setCounter((prevCount) => prevCount + 1);
    }
  }, []);
  const selectedLanguage = useSelector(getSelectedLanguage);

  const updateEmailHandler = async (email, phone, isClose, commonType) => {
    const ReservationNameID = localStorage.getItem("reservationNameID");
    let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (commonType === "email") {
      if (email !== null && email !== undefined && !mailFormat.test(email)) {
        setEmailValidation(true);
        return;
      } else {
        setSuccessMessage(t("Mail sent successfully!!"));
      }
    } else {
      if (phone !== null && phone !== undefined) {
        if (phone === "") setPhoneValidation(true);
        return;
      } else {
        setSuccessMessage(t("Message sent successfully!!"));
      }
    }
    const response = await sendFolioEmail(
      {
        EmailAddress: email,
        PhoneNumber: phone,
        ConfirmationNo: confirmationNo,
        ReservationNameID: ReservationNameID,
        lan: selectedLanguage,
        communicationType: commonType,
      },
      token
    );

    setLoading(true);
    //&& response?.data?.result === true)
    if (response !== null) {
      setLoading(false);
      setSuccessLoader(true);
      setTimeout(() => {
        setSuccessLoader(false);
        setLoading(false);
        if (counter === 1) {
          navigate(CHECKOUT_THANKYOU);
        }
      }, 3000);
    } else {
      setLoading(false);
    }

    return;
  };
  const handlebackPage = () => {
    try {
      homePageHandler(dispatch, navigate);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };
  const handlethankyouPage = () => {
    try {
      navigate(CHECKOUT_THANKYOU);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };
  return (
    <div>
      <main>
        <div className="main-content ">
          <div className="h-100 bg-content">
            <div className="d-flex">
              <Header
                onClick={handlebackPage}
                setShowModal={openModal}
                isHome={true}
                title={""}
                subTitle={""}
              />
            </div>

            <div
              className="message-wrapper flex justify-center-center"
              style={{ height: "60%" }}
            >
              <div style={{ width: "500px" }}>
                <SendMessage
                  updateEmailHandler={updateEmailHandler}
                  emailValidation={emailValidation}
                  title={t("Invoice")}
                  subtitle={t("Please update or verify your details")}
                  phoneValidation={phoneValidation}
                  successLoader={successLoader}
                  loading={loading}
                  emailRequired={emailRequired}
                  smsRequired={smsRequired}
                  whatsappRequired={whatsappRequired}
                />
              </div>
            </div>
            <button
              className="btn btn-md button-blue skip-key-btn"
              onClick={handlethankyouPage}
            >
              {t("Proceed")}
              <i class="bx bx-chevrons-right"></i>
            </button>
          </div>

          <Modal>
            <Modal.Body>
              <div className="send-invoice-center">
                <div className="page-title invoice-mail-title">
                  {t("Would you like to get the invoice by")}
                </div>
                <div className="reservation-confirm-box">
                  <div className="reservation-confirm w-360">
                    <label>{t("Please update or verify your email address")}</label>
                    <input
                      type="email"
                      value={email}
                      autoComplete="off"
                      onChange={emailChangeHandler}
                      className="form-control form-control-set font-weight-600"
                    />
                    {emailValidation && (
                      <p className="email-error-msg">
                        {t("Please enter a valid Email Address")}
                      </p>
                    )}
                  </div>
                </div>
                <div className="reservation-btn-set">
                  <div>
                    <button
                      onClick={updateEmailHandler}
                      disabled={!email && requiredEmail}
                      className="btn button-outline proceed-blue-btn confirm-invoice-btn"
                    >
                      {t("Confirm")}
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <SuccessPopup
            loading={successLoader}
            successMessage={t("Folio sent successfully!!")}
          />
          <LoaderPopup loading={loading} />
        </div>
        <CheckoutFooter
          page={"receipt"}
          showModal={showModal}
          openModal={openModal}
          timerCounter={timerCounter}
          timerValue={timerValue}
          restartTimer={restartTimer}
          stopTimer={stopTimer}
          timeLimit={timeLimit}
        />
      </main>
      {showModal && (
        <HomePageCard
          showModal={showModal}
          resetTimerAndClosePopUp={resetTimerAndClosePopUp}
        />
      )}
    </div>
  );
};
export default SendInvoiceOnMail;
