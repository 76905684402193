export const HOME = "/home-page/:type/:currentStep";
export const CHECKIN = "/checkin-page";
export const CHECKOUT = "/check-out-page";
export const KEYPAGE = "/key-page";

export const SEARCH_RESERVATION = "/confirmation-number";
export const RESERVATION_FORM = "/confirmation-no-reservation-form";
export const DOCUMENT_VERIFICATION = "/confirmation-no-document-verification";
export const DOCUMENT_SCAN = "/passport-scanning";
export const DOCUMENT_SCAN_TYPE = "/passport-scanning/:type";
export const RESERVATION_LIST_FORM = "/passport-scanning-reservation-form";
export const RESERVATION_LIST_FORM_TYPE = "/passport-scanning-reservation-form/:screen";

export const FACE_VERIFICATION = "/qr-code-scanning-face-verification";
export const FACE_VERIFICATION_NEW = "/qr-code-scanning-face-verification/:screen";
export const RESERVATION_DETAIL = "/passport-scan-reservation-details";
export const QR_SEARCH = "/qr-code-scanning";


export const UPGRADE = "/confirmation-no-upgrade";
export const KEY = "/passport-scan-create-key";
export const CHECKIN_PAYMENT = "/passport-scan-payment";
export const CHECKIN_THANKYOU = "/passport-scan-thank-you";


export const FOLIO = "/checkout-invoice-info";
export const SEND_FOLIO = "/checkout-send-invoice-mail";
export const CHECKOUT_PAYMENT = "/checkout-payment";
export const CHECKOUT_THANKYOU = "/checkout-thank-you";

export const KEY_NEW = "/passport-scan-create-key/:screen";
export const KEY_THANKYOU = "/key-done";

