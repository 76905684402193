import BaseLayout from "../components/Layout/BaseLayout";
import ThemeComponent from "../components/Theme/ThemeComponent";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";

const PrivateRoute = ({ component: C, type: type, currentStep: currentStep }) => {
  const AuthenticationState = useSelector((state) => state.AuthReducer);
  const location = useLocation();
  //removeParametersFromLocalStorage("token");
 
  useEffect(() => {
    if (location.pathname === "/") {
      localStorage.clear();
      sessionStorage.clear();
    }
  }, [location.pathname]);
  
  return (
    <div>
      <BaseLayout type={type} currentStep={currentStep} />
      <C  type={type} currentStep={currentStep}/>
    </div>
  );
};

export default PrivateRoute;
