import { initReactI18next, useTranslation } from 'react-i18next';

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { getSelectedLanguage } from "../../../store/Checkin/languageSlice";
import i18n from 'i18next';
import { useEffect } from "react";
import { useSelector } from "react-redux";

const FaceRcgFailedModal = ({
  ficalRecognisationModal,
  completedFacialRecognisation,
  backToHomePage,
  retryFace,
  manualAuthenticationRequired
}) => {

  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
     }, []);

  return (
    <div>
      <Modal open={ficalRecognisationModal} style={{ zIndex: "1" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "700px",
            backgroundColor: "#f5f5f5",
            border: "2px #000",
            boxShadow: "0 0 24px rgba(0, 0, 0, 0.5)",
            padding: "70px",
            borderRadius: "20px",
          }}
        >
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: "center", fontSize: "1.8rem" }}
          >
            <p><h3><b>{t("Face Verification Failed!!")}</b></h3></p>
            <br/>
            <p><h5>{t("To verify your face, please click on below button.")}</h5></p>
            <br/>
          </Typography>
          <div style={{ textAlign: "center" }}>
          <div className="row">
           
          { manualAuthenticationRequired ? (
            <div className="col-lg-12">
            <button
              // style={{ marginTop: "20px", padding: "15px",marginLeft:"15px;" }}
              className="btn button-blue manual-btn"
              onClick={() => {
                completedFacialRecognisation();
              }}
            >
              {t("MANUAL AUTHENTICATION")}
            </button>
            </div>
          ):null}
            
            </div>
            <div className="row">
              <div className="col-lg-6"> <button
               style={{ marginTop: "20px", padding: "15px", marginLeft: "15px" }}
              className="btn button-green scan-btn"
              onClick={() => {
                retryFace();
              }}
            >
              {t("RETRY")}
            </button>
            </div>
              <div className="col-lg-6">

              <button
              style={{ marginTop: "20px", padding: "15px", marginLeft: "15px" }}
              className="btn button-red scan-btn"
              onClick={() => {
                backToHomePage();
              }}
            >
              {t("HOME")}
            </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default FaceRcgFailedModal;
