/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { initReactI18next, useTranslation } from "react-i18next";

import ErrorPopup from "../LoaderAndNotification/ErrorPopup";
import FlipDocumentModal from "../Layout/Card/FlipDocumentModal";
import Header from "../Layout/Header";
import LoaderPopup from "../LoaderAndNotification/LoaderPopup";
import LottieHelper from "../../../src/components/Layout/LottieHelper";
import { STEP_MASTER_ENUM } from "../../constants/constant";
import SuccessPopup from "../LoaderAndNotification/SuccessPopup";
import { getModuleConfiguration } from "../../store/Checkin/moduleConfigurationSlice";
import { getSelectedLanguage } from "../../store/Checkin/languageSlice";
import i18n from "i18next";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const DocumentVerification = ({
  loading,
  reservationData,
  openModal,
  userData,
  openMaskModal,
  closeErrorModal,
  erroModalData,
  scanDocumentHandler,
  successLoader,
  successMessage,
  flipModalToggle,
  completedScanDone,
  guestCount,
  totalCount,
  show,
  currentProfile: currentProfile,
  documentTypes,
  onBackPressed,
  lastName,
  isPrimaryGuest,
  closeModel,
  isChild,
}) => {
  const {t} = useTranslation();
  const stepMaster = useSelector((state) => getModuleConfiguration(state));
  const documentScanPage =
    stepMaster && stepMaster.length > 0
      ? stepMaster.find((ele) => ele.stepCode === STEP_MASTER_ENUM.SCAN_DOCUMENT_PAGE)
      : null;

  const activeDocumentTypes = documentTypes.filter((item) => item.ActiveFlag);

  const listItems = activeDocumentTypes.map((item, index) => (
    <li key={index}>{t(item.Description)}</li>
  ));


  const selectedLanguage = useSelector(getSelectedLanguage);

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
  }, []);

  return (
    <div>
      <main>
        <div className="main-content">
          <div className="h-100">
            <div className="content-bg h-100">
              <div className="row h-100">
                <div className="col-sm-4 pl-0">
                  <div className="h-100">
                    <div>
                      <Header
                        onClick={onBackPressed}
                        setShowModal={openModal}
                        isHome={true}
                        title={""}
                        subTitle={""}
                      />
                    </div>

                    <LoaderPopup loading={loading} />
                    <ErrorPopup
                      closeErrorModal={closeErrorModal}
                      erroModalData={erroModalData}
                    />
                    <SuccessPopup
                      loading={successLoader}
                      successMessage={successMessage}
                    />
                    <FlipDocumentModal
                      completedScanDone={completedScanDone}
                      flipModalToggle={flipModalToggle}
                      closeModel={closeModel}
                    />

                    <div className="scan-content-left-page  text-center ">
                      <div className="mt-115 justify-content-center">
                        <div className="scan-text">
                          <h5 className="text-center font-14 font-weight-500">
                            {lastName && (
                              <>
                                {t("Hey")}{" "}
                                <strong>
                                  {lastName}
                                  {","}
                                </strong>
                              </>
                            )}
                          </h5>
                          <br />
                          <h5
                            className="text-center font-14 font-weight-500"
                            style={{ padding: "10px" }}
                          >
                            {isChild ? (
                              <>
                                <h5 className="text-center font-14 font-weight-500">
                                  {t("Place the valid document of the child on the")}{" "}
                                  {t("scanner")}.
                                </h5>

                                <h5>{t("Tap Scan to begin.")}</h5>
                              </>
                            ) : !isChild && !isPrimaryGuest ? (
                              <>
                                <h5 className="text-center font-14 font-weight-500">
                                  {t(
                                    "Place the valid document of the accompanying"
                                  )}{" "}
                                  {t("guest on the scanner.")}
                                </h5>

                                <h5>{t("Tap Scan to begin.")}</h5>
                              </>
                            ) : (
                              <>
                                <h5 className="text-center font-14 font-weight-500">
                                  {t("Place valid document on the scanner.")}
                                </h5>

                                <h5>{t("Tap Scan to begin.")}</h5>
                              </>
                            )}
                          </h5>
                        </div>
                        <div className="text-center mt-30">
                          {show === true ? (
                            <button
                              onClick={() => {
                                scanDocumentHandler();
                              }}
                              className="btn button-blue scan-btn"
                              // onClick={()=>{setShowMaskModal(true)}}
                            >
                              <i className="bx bx-barcode-reader scan-icon"></i>
                              {t("Scan")}
                            </button>
                          ) : (
                            <>
                              <progress class="linear-pgb mb-0" />
                            </>
                          )}
                        </div>

                        <br />
                        {listItems && listItems?.length > 0 && (
                          <>
                            <div className="down-text scan-text">
                              <h5
                                className="text-center font-14 font-weight-500"
                                style={{ marginTop: "20px" }}
                              >
                                {t("Supported documents")}:
                              </h5>
                              <div className="bullet">
                                <ul style={{ textAlign: "start" }}>
                                  {listItems}
                                </ul>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-8 pr-0">
                  {/* <div className="scan-img">
                    <img src="./assets/images/scan.gif" alt="" />
                  </div>
                   */}
                  {documentScanPage?.stepImage?.includes(".json") ? (
                    <>
                      <LottieHelper animationData="passport_scan.json" />
                    </>
                  ) : (
                    <>
                       <img src={documentScanPage?.stepImage} alt="" style={{backgroundSize:"cover", width:"100%"}} />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default DocumentVerification;
