import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { v4 as uuidv4 } from "uuid";
import { verifyScanData } from "../../../helpers/commonHelper";

const initialState = {
  scanDetails: {},
  scanBackDetails: {},
  profileList: [],
  guestCount: 0,
  scannedCount: 0,
  retryFaceCount: 0,
  documentTypes: null,
  documentListTypes: null,
  error: null,
  response: {
    result: false,
    error: null,
    isFinished: false,
    isFlip: false,
  },
  docResult: null,
};
export const validateScanDocument = createAsyncThunk(
  "scan/validateScanDocument",
  async (
    {
      scanData,
      profile,
      isChild,
      adultAgeLimit,
      childAgeLimit,
      isBackSide,
      fullName,
    },
    thunkAPI
  ) => {
    const verifydata = verifyScanData(
      scanData,
      profile,
      thunkAPI.getState().scandocument.documentTypes,
      thunkAPI.getState().scandocument.profileList,
      isChild,
      adultAgeLimit,
      childAgeLimit,
      isBackSide,
      fullName,
      thunkAPI.getState().scandocument.documentListTypes,
    );
    if (verifydata[1]) {
      if (!verifydata[0]) {
        return {
          result: true,
          isFinished: true,
          isFlip: false,
        };
      } else {
        return {
          result: true,
          isFinished: true,
          isFlip: true,
        };
      }
    } else if (!verifydata[1] && verifydata[2] !== "") {
      return {
        result: false,
        isFinished: true,
        error: verifydata[2],
      };
    }
  }
);
const scanSlice = createSlice({
  name: "scandocument",
  initialState,
  reducers: {
    resetDocumentState: (state, action) => {
      state = initialState();
    },
    setDocumentInfo: (state, action) => {
      //state.documentTypes.push(action.payload.BODocumentType);
      if (state.documentTypes === null) {
        state.documentTypes = [];
      }
      action.payload.forEach((el) => {
        state.documentTypes.push(el.BODocumentType);
      });
      state.documentListTypes = action.payload;
    },
    scannedDocumentDetails: (state, action) => {
      const scanData = action.payload.scanData;
      const scanBackData = action.payload.scanBackData;

      let frontdata = { ...scanData };
      let backdata = { ...scanBackData };

      let names = [];
      if (
        scanData?.GivenName?.split(" ").length >
          scanData?.FullName?.split(" ").length &&
        scanData?.GivenName !== frontdata.FullName
      ) {
        names = scanData?.GivenName?.split(" ");
      } else {
        names = scanData?.FullName?.split(" ");
      }

      let bnames = [];
      if (
        backdata?.GivenName?.split(" ").length >
          backdata?.FullName?.split(" ").length &&
        backdata?.GivenName !== backdata.FullName
      ) {
        bnames = backdata?.GivenName?.split(" ");
      } else {
        bnames = backdata?.FullName?.split(" ");
      }

      if (backdata?.FirstName || "" === "") {
        backdata.FirstName =
          scanData?.FirstName ||
          (names !== undefined && names !== null && names?.length) ||
          (0 > 0 && Array.isArray(names) && names[names?.length - 1]) ||
          (bnames !== undefined && bnames !== null && bnames?.length) ||
          (0 > 0 && Array.isArray(bnames) && bnames[bnames.length - 1]) ||
          "";
      }
      if (backdata?.MiddleName || "" === "") {
        backdata.MiddleName =
          scanData?.MiddleName ||
          (names !== undefined && names !== null && names?.length) ||
          (0 > 0 && Array.isArray(names) && names[1]) ||
          (bnames !== undefined && bnames !== null && bnames?.length) ||
          (0 > 0 && Array.isArray(bnames) && bnames[1]) ||
          "";
      }
      if (backdata?.LastName || "" === "") {
        backdata.LastName =
          scanData?.LastName ||
          (names !== undefined && names !== null && names?.length) ||
          (0 > 0 && Array.isArray(names) && names[0]) ||
          (bnames !== undefined && bnames !== null && bnames?.length) ||
          (0 > 0 && Array.isArray(bnames) && bnames[0]) ||
          "";
      }

      if (frontdata?.FirstName || "" === "") {
        frontdata.FirstName =
          backdata?.FirstName ||
          (names !== undefined && names !== null && names?.length) ||
          (0 > 0 && Array.isArray(names) && names[names.length - 1]) ||
          (bnames !== undefined &&
            bnames !== null &&
            bnames?.length > 0 &&
            Array.isArray(bnames) &&
            bnames[bnames.length - 1]) ||
          "";
      }
      if (frontdata?.MiddleName || "" === "") {
        frontdata.MiddleName =
          backdata?.MiddleName ||
          (names !== undefined && names !== null && names?.length) ||
          (0 > 0 && Array.isArray(names) && names[1]) ||
          (bnames !== undefined &&
            bnames !== null &&
            bnames?.length > 0 &&
            Array.isArray(bnames) &&
            bnames[1]) ||
          "";
      }
      if (frontdata?.LastName || "" === "") {
        frontdata.LastName =
          backdata?.LastName ||
          (names !== undefined && names !== null && names?.length) ||
          (0 > 0 && Array.isArray(names) && names[0]) ||
          (bnames !== undefined &&
            bnames !== null &&
            bnames?.length > 0 &&
            Array.isArray(bnames) &&
            bnames[0]) ||
          "";
      }

      if (scanData !== undefined && scanData !== null) {
        state.scanDetails = scanData;
      } else if (scanBackData !== null && scanBackData !== undefined) {
        state.scanBackDetails = scanBackData;
      }

      // state.docResult = true;
    },
    resetSannedDocumentDetails: (state, action) => {
      state.scanBackDetails = null;
      state.scanDetails = null;
    },
    addItem(state, action) {
     

      state.docResult = null;

      let scanData = action.payload.scanData;
      let scanBackData = action.payload.scanBackData;
      const reservation = action.payload.reservation;

      const profile = action.payload.profile;
      const indexToUpdate = action.payload.indexToUpdate || -1;

      const newScanData = {
        ...scanData,
        manualAuthby: null,
        isPrimary: profile?.isPrimary,
        pmsProfileID: profile?.pmsProfileID,
        guestDetailID: profile?.guestDetailID,
        // pmsConfirmationNo: profile?.pmsConfirmationNo,
        // reservationNameID: profile?.reservationNameID,
        pmsConfirmationNo:
          profile?.pmsConfirmationNo === null ||
          profile?.pmsConfirmationNo === undefined ||
          profile?.pmsConfirmationNo === ""
            ? reservation?.pmsConfirmationNo
            : profile?.pmsConfirmationNo,
        reservationNameID:
          profile?.reservationNameID === null ||
          profile?.reservationNameID === undefined ||
          profile?.reservationNameID === ""
            ? reservation?.reservationNameID
            : profile?.reservationNameID,
        isFaceMatch: false,
        isManualAuth: false,
      };
      
     
      if (indexToUpdate > 0 && state.profileList?.length > indexToUpdate) {
        state.profileList[indexToUpdate] = newScanData;
      } 
      else {
        if (state.profileList === null) {
          state.profileList = [];
        }

        state.profileList.push(newScanData);
        state.scannedCount = state.scannedCount + 1;        
      }
      if (scanBackData !== null && scanBackData !== undefined) {
        const newScanDataBack = {
          ...scanBackData,
          isPrimary: profile?.isPrimary,
          pmsProfileID: profile?.pmsProfileID,
          guestDetailID: profile?.guestDetailID,
          // pmsConfirmationNo: profile?.pmsConfirmationNo,
          // reservationNameID: profile?.reservationNameID,
          pmsConfirmationNo:
            profile?.pmsConfirmationNo === null ||
            profile?.pmsConfirmationNo === undefined ||
            profile?.pmsConfirmationNo === ""
              ? reservation?.pmsConfirmationNo
              : profile?.pmsConfirmationNo,
          reservationNameID:
            profile?.reservationNameID === null ||
            profile?.reservationNameID === undefined ||
            profile?.reservationNameID === ""
              ? reservation?.reservationNameID
              : profile?.reservationNameID,
          isFaceMatch: false,
          isManualAuth: false,
        };

        state.profileList.push(newScanDataBack);
        state.scannedCount = state.scannedCount + 1;        
      }
      state.scanDetails = newScanData;      
      state.docResult = true;
    },
    updateFaceItem(state, action) {
      state.docResult = null;     

      let reservation = action.payload.reservation;
      let profile = action.payload.profile;
      let scanData = action.payload.scanData;
      let faceLiveImg = action.payload.faceImg;
      let manualAuthby = action.payload.manualAuthby;
      let transactionGuid = action.payload.transactionGuid;

      let isManualAuth =
        manualAuthby !== null &&
        manualAuthby !== undefined &&
        manualAuthby !== ""
          ? true
          : false;

      let indexToUpdate = action.payload.indexToUpdate - 1;      

      const uniqueId = uuidv4();
      const newScanData = {
        ...scanData,
        transactionGUID:
          transactionGuid !== "" &&
          transactionGuid !== undefined &&
          transactionGuid !== null
            ? transactionGuid
            : uniqueId,
        isPrimary: profile?.isPrimary,
        firstName: profile?.firstName,
        lastName: profile?.lastName,
        pmsProfileID: profile?.pmsProfileID,
        guestDetailID: profile?.guestDetailID,
        pmsConfirmationNo:
          profile?.pmsConfirmationNo === null ||
          profile?.pmsConfirmationNo === undefined ||
          profile?.pmsConfirmationNo === ""
            ? reservation?.pmsConfirmationNo
            : profile?.pmsConfirmationNo,
        reservationNameID:
          profile?.reservationNameID === null ||
          profile?.reservationNameID === undefined ||
          profile?.reservationNameID === ""
            ? reservation?.reservationNameID
            : profile?.reservationNameID,
        liveFaceImage: faceLiveImg,
        isFaceMatch: true,
        manualAuthby: manualAuthby,
        isManualAuth: isManualAuth,
      };
      if (indexToUpdate > -1 && state.profileList?.length >= indexToUpdate) { // 2, 2
        state.profileList[indexToUpdate] = newScanData;
      } else {
        if (state.profileList === null) {
          state.profileList = [];
        }
       state.profileList.push(newScanData);
      //  console.log("3scan completed tax number " + newScanData.TaxNumber);
        // state.guestCount = state.guestCount + 1;
        // state.scannedCount = state.scannedCount + 1;
      }
      state.guestCount = state.guestCount + 1;
      
      // if (scanData === null) {

      // } else {
      //   const newScanData = {
      //     ...scanData,
      //     liveFaceImage: faceLiveImg,
      //     isFaceMatch: true,
      //     manualAuthby: manualAuthby,
      //     isManualAuth: isManualAuth,
      //   };

      //   if (indexToUpdate > 0 && state.profileList?.length > indexToUpdate) {
      //     state.profileList[indexToUpdate] = newScanData;
      //   } else {
      //     if (state.profileList === null) {
      //       state.profileList = [];
      //       state.profileList.push(newScanData);
      //       // state.guestCount = state.guestCount + 1;
      //       // state.scannedCount = state.scannedCount + 1;
      //     }
      //   }

      //   state.guestCount = state.guestCount + 1;
      //   state.scannedCount = state.scannedCount + 1;
      //   console.log("3scan completed " + state.guestCount);
      // }

      state.docResult = true;      
    },
    validateScanDocument1: (state, action) => {
      let scanData = action.payload.scanData;
      let profile = action.payload.profile;
      const verifydata = verifyScanData(
        scanData,
        profile,
        state.documentTypes,
        state.profileList
      );
      if (verifydata[1]) {
        if (!verifydata[0]) {
          state.response = {
            result: true,
            isFinished: true,
            isFlip: false,
          };
        } else {
          state.response = {
            result: true,
            isFinished: true,
            isFlip: true,
          };
        }
      } else if (!verifydata[1] && verifydata[2] !== "") {
        state.response = {
          result: false,
          isFinished: true,
          error: verifydata[2],
        };
      }
    },
    scanDocumentCountOfTheGuests: (state, action) => {
      state.guestCount = state.guestCount + action.payload;
      console.log("4scan completed " + state.guestCount);
    },
    resetScanDocumentGuestCount: (state) => {
      state.guestCount = 0;
      state.scannedCount = 0;
      state.scanDetails = null;
      state.scanBackDetails = null;
    },
    scanDocumentRetryFace: (state, action) => {
      state.retryFaceCount = state.retryFaceCount + action.payload;
    },
    resetRetryFaceCount: (state) => {
      state.retryFaceCount = 0;
    },
    resetProfileList: (state) => {
      state.profileList = null;
    },
    resetReponse: (state, action) => {
      state.response = {
        result: false,
        error: "",
        isFinished: false,
      };
    },
    resetDocReponse: (state, action) => {
      state.docResult = false;
    },
    resetScanDocument: (state) => {
      state.scanDetails = null;
      state.scanBackDetails = null;
    },
    incrementScanDocumentCount: (state) => {
      state.scannedCount = state.scannedCount + 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(validateScanDocument.fulfilled, (state, action) => {
      state.response = action.payload;
    });
  },
});

export const getScanData = (state) => state.scandocument.scanDetails;

export const getScanBackData = (state) => state.scandocument.scanBackDetails;

export const getProfileData = (state) => state.scandocument.profileList;

export const getScanDocumentCurrentCountOfGuest = (state) =>
  state.scandocument.guestCount;

export const getTotalScannedDocument = (state) =>
  state.scandocument.scannedCount;

export const getRetryFaceCount = (state) => state.scandocument.retryFaceCount;

export const getResponseProfile = (state) => state.scandocument.response;
export const getScanDocResult = (state) => state.scandocument.docResult;
export const getDocumentTypes = (state) => state.scandocument.documentTypes;
export const getDocumentListTypes = (state) =>
  state.scandocument.documentListTypes;

export const {
  scannedDocumentDetails,
  scanDocumentCountOfTheGuests,
  resetSannedDocumentDetails,
  resetScanDocumentGuestCount,
  resetScanDocument,
  scanDocumentRetryFace,
  resetRetryFaceCount,
  addItem,
  updateFaceItem,
  profileList,
  resetProfileList,
  resetReponse,
  resetDocReponse,
  setDocumentInfo,
  incrementScanDocumentCount,
} = scanSlice.actions;

export default scanSlice.reducer;
