import { useTranslation } from "react-i18next";

const PolicyDetailsModal = ({
  closeModal,
  closeDownloadKey,
  doneDownloadKey,
  policyData
}) => {
  const { t } = useTranslation();
  return (
    <div className="modal" style={{ zIndex: "1" }}>
      <div className="modal-dialog modal-lg modal-middle terms">
        <div className="modal-content modal-bg">
          <div className="modal-body">
            <div className="policy">
              <div className="mt-30 modal-info">
                <p>
                <b className="modal-title text-center">{t("Privacy Policy")}</b><br/>
                {policyData}
                </p>
              </div>
      
            </div>
            <div className="text-center mt-30">
            
              <button
                className="btn button-blue done-modal-btn"
                onClick={doneDownloadKey}
              >
                {t("Close")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyDetailsModal;
