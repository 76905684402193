import { initReactI18next, useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { getSelectedLanguage } from "../../../store/Checkin/languageSlice";
import i18n from 'i18next';
import { useSelector } from "react-redux";

const EarlyCheckinModal = ({
  earlyCheckIn,
  packageAmout,
  packageCode,
  currency,
  confirmEarlyCheckin,
  backToHomePage,
  reservationData
}) => {
  const { t } = useTranslation();
  const selectedLanguage = useSelector(getSelectedLanguage);

  useEffect(async () => {
    i18n.changeLanguage(selectedLanguage);
  }, []);
  

  return (
    <div>
      <Modal open={earlyCheckIn} style={{ zIndex: "1" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "800px",
            backgroundColor: "#f5f5f5",
            border: "2px #000",
            boxShadow: "0 0 24px rgba(0, 0, 0, 0.5)",
            padding: "70px",
            borderRadius: "20px",
          }}
        >
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: "center", fontSize: "1.8rem" }}
          >
            <p>{t("Would you like to Check-In Early?")}</p>
            <p>{t("Early Check-In is available for")} {currency} {packageAmout} </p>
          </Typography>
          <div style={{ textAlign: "center" }}>

              <button
              style={{ marginTop: "20px", padding: "15px", marginLeft: "15px" }}
              className="btn button-blue scan-btn"
              onClick={() => {
                confirmEarlyCheckin(packageAmout,packageCode,reservationData);
              }}
            >
              {t("Yes")}
            </button>
          
            <button
              style={{ marginTop: "20px", padding: "15px", marginLeft: "15px" }}
              className="btn button-blue scan-btn"
              onClick={() => {
                backToHomePage();
              }}
            >
               {t("No")}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EarlyCheckinModal;
