import "./assets/stylesheet/bootstrap/css/bootstrap.min.css";
import "./assets/stylesheet/footer.css";
import "./assets/stylesheet/confirmation.css";
import "./assets/stylesheet/create-key.css";
import "./assets/stylesheet/reservation-form.css";
import "./assets/stylesheet/find-booking.css";
import "./assets/stylesheet/confirmation-number.css";
import "./assets/stylesheet/verify-email.css";
import "./assets/stylesheet/upgrade-option.css";
import "./assets/stylesheet/process-payment.css";
import "./assets/stylesheet/terms-and-condition.css";
import "./assets/stylesheet/reservation-detail.css";
import "./assets/stylesheet/agreed-sign.css";
import "./assets/stylesheet/terms-and-condition.css"
import "./assets/stylesheet/reservation-detail.css"
import "./assets/stylesheet/agreed-sign.css";
import "./assets/stylesheet/terms-and-condition.css";
import "./assets/stylesheet/agreed-sign.css";
import "./assets/stylesheet/upgrade-option.css";
import "./assets/stylesheet/reservation-form.css";
import "./assets/stylesheet/confirmation-number.css";
import "./assets/stylesheet/screen-verification.css";
import "./assets/stylesheet/find-booking.css";
import "./assets/stylesheet/boxicons/css/boxicons.min.css";
import "./assets/stylesheet/reservation-form.css";
import "./assets/stylesheet/find-booking.css";
import "./assets/stylesheet/boxicons/css/boxicons.min.css";
import "./assets/stylesheet/verify-email.css";
import "./assets/stylesheet/scan.css";
import "./assets/stylesheet/footer.css";
import "./assets/stylesheet/style1.css";
import "./assets/stylesheet/responsive.css";
import "./assets/stylesheet/remixicon/remixicon.css";


import App from "./App";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom";
import store from "./store";

//import ReactDOM from 'react-dom/client';
import './index.css';
import "./i18n";

const root = document.getElementById("root");
ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  root
);
